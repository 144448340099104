.streaming-now-indicator {
  position: relative;
  color: white !important;

  &::after {
    content: '\2022';
    color: #f33 !important;
    position: relative;
    display: inline-block;
    top: -.4rem;
    left: 0.05rem;
    font-size: 1.1rem;
    line-height: 0
  }
}
