@function boxShadow($r, $g, $b) {
  @return 0px 2px 4px 0px rgba($r, $g, $b, 0.85);
}

$shadow: boxShadow(0, 0, 0);
$textShadow: 0px 2px 4px rgba(0, 0, 0, 0.85);
$hp-red: #F55;

.live-fight-data {
  &__vertical-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    height: 100vh;
    width: 70px;
    gap: 12px;
  }

  &__horizontal-wrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    gap: 24px;
  }

  &__horizontal-left-panel {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 24px;
    flex: 1;
  }

  &__horizontal-top-values {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__horizontal-phase-indicators {
    display: flex;
    flex-direction: row;
    gap: 0;

    &--inactive {
      opacity: 0.3;
    }
  }

  &__horizontal-hp-bar {
    height: 64px;
  }

  &__horizontal-boss-resource-bar {
    height: 32px;
  }

  &__horizontal-right-panel {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
  }

  &__outer-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }

  &__horizontal-stack-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &_timer-icon-container {
    position: relative;
  }

  &__icon {
    border: 1px solid #3A3A3A;
    box-shadow: $shadow;
    width: 32px;
    height: auto !important;
    margin-top: 1px;
    transition: all 0.24s ease-out;
    border-radius: 2px;

    &--active {
      border: 1px solid orange;
      box-shadow: boxShadow(255, 165, 0);
      opacity: 0.75;
    }

    &--on-cooldown {
      filter: saturate(0);
      opacity: 0.75;
    }
  }

  &__icon-timer {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: bold;
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);

    &--active {
      font-size: 1.25rem;
    }

    &--on-cooldown {
      font-size: 1rem;
    }
  }

  &__title {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: $subsubtitle-font-size;
    font-weight: bold;
    color: $white-700;
    text-shadow: $textShadow;
    text-transform: uppercase;
    text-align: center;
  }

  &__value {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 32px;
    font-weight: bold;
    color: #FFFFFF;
    text-shadow: $textShadow;
    margin-top: -4px;
    transition: all 0.24s ease-out;

    &--small {
      font-size: 25px;
    }

    &--tiny {
      font-size: 18px;
    }

    &--no-image {
      margin-top: -7px;
    }
  }

  &__subtitle {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 15px;
    font-weight: bold;
    color: $white-700;
    text-shadow: $textShadow;
    margin-top: -5px;
    text-transform: uppercase;
  }

  &--used & {
    &__icon {
      opacity: 0.4;
    }

    &__value {
      color: $hp-red;
    }
  }

  &--inactive {
    opacity: 0.3;
    filter: saturate(0.1);
  }


  &--horizontal {
    align-items: flex-start;
  }

  &--horizontal & {
    &__title {
      font-size: 21px;
      letter-spacing: -0.735px;
      color: $white-800;
    }

    &__icon {
      display: none;
    }

    &__value {
      margin-top: 1px;
      font-size: 38px;
      letter-spacing: -2.28px;
      line-height: 73%;
    }
  }

  &--horizontal-stacked {
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  &--horizontal-stacked & {
    &__title {
      font-size: 16px;
      color: $white-800;
    }

    &__icon {
      width: 44px;
    }

    &__value {
      margin-top: -3px;
      font-size: 24px;
      line-height: 84%;
    }
  }

  &__phase-indicator {
    &__wrapper {
      height: 30px;
      display: flex;
      flex-direction: row;
      gap: 0;
      align-items: stretch;
      justify-content: flex-start;
      flex: 1;
    }

    &__content {
      background: #FAC643;
      border-top: 2px solid #9A6E00;
      border-bottom: 2px solid #9A6E00;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      color: $white-800;
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 21px;
      font-weight: bold;
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.95);
      text-transform: uppercase;
      letter-spacing: 0.21px;
      box-sizing: content-box;
    }

    &__side-fill {
      fill: #FAC643;
    }

    &__side-stroke {
      stroke-width: 2px;
      stroke: #9A6E00;
      fill: transparent;
    }

    &--state-past {
      opacity: 0.4;
      filter: saturate(0);
    }

    &--state-upcoming {
      opacity: 0.6;
    }


    $phase0Fill: #50cfa2;
    $phase0Border: #128D61;

    &--phase-0 & {
      &__side-fill {
        fill: $phase0Fill;
      }

      &__side-stroke {
        stroke: $phase0Border;
      }

      &__content {
        background: $phase0Fill;
        border-top: 2px solid $phase0Border;
        border-bottom: 2px solid $phase0Border;
      }
    }

    $phase1Fill: #72d359;
    $phase1Border: #225A14;

    &--phase-1 & {
      &__side-fill {
        fill: $phase1Fill;
      }

      &__side-stroke {
        stroke: $phase1Border;
      }

      &__content {
        background: $phase1Fill;
        border-top: 2px solid $phase1Border;
        border-bottom: 2px solid $phase1Border;
      }
    }

    $phase2Fill: #bddf5b;
    $phase2Border: #6D8E0F;

    &--phase-2 & {
      &__side-fill {
        fill: $phase2Fill;
      }

      &__side-stroke {
        stroke: $phase2Border;
      }

      &__content {
        background: $phase2Fill;
        border-top: 2px solid $phase2Border;
        border-bottom: 2px solid $phase2Border;
      }
    }

    $phase3Fill: #dfda46;
    $phase3Border: #E0A126;

    &--phase-3 & {
      &__side-fill {
        fill: $phase3Fill;
      }

      &__side-stroke {
        stroke: $phase3Border;
      }

      &__content {
        background: $phase3Fill;
        border-top: 2px solid $phase3Border;
        border-bottom: 2px solid $phase3Border;
      }
    }

    $phase4Fill: #FAC643;
    $phase4Border: #9A6E00;

    &--phase-4 & {
      &__side-fill {
        fill: $phase4Fill;
      }

      &__side-stroke {
        stroke: $phase4Border;
      }

      &__content {
        background: $phase4Fill;
        border-top: 2px solid $phase4Border;
        border-bottom: 2px solid $phase4Border;
      }
    }

    $phase5Fill: #F58B50;
    $phase5Border: #E05A0F;

    &--phase-5 & {
      &__side-fill {
        fill: $phase5Fill;
      }

      &__side-stroke {
        stroke: $phase5Border;
      }

      &__content {
        background: $phase5Fill;
        border-top: 2px solid $phase5Border;
        border-bottom: 2px solid $phase5Border;
      }
    }

    $phase6Fill: #F24646;
    $phase6Border: #9F0D0D;

    &--phase-6 & {
      &__side-fill {
        fill: $phase6Fill;
      }

      &__side-stroke {
        stroke: $phase6Border;
      }

      &__content {
        background: $phase6Fill;
        border-top: 2px solid $phase6Border;
        border-bottom: 2px solid $phase6Border;
      }
    }
  }

  &__bars {
    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      height: 100%;
    }

    &__inner {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: flex-start;
      flex: 1;
    }

    &__health-title {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 32px;
      font-weight: bold;
      text-shadow: $textShadow;
      text-transform: uppercase;
      letter-spacing: 1px;
      text-align: center;
      color: $hp-red;
      margin-top: -2px;
    }

    &__health-absolute {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 18px;
      font-weight: bold;
      text-shadow: $textShadow;
      text-transform: uppercase;
      text-align: center;
      color: $white-700;
      margin-top: -9px;
    }

    &--horizontal & {
      &__title {
        text-align: left;
        color: $white-800;
        font-size: 21px;
        letter-spacing: 0.21px;
      }
    }

    &--inactive {
      opacity: 0.3;
    }
  }

  &__bar {
    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1 0 0;
      position: relative;
      padding-top: 20px;
    }

    &__title {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 14px;
      font-weight: bold;
      color: white;
      text-shadow: $textShadow;
      text-transform: uppercase;
      text-align: center;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &__bar-outer {
      background: linear-gradient(180deg, rgba(169, 169, 169, 0.23) 0%, rgba(84, 84, 84, 0.23) 100%);
      width: 13px;
      border-radius: 3px;
      box-shadow: $shadow;
      flex: 1;
      overflow: hidden;
    }

    &__bar-inner {
      background: linear-gradient(8deg, #F91E1E 0%, #F55 99.84%, rgba(255, 85, 85, 0.00) 100%);
      width: 100%;
      box-shadow: $shadow;
      height: 100%;
      transform-origin: bottom;

      &--resource {
        background: linear-gradient(1deg, #F9C91E 0%, #F2CD49 99.89%, rgba(242, 205, 73, 0.00) 100%);
      }
    }

    &--horizontal {
      height: 100%;
      padding-top: 0;
    }

    &--horizontal & {
      &__bar-outer {
        width: 100%;
        border-radius: 0;
        background: linear-gradient(-90deg, rgba(24, 24, 24, 1) 0%, rgba(24, 24, 24, 1) 100%);
        box-shadow: $shadow;
        overflow: visible;
      }

      &__bar-inner {
        transform-origin: left;
        filter: drop-shadow(0px 0px 9px #D03C3F)
      }
    }
  }

  &__branding {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;

    & > div {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 14px;
      font-weight: bold;
      color: white;
      text-shadow: $textShadow;
      text-transform: uppercase;
      text-align: center;
      line-height: 100%;
    }
  }
}
