.header-menu-user {
  &__content-type-picker {
    position: relative;
    z-index: 4;
    display: block;

    @include md {
      display: none;
    }
  }
}
