.navigation-item {
  > a {
    color: $lighter-gray;
  }

  > a > img {
    border: 1px solid $light-gray;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    vertical-align: -6px;
    --size: 20;
  }

  &--selected {
    > a {
      color: $white;
    }
  }

  &--highlight {
    @include highlight-glow;
    z-index: 1;
  }

  &__badge {
    position: absolute;
    top: -8px; left: -2px;
    color: $off-white;
  }
}
