.subscription-tiers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    width: fit-content;

    @include md() {
      flex-direction: row;
    }
  }
}
