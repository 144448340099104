.race-in-review-embed-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: $smaller-gap 0;
  gap: $tiny-gap;
  position: relative;
  font-size: $body-font-size;

  &__guild-logo {
    position: absolute;
    display: flex;
    align-items: center;
    left: 1.25em;
  }

  &__strip {
    height: 0.75em;
    width: 1em;
    flex-grow: 1;
  }

  &__race-in-review {
    height: 1.5em;
  }
}
