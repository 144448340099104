.mra-cta-banner {
  &__content {
    display: grid;
    justify-items: center;
    justify-content: center;
    margin: $small-gap 0;

    @include lg {
      width: max-content;
      max-width: 850px;
      margin: 0;
      align-items: center;
      grid-template-columns: auto auto;
      grid-gap: 5rem;
    }
  }

  &__title {
    font-size: $subtitle-font-size;
    font-weight: 900;
    text-transform: uppercase;
    color: $text-white;

    @include md {
      font-size: $page-title-font-size;
    }
  }

  &__description {
    @include body-text;
    color: $text-white;
  }

  &__action {
    margin-top: 1em;
  }

  &__featured-image {
    @include md {
      display: none;
    }

    @include lg {
      display: initial;
      order: 2;
    }
  }

  &__featured-image img {
    max-height: 14rem;
  }
}
