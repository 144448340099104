.builds-popular-talents-section {
  .talent-tree {
    background: $level-0-bg;
    border: 1px solid $level-1-border;

    @include md {
      border-radius: $border-radius;
    }
  }
}
