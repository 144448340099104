.horizontal-content {
  display: flex;

  &--tiny {
    > * {
      @include horizontal-margin-between($tiny-gap);
    }
  }

  &--smaller {
    > * {
      @include horizontal-margin-between($smaller-gap);
    }
  }

  &--small {
    gap: $small-gap;
  }

  &--content-padding {
    > * {
      @include horizontal-margin-between($content-padding);
    }
  }

  &--large {
    > * {
      @include horizontal-margin-between($large-gap);
    }
  }

  &--xlarge {
    > * {
      @include horizontal-margin-between($xlarge-gap);
    }
  }

  &--stretch-wrap {
    align-items: stretch;
    flex-wrap: wrap;
  }

  &--desktop-no-wrap {
    @include lg {
      flex-wrap: nowrap;
    }
  }
}
