.builds-tier-set-section {
  margin-top: 12px;

  > * {
    @include vertical-margin-between(3.50rem);
  }

  &__effects {
    display: flex;
    flex-direction: column;
    gap: pxToRem(8);
    margin-top: 1rem;

    &__effect {
      border: 1px solid $slightly-dark-gray;
      border-radius: 0.25rem;
      background: $level-0-bg;
      display: flex;
      padding: $smaller-gap;
      gap: $medium-gap;

      &__icon {
        display: none;
        align-items: center;

        img.icon__image {
          border-radius: 5px;
        }

        @include sm {
          display: flex;
        }
      }

      &__bonus {
        display: flex;
        flex-direction: column;
        gap: 4px;

        &-title {
          font-size: pxToRem(24) !important;
          font-weight: 700 !important;
        }
      }
    }
  }

  &__most-popular-slots {
    &--table {
      margin-top: 0.5rem;
    }
  }
}
