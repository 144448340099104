.guild-progress-encounter-pulls-embed-instructions-tile {
  &__content {
    display: grid;
    grid-gap: $small-gap;

    @include md {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__preview-container {
    display: grid;
    justify-content: center;
  }

  &__preview {
    min-height: 500px;
    width: 150px;

    * {
      pointer-events: none;
      user-select: none;
    }
  }
}
