.image-and-markdown-tile {
  &__content {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-gap: $content-padding;

    @include lg() {
      grid-template-columns: auto 1fr;
    }
  }

  &__image {
    display: none;

    @include lg() {
      display: block;
      margin-top: -$content-padding;
      margin-left: -$content-padding;
      margin-bottom: -$content-padding;
    }
  }
}
