.character-name-faction-server-region-title {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $tiny-gap;

  &__name, &__faction, &__server, &__region {
    @include body-text;
    font-weight: bold;
  }

  &__faction, &__server {
    display: flex;
    align-items: center;
  }

  &__name {
    font-size: $small-title-font-size;
  }

  &__tooltip {
    font-size: $smaller-font-size;
  }
}
