.search-select-autocomplete-option {
  display: flex;

  &--header-search {
    min-width: 0;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
  }

  &.menu-select__option:hover {
    background-color: $dark-blue !important;
    cursor: pointer;
  }

  &__left-wrapper {
    width: 40%;
  }

  &__right-wrapper {
    width: 60%;
    display: flex;
    justify-content: space-between;
  }

  &__type {
    color: $lighter-gray;
    display: none;

    @include lg() {
      font-size: $smaller-font-size;
    }

    @include xl() {
      display: initial;
    }
  }

  &--header-search & {
    &__left-wrapper {
      flex-shrink: 1;
      min-width: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-grow: 1;
      margin-right: $gap-16;
    }

    &__right-wrapper {
      justify-content: flex-end;
      flex-shrink: 2;
      min-width: 0;
      flex-grow: 0;

      @include lg() {
        justify-content: space-between;
      }
    }

    &__type {
      @include lg() {
        display: initial !important;
      }

      @media (min-width: 1200px) {
        display: none !important;
      }

      @include xl() {
        display: none !important;
      }

      @media (min-width: 1600px) {
        display: initial !important;
      }
    }

    &__actor-class {
      color: $white-800;
    }
  }
}
