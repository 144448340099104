.character-recruitment-profile-tile{
  &__character-select{
    display: flex;

    &__thumbnail{
      display: none;
      @include sm {
        display: block;
        margin-right: $content-padding;
      }
    }
    &__input{
      flex: 1;
    }
  }
}