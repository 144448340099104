.talent-tree-inline {
  --current-node-width: var(
    --talent-tree-node-width,
    #{$talent-tree-node-default-size}
  );
  display: inline-flex;
  overflow-x: scroll;

  width: inherit;

  &::-webkit-scrollbar {
    display: none;
  }

  // for ff; already hidden in chrome via above
  scrollbar-width: none;

  &__button {
    position: sticky;

    appearance: none;
    border: none;
    cursor: pointer;

    svg {
      path {
        fill: white;
      }
    }

    &[disabled] {
      cursor: default;

      svg {
        path {
          fill: #727272;
        }
      }
    }

    &[data-direction='left'] {
      --gradient-rotation: 90deg;

      left: 0;
      z-index: 1;

      padding-right: 0.75rem;
    }

    &[data-direction='right'] {
      --gradient-rotation: 270deg;

      right: 0;
      padding-left: 0.75rem;

      svg {
        transform: rotate(180deg);
      }
    }

    background: linear-gradient(
      var(--gradient-rotation),
      rgb(0 0 0 / 85%) 0%,
      rgb(0 0 0 / 75%) 60%,
      rgba(0, 0, 0, 0) 100%
    );

    &:not([disabled]):hover {
      background: linear-gradient(
        var(--gradient-rotation),
        $active-300-color-1 0%,
        transparentize($active-300-color-2, 0.2) 74.48%,
        transparentize($active-300-color-2, 1) 100%
      ) !important;
    }
  }

  &--transparent {
    background-color: unset;
  }

  &__hero-icon--wrapper {
    display: flex;
    padding: 0.5em;
    width: 1.5em;
    height: 1.5em;

    & img {
      border: 1px solid gold;
      border-radius: 50%;
    }
  }

  &--tree-container {
    display: inline-flex;
    background-color: black;
    width: inherit;

    &--preview {
      overflow-x: hidden;
    }

    &__row-container {
      display: inherit;

      &[data-kind='class'] {
        --talent-tree-inline-steps-class: 0;
        --var: var(--talent-tree-inline-steps-class);
      }

      &[data-kind='spec'] {
        --talent-tree-inline-steps-spec: 0;
        --var: var(--talent-tree-inline-steps-spec);
      }

      transform: translateX(
        calc(var(--max-translate-x) + var(--var, 0) * 16px)
      );

      > div {
        display: flex;
        align-items: center;
        // required to have <MultiplePointsIndicator /> not clip
        padding: 0.5rem 0;

        &:not(:first-of-type) {
          margin-left: calc(0.5 * var(--current-node-width));
        }

        .talent-tree-node:not(:first-of-type) {
          margin-left: calc(var(--current-node-width) / 8);
        }
      }
    }
  }

  &--compact {
    .talent-tree-inline--tree-container__row-container {
      > div {
        &:not(:first-of-type) {
          margin-left: calc(0.25 * var(--current-node-width));
        }
      }
    }
  }

  &--row-expander {
    display: none;
    font-weight: bold;
    font-size: 1rem;

    @include md() {
      display: inline-flex;
    }

    align-items: center;
    will-change: transform;
    cursor: pointer;

    appearance: none;
    background: none;
    border: unset;
    color: white;

    &:hover {
      background: linear-gradient(
        270deg,
        $active-300-color-1 0%,
        transparentize($active-300-color-2, 0.2) 74.48%,
        transparentize($active-300-color-2, 1) 100%
      ) !important;
    }

    &__label {
      &--short {
        @include lg() {
          display: none;
        }
      }

      &--long {
        display: none;

        @include lg() {
          display: initial;
        }
      }
    }
  }

  &--preview {
    .talent-tree-inline--tree-container__row-container {
      @include md() {
        mask: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 100 100' preserveAspectRatio='none' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' x='0%25' y='0%25' fill='url(%23rad)'/%3E%3Cdefs%3E%3ClinearGradient id='rad'%3E%3Cstop offset='5%25'/%3E%3Cstop offset='100%25' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        -webkit-mask: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 100 100' preserveAspectRatio='none' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' x='0%25' y='0%25' fill='url(%23rad)'/%3E%3Cdefs%3E%3ClinearGradient id='rad'%3E%3Cstop offset='5%25'/%3E%3Cstop offset='100%25' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
        -webkit-mask-size: cover;
        mask-size: cover;

        .talent-tree-node {
          pointer-events: none;
          user-select: none;
        }
      }
    }
  }
}

