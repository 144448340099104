.character-recruitment-search-results-tile {
  &__search-button {
    text-align: center;
  }

  &__results {
    display: grid;
    grid-gap: $content-padding;
    position: relative;
  }

  &__results-loading-indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
