.content {
  @include fill-view-width;
  margin: $small-gap auto;

  &--narrow-width {
    max-width: 1024px;
  }

  &--medium-width {
    max-width: 1350px;
  }

  @include md {
    padding-left: $small-gap;
    padding-right: $small-gap;
  }

  &--builds-wrapper {
    position: relative;
    z-index: 1;
  }
}
