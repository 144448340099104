.badge-label {
  border-radius: $border-radius;
  padding: 0 $tiny-gap;
  font-size: 0.9em;
  display: inline-flex;
  align-items: center;

  &--gray {
    background-color: $gray;
  }

  &--dark-gray {
    background-color: $dark-gray;
  }

  &--red {
    background-color: $red;
    color: $white;
    @include text-shadow();
  }

  &--orange {
    background-color: $orange;
    color: $white;
    @include text-shadow();
  }

  &--green {
    background-color: $default-green;
    color: $white;
    @include text-shadow();
  }

  &--level-1 {
    height: 25px;
    color: $white-800;
    font-weight: 800;
    border-radius: $border-radius;
    background: $level-1-bg;
    border: 1px solid $level-1-border;
    letter-spacing: 0;
    @include text-shadow();
  }
}
