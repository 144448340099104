.expand-indicator {
  &__wrapper {
    display: flex;
    width: 45px;
    height: 25px;
    align-items: center;
    justify-content: center;
    background: $level-1-bg;
    border: 1px solid $level-2-border;
    border-radius: 13px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.8);
    box-sizing: border-box;
    transition: background 0.24s ease-out;
    cursor: pointer;
    user-select: none;
  }

  &__icon {
    font-size: 28px !important;
    color: $white-800;
    padding-top: 1px;
    @include md {
      transition: transform 0.32s ease-out;
    }
  }

  &--expanded {
    background: $active-400 !important;

    .expand-indicator__icon {
      transform: scaleY(-1);
    }
  }

  &--compact {
    border: none;
    background: transparent;
    border-radius: 0px;
    width: auto;
    font-size: 30px;
    box-shadow: none;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    color: $white-800;
    height: 24px;
    padding: 0;
    padding-bottom: 4px;
    flex: 0 0 24px;

    @include md {
      transition: transform 0.28s ease-out;
    }
  }

  &--compact.expand-indicator--expanded {
    background: transparent !important;

    .expand-indicator__icon {
      transform: scaleY(-1) translateY(0px);
    }
  }
}
