@use "resources/sass/tools";

.header-bottom-bar {
  height: $header-bottom-height;
  width: 100%;
  background: $level-0-bg;
  border-bottom: 1px solid $light-gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
  z-index: 2;

  &__left,
  &__right {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    height: 100%;
  }

  &__right {
    justify-content: flex-end;
  }

  &__item {
    display: inline-flex;
    padding: 0 $gap-16;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: $white-800;
    font-weight: 900;
    font-size: $smaller-font-size;
    letter-spacing: 0.105em;
    text-transform: uppercase;
    text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.35);
    background: transparent;
    transition: background 0.14s ease-out;
    cursor: pointer;
    user-select: none;
    border: none;
    line-height: $smaller-font-size;
    position: relative;

    font-family: inherit; // required for ff to not override the font

    &.ff {
      @media (max-width: 940px) {
        padding: 0 $gap-8;
      }

      @media (max-width: 820px) {
        padding: 0 $gap-4;
      }
    }

    &:hover {
      background: $active-300;
    }

    &-new-badge {
      @include tools.new-badge();
    }

    &-icon {
      font-size: 28px !important;
      margin-left: $gap-8;

      &--sign-in {
        margin-right: 0.5rem;
        margin-left: 0;
        font-size: 20px !important;
        font-weight: 400 !important;
      }
    }

    &-button-icon {
      height: calc(100% - 1rem);
      padding: 0.5rem 0.5rem 0.5rem 0;
    }

    &--sign-in {
      color: $white-800 !important;
      font-weight: 600;
      letter-spacing: 0.025rem;
      text-transform: none;
      font-size: $smaller-body-font-size;
    }

    &-image {
      width: 26px;
      height: 26px;
      border-radius: 26px;
      border: 1px solid $level-2-border;
      overflow: hidden;
      object-fit: cover;
      margin-right: $gap-8;
      box-sizing: border-box;
    }
  }
}
