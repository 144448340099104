.header-menu-content {
  padding-top: 0px;
  background: $level-0-bg;
  transition: all 0.24s ease-out;

  @include md {
    padding-top: 64px;
  }

  &__site-select {
    display: block;

    @include md {
      display: none;
    }
  }

  &--no-expansion {
    padding-top: 0;
  }

  &__content-type-picker {
    position: relative;
    width: 100%;
    z-index: 4;
    display: block;

    @include md {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
    }
  }

  &__expansion-picker {
    width: 100%;
    position: relative;
    z-index: 3;

    @include md {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__data-loading {
    min-height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 390px;
    border: 0px solid $level-0-border;
    border-bottom: 1px;
    border-top: 1px;
  }

  &__panel {
    opacity: 1;
    transition: 0.14s opacity ease-out;

    &--menuOpen {
      opacity: 0.2;
    }
  }
}
