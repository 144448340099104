.builds-consumables-tables-section {
  &__group {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(1, 100%);

    @include lg {
      grid-template-columns: repeat(2, calc(50% - 0.75rem));
    }
  }

  &--wide & {
    &__group {
      grid-template-columns: 100%;
    }
  }
}
