.recruitment-guild-search-result-zone-metrics-tile {
  &__container {
    display: flex;
    justify-content: space-evenly;
    align-content: stretch;
  }

  &__zone-name {
    grid-column: span 2;
    text-align: center;
    font-weight: bold;
    margin: $tiny-gap 0;
  }

  &__ranks {
    display: grid;
    flex: 1 1 auto;
    justify-content: space-evenly;
    @include body-text;
  }

  &__rank {
    display: grid;
    text-align: center;
  }

  &__rank-value {
    font-weight: bold;
  }
}
