.featured-article {
  &__container {
    display: flex;
    justify-content: center;
    overflow: hidden;
    height: 100%;
  }

  &__article-preview {
    border: 1px solid $light-gray;
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__article-preview-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center;
    background-size: cover;
  }

  &__article-preview-title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: $small-gap;
    background-color: rgba(0, 0, 0, 0.75);
    font-weight: bold;

    .markdown__content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__article-preview-published-at {
    text-align: right;
    font-weight: normal;
  }

  &__article-info-container {
    display: flex;
    justify-content: space-between;
    margin-top: $small-gap;
  }

  &__latest-news-label {
    color: $highlight;
  }
}
