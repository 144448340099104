.editable-specs-and-priorities {
  overflow: visible;

  &__bulk-update-container {
    @include md {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__bulk-update {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $tiny-gap;
    @include body-text;

    @include md {
      grid-template-columns: minmax(auto, 150px) minmax(auto, 150px) 0 minmax(auto, 120px);
    }
  }

  &__bulk-update-button {
    text-align: center;
  }

  &__spec {
    @include body-text;
    display: grid;
    grid-template-columns: auto 1fr minmax(auto, 220px);
    grid-gap: $tiny-gap;
    margin-bottom: $tiny-gap;
    align-items: center;
  }

  &__buttons {
    display: flex;
    justify-content: center;

    button {
      margin: 0;
      @include horizontal-margin-between($small-gap);
    }
  }

  &__categories {
    &--multi-column {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      justify-content: center;

      .editable-specs-and-priorities__category {
        min-width: 380px;
        margin-right: $xlarge-gap;
        margin-bottom: $content-padding;
      }

      margin-right: -$xlarge-gap !important;
      margin-bottom: -$content-padding !important;
    }
  }
}
