.report-component-sandbox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $small-gap;
  height: 700px;

  &__column {
    height: 700px;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
  }

  &__result {
    overflow-y: auto;
  }

  &__no-result, &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__loading {
    height: 100%;
  }

  &__no-result {
    height: 100%;
    text-align: center;
    @include body-text;
  }
}
