.zone-rank-filter {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: $large-gap;

  @include body-text;

  @include sm {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "scope ."
    "slider slider";

    &__text-field {
      width: 50%;
    }
  }

  @include md {
    column-gap: $large-gap;
  }

  &__field {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    column-gap: $small-gap;

    @include sm {
      grid-template-columns: 0.6fr 1.4fr;

      &--scope {
        grid-area: scope;
      }
    }
  }

  &__select-field {
    @include md {
      width: 50%;
    }
  }

  &__field-label {
    @include sm {
      text-align: right;
    }
  }

  &__summary {
    @include sm {
      margin-left: $large-gap;
    }
  }

  &__filter-name {
    font-weight: bold;
    margin: $small-gap 0;

    @include body-text;

    @include sm {
      margin-left: $large-gap;
    }
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__slider {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
