.guild-user-management-tile__wrapper {
  td, th {
    display: block;
    text-align: left;

    @include sm {
      display: table-cell;
      text-align: center;
    }
  }

  th {
    display: none;
    @include sm {
      display: table-cell;
    }
  }

  td[data-label]:before {
    content: attr(data-label);
    font-weight: bold;
  }


  td[data-label]:before {
    content: attr(data-label) ": ";
    @include sm {
      content: '';
    }
  }


  .guild-user-management-tile__row {
    &:hover {
      transition: none;
    }

    &--active {
      background: $blue !important;
    }

    .guild-user-management-tile__commands{
      > * {
        padding: 0 0.5rem;
        margin: 0 0.5rem;
        @include sm {
          padding: 0;
          margin: 0 0.25rem;
        }
      }
    }

    .guild-user-management-tile__characters {
      > * {
        margin-right: $smaller-gap;
      }
    }
  }
}