.report-tag-table__container {
  @include lg {
    width: 66%;
    margin: 0 auto;
  }

  .report-tags-table__body__commands {
    > * {
      margin: 0 0.5rem;
    }
  }
}