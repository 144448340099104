.vertical-content {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &--position-left {
    align-items: baseline;
  }

  &--gap-none {
    > * {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }

  &--gap-tiny {
    > * {
      @include vertical-margin-between($tiny-gap);
    }
  }

  &--gap-smaller {
    > * {
      @include vertical-margin-between($smaller-gap);
    }
  }

  &--gap-small {
    > * {
      @include vertical-margin-between($small-gap);
    }
  }

  &--gap-medium {
    > * {
      @include vertical-margin-between($medium-gap);
    }
  }

  &--gap-content-padding {
    > * {
      @include vertical-margin-between($content-padding);
    }
  }

  &--gap-large {
    > * {
      @include vertical-margin-between($large-gap);
    }
  }

  &--gap-larger {
    > * {
      @include vertical-margin-between($larger-gap);
    }
  }

  &--gap-xlarge {
    > * {
      @include vertical-margin-between($xlarge-gap);
    }
  }
}

.react-tile__content--narrow {
  // If inside a narrow tile, use a smaller margin
  .vertical-content--gap-content-padding {
    > * {
      @include vertical-margin-between($small-gap);
    }
  }

  // However, if inside a regular tile (such as a SubTile) inside a narrow tile, go back to using regular margin
  .react-tile__content--regular {
    .vertical-content--gap-content-padding {
      > * {
        @include vertical-margin-between;
      }
    }
  }
}
