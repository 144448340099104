.builds-item-breakdown {
  border: 1px solid $slightly-dark-gray;
  border-radius: 0.25rem;
  background: $level-0-bg;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "icon item popularity";
  grid-gap: $smaller-gap;
  align-items: center;
  padding: $tiny-gap;

  &--right {
    @include lg {
      grid-template-areas: "popularity item icon";
      text-align: right;
    }
  }

  &__icon {
    grid-area: icon;
    img.icon__image {
      border-radius: 5px;
    }
  }

  &__item {
    grid-area: item;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__item-name {
    font-size: $subsubtitle-font-size;
    font-weight: 600;
  }

  &__item-slot {
    font-weight: 500;
    color: $white-700;
  }

  &__item-description {
    font-size: $smaller-body-font-size;
  }

  &__popularity {
    grid-area: popularity;
    text-align: right;
    font-size: $body-font-size;
    font-weight: 700;
    padding: 0 $tiny-gap;
  }
}
