.progress-race-head-to-head-notification-tile {
  position: relative;

  &__content {
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__logos {
    display: none;

    @include md {
      display: flex;
      align-items: center;
    }

    &__versus {
      display: inline;
      font-size: $subtitle-font-size;
      margin: 0 0.5rem;
      vertical-align: middle;
    }
  }

  &__text {
    font-size: $smaller-font-size;

    @include md {
      font-size: $body-font-size;
    }
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;

    > * {
      pointer-events: none;
    }
  }
}
