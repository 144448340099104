.builds-hero-talents-section {
  &__talent-trees {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $level-0-border;
    background: $level-0-bg;
    border-radius: $border-radius;
    padding: $small-gap;
    gap: $larger-gap;
    
    @include lg() {
      flex-direction: row;
      justify-content: space-evenly;
      zoom: 1.15;
      gap: 0;
    }
  }
}
