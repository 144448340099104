.recruitment-spot-icon-specs-selector {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: $small-gap;
  margin: 20px 0;

  &--multi-column {
    grid-template-columns: 1fr 1fr;
  }

  &__role-display-container {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include sm {
      grid-template-columns: 1fr 1.5fr;
    }

    margin-bottom: $large-gap;
    @include no-vertical-margin-around;
  }

  &__spec-display-container {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &--single-column {
      grid-template-columns: 1fr;
    }
  }

  &__spec-icons-container {
    display: flex;
    flex-direction: row;
  }

  &__role-container {
    display: grid;
    grid-gap: $smaller-gap;
    grid-template-columns: 1fr;

    @include sm {
      grid-template-columns: 1fr 1.5fr;
    }
  }
  
  &__spec-icons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__spec-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;

    &:hover {
      filter: none;
    }

    &--editable {
      cursor: pointer;
      margin: 2px;
    }
  }

  &__spec-icon img {
    border-radius: 8px;
  }

  &__selected-spec-icon img {
    border: 3px solid $highlight;
  }

  &__disabled-spec-icon {
    cursor: not-allowed;

    &:hover {
      filter: grayscale(100%);
    }
  }

  &__role-label-container {
    display: flex;
    align-items: center;
  }

  &__role-label {
    margin-left: 10px;
    user-select: none;
  }

  &__role-label-display {
    margin-right: 10px;
    user-select: none;
    text-align: right;
  }
}
