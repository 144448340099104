.recruitment-guild-search-result-recruitment-tile {
  &__spec-priority {
    @include body-text;
    font-style: italic;
  }

  &__more-specs {
    display: flex;
    justify-content: center;
    font-size: $smaller-font-size;
  }
}