@use "./variables";

@mixin link-looking-button {
  background: none;
  border: none;
  color: variables.$link-blue;
  @include body-text;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    color: variables.$lighter-gray;
    cursor: unset;
  }
}

@mixin link-looking-button-with-icon {
  @include link-looking-button;

  i {
    margin-right: 3px;
  }
}

@mixin archon-style-text {
  background-clip: text;
  background: variables.$archon-title-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  font-style: italic;
  padding-left: 6px;
  padding-right: 6px;
  text-transform: uppercase;
}
