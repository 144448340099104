.icon-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-overflow: ellipsis;

  &__item {
    position: relative;
    padding: $tiny-gap $small-gap;

    &--highlight {
      @include highlight-glow;
      z-index: 1;
    }

    &:hover {
      background-color: $dark-blue;

      .icon-list__item-title, .icon-list__item-description {
        color: $white;
      }
    }

    .icon-list__item-badge {
      position: absolute;
      top: -2px; left: -2px;
      color: $off-white;
    }
  }

  &__item-link {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: $small-gap;
    align-items: center;
    width: 100%;
  }

  &__item-icon {
    width: 32px;
    height: 100%;
    text-align: center;
    object-fit: cover;

    &--border {
      border: 1px solid $gray;
    }

    .zmdi {
      color: $off-white;
      font-size: $title-font-size;
    }
  }

  &__item-text {
    display: flex;
    flex-direction: column;

    &--no-wrap-title {
      overflow-x: hidden;
    }
  }

  &__item-title {
    color: $text-gray;

    &--color-white {
      color: $white;
    }

    &--size-large {
      font-size: $body-font-size;
      font-weight: bold;
    }

    &--no-wrap-title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden;
    }
  }

  &__item-description {
    color: $text-dark-gray;

    &--line-clamp {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
