.guild-recruitment-search-filters {
  display: flex;
  gap: $content-padding;

  flex-direction: column;
  @include lg {
    flex-direction: row;
  }

  &__filter {
    flex: 1 1 0;
  }

  &__button {
    display: flex;
    align-items: center;
    flex-direction: column;
    @include lg {
      flex-direction: row;
      align-items: flex-end;
    }
  }
}
