@mixin menu {
  @include body-text;
  margin: 0;
  background: $level-1-bg;
  border: 1px solid $level-1-border;
  border-radius: $border-radius;
  font-family: $body-font-family;
  min-width: 6em;
  padding: $tiny-gap $content-padding;
  white-space: nowrap;

  &:hover, &:active, &:focus-visible, &--is-focused {
    border: 1px solid $level-2-border !important;
    outline: none;
  }

  &--style-disabled, &:disabled {
    border: none !important;
    color: $lighter-gray !important;
  }
}

@keyframes brightness-transition-in {
  from {
    filter: brightness(1.0);
  }
  to {
    filter: brightness(1.1);
  }
}

@keyframes brightness-transition-out {
  from {
    filter: brightness(1.1);
  }
  to {
    filter: brightness(1.0);
  }
}

.react-button {
  border: 0;
  border-radius: $border-radius;
  box-sizing: border-box;
  color: $off-white !important;
  font-size: $button-font-size;
  min-width: 7em;
  padding: $subtitle-v-padding $content-padding;
  white-space: nowrap;

  &:not(&--style-disabled, :disabled) {
    animation-name: brightness-transition-out;
    animation-duration: 100ms;
    animation-timing-function: ease-in-out;
  }

  &:hover {
    cursor: pointer;
    filter: brightness(1.1);
  }

  &:hover:not(&--style-disabled, :disabled) {
    animation-name: brightness-transition-in;
    animation-duration: 100ms;
    animation-timing-function: ease-in-out;
  }

  &--style-disabled,
  &--style-disabled:hover,
  &:disabled,
  &:disabled:hover {
    cursor: not-allowed;
    filter: grayscale(100%);
  }

  &--style-primary {
    background-color: $blue;
  }

  &--style-warning {
    background-color: $orange;
  }

  &--style-color-light-blue {
    color: $light-blue !important;
  }

  &--style-color-red {
    color: $red !important;
  }

  &--style-icon {
    font-size: $title-font-size;
  }

  &--style-unstyled {
    background: none;
    border: none;
    border-radius: unset;
    margin: 0;
    min-width: unset;
    padding: 0;

    &.react-button--style-icon {
      padding: 0 $small-gap;
    }
  }

  &--style-full-size {
    white-space: normal;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &--style-link {
    @include body-text;
    background: none;
    border: none;
    border-radius: unset;
    color: $link-blue !important;
    min-width: unset;
    padding: 0;
  }

  &--style-required-border {
    border: 1px solid $required;
    border-radius: $border-radius;
    padding: 0 $smaller-gap;
  }

  &--style-highlight-glow {
    @include highlight-glow;
  }

  &--style-menu {
    @include menu;
  }

  &--style-menu-selected {
    @include menu;
    background: $blue;
  }

  &--style-search {
    height: 42px;
    font-size: 26px;
    width: 42px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $active-300;
    filter: none;
    box-sizing: border-box;
    border: 1px solid $level-2-border !important;
    border-radius: 0px 3px 3px 0px;
    color: $white-800 !important;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;

    &-disabled {
      cursor: default;
      pointer-events: none;
    }

    @include md {
      height: 31px;
      font-size: 22px;
      width: initial;
    }
  }

  &--style-gradient-rounded {
    background: $active-300;
    border: 1px solid $level-1-border !important;
    color: white !important;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    font-size: $smaller-body-font-size;
    font-weight: 400 !important;
    padding: 7px 16px;
    border-radius: 6px;
    line-height: $body-line-height;

    &:hover {
      background: $active-400;
    }
  }

  &--style-slim {
    font-size: $slim-button-font-size;
    padding: 0 10px;
    min-width: 0;
  }

  &--style-full-width {
    width: 100%;
  }

  &--style-hard-borders {
    border-radius: 0;
  }

  &--style-bold {
    font-size: $button-font-size;
    font-weight: 700 !important;
    letter-spacing: 0.4px;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.55);
  }
  
  &--style-uppercase {
    font-weight: 900 !important;
    text-transform: uppercase;
  }

  &--style-body-font-size {
    font-size: $body-font-size;
  }
}
