.recruitment-character-search-result-zone-metrics-tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 $small-gap;

  > * {
    @include vertical-margin-between($smaller-gap);
  }

  @include lg {
    display: grid !important;
    grid-template-columns: 1fr 300px 1fr;
    grid-gap: $smaller-gap;

    > * {
      @include vertical-margin-between(0px);
    }
  }

  &__name {
    font-size: $body-font-size;
    font-weight: bold;
  }

  &__bar {
    width: 100%;
  }

  &__metrics {
    font-size: $subsubtitle-font-size;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @include lg {
      justify-content: flex-end;
    }
  }

  &__private-metric-label {
    font-size: $body-font-size;
    margin-bottom: 1px;
  }

  &__metric {
    font-weight: bold;
    margin-left: $small-gap;
  }
}
