.specs-and-priorities {
  &__spec {
    @include body-text;
    display: grid;
    grid-template-columns: auto auto 1fr;
    grid-gap: $tiny-gap;
    align-items: center;
  }

  &__show-or-hide-button {
    text-align: center;
  }

  &__categories {
    &--multi-column {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      justify-content: center;

      .specs-and-priorities__category {
        min-width: 250px;
        margin-bottom: $content-padding;
      }

      margin-bottom: -$content-padding !important;
    }
  }
}
