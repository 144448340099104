.time-machine-competition-summary {
  font-weight: 900;
  text-transform: uppercase;
  padding: $smaller-gap;
  border-radius: $border-radius;
  background: linear-gradient(140.09deg, rgba(6, 6, 6, 0.7) 0%, rgba(20, 20, 20, 0.7) 100%);
  display: flex;
  flex-direction: column;
  gap: $smaller-gap;

  &__title {
    font-size: 17px;
    color: $white-700;
    letter-spacing: 0.05em;
    line-height: 1.17em;
  }

  &__subtitle {
    font-size: 23px;
    color: $white-800;
    letter-spacing: 0.115em;
    line-height: 1.17em;
  }

  &__content {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: $smaller-gap;

    &--single-column {
      grid-template-columns: 1fr;
    }
  }

  &__header-and-before {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__header {
    margin-top: $smaller-gap;
    text-align: center;
  }

  &__before {
    padding-bottom: $small-gap;
    border-bottom: 1px solid transparent;
    max-width: 275px;
  }

  &__before-title {
    text-align: center;
    font-size: 18px;
    color: $white-600;
    line-height: 1.17em;
    margin-bottom: $smaller-gap;
  }

  &__guild {
    position: relative;
    background-size: cover;
    background-position: center center;
    text-shadow: 0 2px 2px #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid $level-2-border;
    border-top: none;

    &--first {
      border-top: 1px solid $level-2-border;
    }
  }

  &__guild-background-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &__guild-content {
    position: relative;
    z-index: 1;
    padding: $smaller-gap;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    grid-gap: $smaller-gap;
  }

  &__guild-rank-and-name {
    min-width: 0;
  }

  &__guild-rank-and-name, &__guild-encounter {
    display: flex;
    flex-direction: column;
    gap: $tiny-gap;
  }

  &__guild-encounter {
    text-align: right;
  }

  &__guild-rank, &__guild-encounter-name {
    font-size: 14px;
    color: $white-700;
    line-height: 1.17em;
  }

  &__rank-difference {
    letter-spacing: initial;

    &--positive {
      color: $green;
    }

    &--negative {
      color: $red;
    }
  }

  &__guild-name {
    letter-spacing: 0.11em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__guild-name, &__guild-encounter-stats {
    font-size: 16px;
    color: $white-800;
    line-height: 1.17em;
  }

  &__now {
    background: $level-0-bg;
    border: 1px solid $level-0-border;
    padding: $small-gap $smaller-gap;
    border-radius: $medium-border-radius;
  }

  &__now & {
    &__guild-rank, &__guild-encounter-name {
      font-size: 15px;
    }

    &__guild-name, &__guild-encounter-stats {
      font-size: 18px;
    }
  }

  &__now-title {
    text-align: center;
    font-size: 40px;
    color: $white-800;
    line-height: 1.17em;
    letter-spacing: 0.085em;

    &--with-bottom-margin {
      margin-top: 10px;
      margin-bottom: 37px;
    }
  }

  &__guilds-and-recent-kills {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: $smaller-gap;

    &--single-column {
      grid-template-columns: 1fr;
    }
  }

  &__recent-kills-title {
    text-align: center;
    font-size: 15px;
    color: $white-700;
    line-height: 1.17em;
    margin-bottom: $small-gap;
    max-width: 80px;
  }

  &__recent-kills {
    display: flex;
    gap: $small-gap;
    flex-wrap: nowrap;
    align-items: center;
    max-width: 80px;
  }

  &__recent-kill {
    position: relative;
    margin-top: $small-gap;

    .icon--bordered .icon__image {
      border: 1px solid $green !important;
    }

    &--no-pulls {
      margin-top: 0;
    }
  }

  &__recent-kill-pulls-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__recent-kill-pulls {
    position: absolute;
    margin-left: 85%;
    margin-bottom: 95%;
    background: $active-300;
    border: 1px solid $level-2-border;
    border-radius: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
    aspect-ratio: 1/1;
    min-width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1px;
    font-size: 14px;
    color: $white-800;
  }

  &__tooltip {
    z-index: 2;
  }

  &__tooltip-content {
    position: relative;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1.17em;
    background-size: cover;
    background-position: center;
  }

  &__tooltip-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &__tooltip-title, &__tooltip-subtitle,
  &__tooltip-best-percent, &__tooltip-pull-count-difference,
  &__tooltip-best-percent-label, &__tooltip-pull-count-difference-label {
    position: relative;
    z-index: 3;
  }

  &__tooltip-title {
    font-size: 18px;
    color: $white-900;
    letter-spacing: 0.08em;
  }

  &__tooltip-subtitle {
    font-size: 15px;
    color: $white-700;
    letter-spacing: 0.055em;
  }

  &__tooltip-best-percent, &__tooltip-pull-count-difference {
    font-size: 22px;
    color: $white-900;
    margin-top: $smaller-gap;
    margin-bottom: $tiny-gap;
  }

  &__tooltip-best-percent-label, &__tooltip-pull-count-difference-label {
    font-size: 15px;
    color: $white-700;
    letter-spacing: -0.005em;
  }

  &--single-column & {
    &__content {
      grid-template-columns: 1fr;
    }

    &__now {
      border: none;
      padding: 0;
    }

    &__now-title {
      display: none;
    }

    &__before {
      display: none;
    }

    &__guilds-and-recent-kills {
      grid-template-columns: 1fr;
    }

    &__recent-kills-title, &__recent-kills {
      display: none;
    }
  }

  &--very-narrow & {
    &__guild-content {
      grid-template-columns: 1fr;
    }

    &__guild-encounter {
      text-align: left;
    }
  }
}
