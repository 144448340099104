/* Adapted from https://codepen.io/maoberlehner/pen/bQGZYB */

.skeleton-box {
  display: inline-block;
  height: 0.9em;
  position: relative;
  overflow: hidden;
  background: $level-1-bg;
  border-radius: $border-radius;
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
                  90deg,
                  rgba(255, 255, 255, 0) 0,
                  rgba(255, 255, 255, 0.03) 20%,
                  rgba(255, 255, 255, 0.06) 60%,
                  rgba(255, 255, 255, 0)
  );
  animation: shimmer 2.5s ease-in-out infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
