.guild-progress-encounter-pulls {
  flex: 1;
  padding: 6px;
  background-position: center;
  background-size: cover;
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: flex-end;
  text-align: center;
  text-shadow: 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000, 0 1px 0 #000, 0 -1px 0 #000, -1px 0 0 #000, 1px 0 0 #000, 2px 2px 0 #000, -2px 2px 0 #000, 2px -2px 0 #000, -2px -2px 0 #000, 0 2px 0 #000, 0 -2px 0 #000, -2px 0 0 #000, 2px 0 0 #000, 1px 2px 0 #000, -1px 2px 0 #000, 1px -2px 0 #000, -1px -2px 0 #000, 2px 1px 0 #000, -2px 1px 0 #000, 2px -1px 0 #000, -2px -1px 0 #000;
  font-size: 18px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 115%;

  &--not-yet-progressed {
    filter: grayscale(1);
  }

  &__message {
    font-size: 13px;
    line-height: 140%;
  }

  &__difficulty {
    font-size: $body-font-size;
  }

  &__best-percent,
  &__pull-count {
    font-size: 21px;
    color: $white-700;
  }
}
