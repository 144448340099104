.guild-rankings-summary-boxes {
  display: flex;
  flex-wrap: wrap;
  gap: $smaller-gap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: stretch;

  &__box {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-width: 185px;
    row-gap: $smaller-gap;
  }

  &__box-title {
    text-align: center;
    font-size: $subsubtitle-font-size;
  }
}
