.guild-social-integration-tile {
  p {
    margin-top: 0;
  }

  &__social-icon {
    max-height: 24px;
    vertical-align: text-bottom;
  }

  &__twitter-icon {
    float: left;
    margin-right: 10px;
  }

  &__icon-label{
    box-sizing: border-box;
    height: 6rem;
    padding: 2rem 0;
    vertical-align: middle;
    input {
      margin-right: 0.5rem;
    }
  }

  &__save-button {
    margin-top: 1rem;
  }
}