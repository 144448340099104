.header-section-item {
  position: relative;
  background: $level-0-bg;
  border: 1px solid $level-0-border;
  padding: $gap-12 $gap-16;
  border-width: 1px 0 1px 0;
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  align-items: center;
  transition: background 0.14s ease-out;

  &:focus,
  &:hover {
    background: $level-1-bg-active;
    @include md {
      background: $level-0-bg-active;
    }
  }
  &:focus &,
  &:hover & {
    &__content-title,
    &__img {
      filter: brightness(1.3);
    }
  }

  &__li {
    display: block;

    &--with-desktop-link {
      cursor: pointer;
    }
  }

  &__link {
    display: block;
    transition: none;
  }

  &__background-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: none;

    @include md {
      display: initial;
      user-select: none;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    display: table;
    object-fit: fill;
    user-select: none;

    &-wrapper {
      width: 45px;
      height: 45px;
      border: 1px solid $level-2-border;
      box-sizing: border-box;
      border-radius: 23px;
      overflow: hidden;
      margin-right: $gap-16;
      flex-shrink: 0;
    }
  }

  &__icon {
    width: 100%;
    height: 100%;

    &-wrapper {
      width: 45px;
      height: 45px;
      margin-right: $gap-16;
      font-size: 2rem;

      i {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .expand-indicator__wrapper {
    display: none;
  }

  &--archon {
    .header-section-item__content {
      &-wrp {
        flex: 1;
        z-index: 1;
      }

      &-title,
      &-subtitle {
        width: 100%;
        text-align: right;
      }

      &-pseudo-link {
        text-align: right;
        width: 100%;
        justify-content: flex-end;
        font-weight: 800;
        display: flex;
        align-items: center;
        color: $link-blue;

        .external-link-icon {
          margin-left: 0.5rem;
        }
      }
    }
  }

  @include md() {
    &--with-bg-image {
      &::before {
        // TODO: finish when images are available
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background-image: linear-gradient(
            to right,
            rgba(0, 0, 0, 0.1) 33%,
            rgba(0, 0, 0, 1) 100%
          ),
          var(--bg-image);
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.25;
      }
    }
  }

  &__content {
    &-wrp {
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: flex-start;
      padding-top: $gap-4;
    }

    &-title {
      font-weight: 700;
      font-size: $subsubtitle-font-size;
      line-height: $subsubtitle-font-size;
      color: $white-800;
      text-shadow: 0px 4px 4px #000000;
      margin-bottom: $gap-4;
    }

    &-subtitle {
      color: $lighter-gray;
      text-align: right;
    }

    &-actions {
      display: flex;
      flex-direction: row;
      justify-items: flex-start;
      align-items: center;
      position: relative;
      z-index: 2;
    }
  }

  &__character {
    font-weight: 700;
    font-size: $smaller-font-size;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.55);
  }

  &__character-title {
    display: flex;
    align-items: center;

    > * {
      @include horizontal-margin-between($tiny-gap);
    }
  }

  &__character-title-server {
    font-weight: 100;
    color: $white-700;
    font-size: $smaller-font-size;
  }

  &__report {
    font-weight: 400;
    font-size: $smaller-font-size;
    color: $white-700;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.55);
  }

  &__mobile-actions {
    display: none;
  }

  @media (max-width: #{$screen-md-min}) {
    background: $level-1-bg;
    border: 1px solid $level-1-border;
    padding: $gap-4;
    border-radius: 28px;
    border-width: 1px;
    position: relative;
    z-index: 2;

    &--archon {
      padding: $gap-4 $gap-8;
    }

    .expand-indicator__wrapper {
      display: flex;
    }

    &--expandable {
      .header-section-item {
        &__content-actions {
          display: none;
        }

        &__content-wrp {
          flex-direction: row;
          justify-items: center;
          align-items: center;
          flex-grow: 1;
          padding-right: 20px;
        }
      }
    }

    &__content-title {
      flex-grow: 1;
      font-size: $body-font-size;
      letter-spacing: 0.065em;
      margin-bottom: 0;
    }

    &__mobile-actions {
      position: relative;
      z-index: 1;
      display: flex;
      flex-wrap: wrap;
      margin: 0 $gap-24;
      padding: $gap-16;
      background: $level-1-bg;
      border: 1px solid $level-1-border;
      border-radius: 0 0 8px 8px;

      .header-link-item {
        width: calc(50% - #{$gap-24 * 0.5});
        margin: $gap-8 0 0;
        color: $white-800 !important;

        &:nth-child(even) {
          margin-left: $gap-24;
        }

        &:nth-child(1),
        &:nth-child(2) {
          margin-top: 0;
        }
      }

      &-outer-wrapper {
        position: relative;
      }

      &-wrapper {
        max-height: 0px;
        overflow: hidden;
        margin-top: -2px;
        transition: max-height 0.16s ease-out;
      }
    }

    &--expanded {
      .header-section-item {
        background: $active-400 !important;

        &__mobile-actions-wrapper {
          max-height: 130px;
        }
      }
    }
  }

  &__outer-wrapper {
    @media (max-width: #{$screen-md-min}) {
      position: relative;

      &--with-tree {
        padding-left: 22px;
        padding-top: $gap-4;
        padding-bottom: $gap-4;

        .header-section-item {
          &::before {
            content: '';
            position: absolute;
            right: calc(100%);
            border-radius: 1px 0 0 1px;
            width: 22px;
            height: 2px;
            background: $gray;
            top: calc(50% - 1px);
          }

          &,
          &__mobile-actions-outer-wrapper {
            &::after {
              content: '';
              position: absolute;
              right: calc(100% + 20px);
              width: 2px;
              height: calc(100% + 8px);
              background: $gray;
              z-index: 1;
              top: -4px;
            }
          }

          &__mobile-actions-outer-wrapper {
            &::after {
              right: calc(100% + 19px);
            }
          }
        }
      }

      &--first {
        padding-bottom: $gap-4;
        padding-top: 0px;

        .header-section-item {
          &::after {
            border-radius: 1px 1px 0 0;
            top: 0;
            height: calc(100% + 4px);
          }
        }
      }

      &--last {
        padding-top: $gap-4;
        padding-bottom: 0px;

        .header-section-item {
          &::after {
            height: calc(50% + 3px);
          }

          &__mobile-actions-outer-wrapper {
            &::after {
              display: none;
            }
          }
        }
      }

      &--unique {
        .header-section-item {
          &::after {
            height: calc(50% + 1px);
          }
        }
      }
    }
  }
}

.header-section-item__outer-wrapper.header-section-item--expanded {
  .header-section-item {
    &::before {
      background: $active-300;
    }
  }
}
