.discord-integration-tile {
  align-items: center;

  &__discord-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      @include vertical-margin-between($small-gap);
    }
  }

  &__linked-discord-container {
    display: flex;
    align-items: center;
    background-color: #5762f8;
    border-radius: 25px;
    padding: $tiny-gap;
    font-size: $body-font-size;
    font-weight: bold;
    width: fit-content;

    > img {
      border-radius: 50px;
    }

    > * {
      @include horizontal-margin-between($small-gap);
    }
  }

  &__success-box {
    background-color: rgba(56, 226, 56, 0.2) !important;
    border: 1px solid $green !important;
    border-radius: 10px;
    padding: $small-gap;
  }

  &__disconnect-discord-button {
    color: #5762f8 !important;
    background-color: $white-800;
    border-radius: 50px;
    font-size: $smaller-font-size;
  }

  &__discord-button {
    background-color: #5762f8;
    display: inline-flex;
    align-items: center;

    > img {
      margin-right: $tiny-gap;

    }
  }


  &__integration-error {
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: center;

    & * {
      margin: 0;
    }
  }
}
