.article-preview-page {
  background-color: $cms-background-color;

  &__editor-and-preview {
    display: grid;
    grid-gap: $large-gap;
    justify-content: center;
  }

  &__editor, &__preview {
    height: calc(100vh - 285px);
    overflow-y: auto;
  }

  &__preview {
    section {
      display: flex;
      justify-content: center;

      > div {
        max-width: calc(min(90%, 800px));
      }
    }
  }
}
