.mra-entry-point-tile {
  &__content {
    display: grid;
    grid-template-columns: 100%;
    margin: $small-gap 0;

    @include md {
      margin: $xlarge-gap 15%;
    }

    @include lg {
      margin: $xlarge-gap 10%;
      grid-template-columns: auto auto;
      grid-gap: 9rem;
    }

    @media (min-width: 1700px) {
      margin: $xlarge-gap 15%;
    }
  }

  &__form {
    order: 2;

    @include lg {
      order: 1;
    }
  }

  &__form-input {
    display: flex;
    align-items: center;
  }

  &__zone-selector {
    margin-left: -7px;
  }

  &__selected-encounter-icon {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    border: 1px solid $light-gray;
    margin-right: $smaller-gap;
  }

  &__form-input-label {
    @include body-text;
    margin-right: $smaller-gap;
  }

  &__title {
    font-size: 2rem;
    font-weight: 900;
    text-transform: uppercase;
  }

  &__description {
    @include body-text;
  }

  &__disclaimer {
    color: $text-gray;
  }

  &__featured-image-container {
    order: 1;
    display: flex;
    justify-content: center;
    max-height: 400px;

    @include sm {
      max-height: 500px;
    }

    @include md {
      display: none;
    }

    @include xl {
      order: 2;
      display: flex;
      max-height: 600px;
      justify-content: flex-end;
    }
  }

  &__featured-image {
    max-height: 100%;
  }

  .react-tile__background-image {
    display: none;

    @include md {
      display: block;
    }
  }
}
