@use "sass:math";

.zone-progress-bar {
  position: relative;
  min-height: math.div(1rem * 17, 16);
  border: 1px solid $gray;

  > div {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  &__background {
    background-color: $black;
    z-index: 0;
  }

  &__fill {
    background: linear-gradient(to right, #69503f 0, #99755c 100%);
    z-index: 1;

    &--partially-full {
      background: linear-gradient(to right, #7b3f00 0, #c76700 100%);
    }

    &--mostly-full {
      background: linear-gradient(to right, #0d004a 0, #1b0196 100%);
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    font-weight: bold;
    margin-bottom: -1px;
    @include text-shadow;
  }
}