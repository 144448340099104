.race-is-near-tile {
  height: calc(100vh - 320px);
  min-height: 250px;

  &__content {
    display: flex;
    min-height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    font-family: $title-font-family;
    @include text-shadow-header();
  }

  &__race-title {
    font-size: 2rem;
    line-height: 2.25rem;
    font-weight: bold;
    filter: drop-shadow(0px 12px 6px $black);

    @include md {
      font-size: 5.25rem;
      line-height: 5.625rem;
    }
  }

  &__race-title-text {
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: bold;

    @include md {
      font-size: 4.5rem;
      line-height: 4.875rem;
    }
  }

  &__subtitle {
    font-size: 1.25rem;
    color: rgb(223, 205, 12);
    font-weight: bold;

    filter: drop-shadow(0px 4px 2px $black);

    @include md {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  &__subtitle-line {
    display: block;
  }
}
