.input-text {
  font-size: $body-font-size !important;
  height: 2.25rem;
  line-height: 2.2rem !important;
  padding: 0 0.66rem;
  background-color: $white !important;

  &--default {}

  &--full-width {
    width: 100%;
    box-sizing: border-box;
  }

  &:disabled {
    opacity: 0.75 !important;
    cursor: not-allowed;
  }

  &--style-dark {
    background: $level-1-bg !important;
    color: $faded-white-2 !important;
    border: 1px solid $level-1-border !important;
  }
}
