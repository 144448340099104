.builds-talent-tree-build-section {
  .talent-tree {
    width: 100%;
  }
  &__talent-trees {
    background: $level-0-bg;
    border: 1px solid $level-1-border;

    @include md {
      border-radius: $border-radius;
    }
  }
  &__popularity {
    justify-content: flex-end;
    align-items: center;
  }
  &__report_link {
    height: 20px;
  }
  &__talent-tree-alternatives {
    padding: $content-padding;
    &--selected {
      color: $light-gray;
    }
    table {
      tbody tr:hover {
        background: $level-1-bg;
      }
    }
  }

  &__heading-and-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $smaller-gap $content-padding;
    flex-wrap: wrap;

    h2 {
      margin-bottom: 0 !important;
    }
  }
  &__metrics-and-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $small-gap;

    @include md {
      display: flex;
    }
  }
}

.builds-talent-tree-build-section__changed-talents {
  --talent-tree-node-width: 1.6rem;
  display: flex;
  justify-content: flex-end;
}
