.latest-progress-updates-tile-embed-instructions-tile {
  &__content {
    display: grid;
    grid-column-gap: 0.75rem;
    grid-template-columns: 50% 50%;
  }

  &__preview {
    display: flex;
    justify-content: center;
  }

  .latest-progress-updates-tile-embed {
    width: 700px;
    height: 250px;
  }
}
