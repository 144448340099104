.recruitment-settings-validator {
  @include body-text;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-row-gap: $small-gap;
  grid-column-gap: $content-padding;
  align-items: center;

  &__icon {
    text-align: center;
    font-size: 200%;

    &--valid {
      color: $light-blue;
    }

    &--invalid {
      color: $red;
    }
  }
}
