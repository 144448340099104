.guild-profile-page {
  &__content {
    display: grid;
    grid-gap: $small-gap;
    padding-top: $small-gap;

    @include lg {
      grid-template-columns: minmax(450px, 2fr) minmax(450px, 1fr);
    }
  }

  &__column {
    display: flex;
    flex-direction: column;

    > * {
      @include vertical-margin-between($small-gap);
    }
  }
}