.recruitment-search-result {
  border: 1px solid $light-gray;
  background-color: $darker-blue;
  padding: $small-gap;
  display: grid;
  grid-gap: $tiny-gap;

  &__top {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: $small-gap;
  }

  &__logo {
    visibility: hidden;
    width: 0;

    @include lg {
      visibility: visible;
      width: unset;

      display: flex;
      align-items: center;
    }
  }

  &__title-and-tiles {
    display: grid;
    grid-template-rows: auto 1fr;
  }

  &__title {
    @include md {
      display: grid;
      grid-template-columns: 1fr auto;
    }
  }

  &__last-updated {
    @include md {
      margin-right: $small-gap;
    }
  }

  &__tiles {
    display: grid;
    grid-column-gap: $small-gap;
    grid-row-gap: $smaller-gap;
    padding: $tiny-gap;
  }

  &__bottom {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: $small-gap;
    align-items: flex-end;
    margin-bottom: $tiny-gap;
  }

  &__more-info {
    @include lg {
      display: grid;
      grid-gap: $tiny-gap;
    }
  }

  &__language-and-activities {
    display: none;

    @include lg {
      display: grid;
      grid-template-columns: auto auto 1fr;
      grid-gap: $large-gap;
      @include body-text;
    }
  }

  &__main-recruitment-info {
    display: grid;
    grid-gap: $tiny-gap;
  }

  &__availability {
    display: none;

    @include lg {
      display: grid;
      grid-template-columns: auto auto 1fr;
      grid-gap: $small-gap;
      @include body-text;
    }
  }

  &__contact-information {
    @include body-text;
  }

  &__view-guild {
    display: none;

    @include lg {
      display: grid;
      grid-template-rows: 1fr auto;
      height: 100%;
      text-align: center;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
