.header-menu-characters {
  &__action-panel {
    padding: $gap-16;
    background: $level-0-bg;
    border: 1px solid $level-0-border;
    border-left: 0px;
    border-right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-weight: 700;
    font-size: $smaller-body-font-size;
    letter-spacing: 0.015em;
    color: $white-800;
    text-align: center;
  }

  &__action-btn {
    font-size: $smaller-body-font-size;
    color: $link-blue !important;
    letter-spacing: 0px;
    background: transparent;
    font-weight: 700;
    text-align: center;
    padding: $gap-8 $gap-24;
    border: 1px solid $level-1-border;
    background: $level-1-bg;
    display: flex;
    height: 34px;
    align-items: center;
    border-radius: 20px;
    box-sizing: border-box;
    cursor: pointer;

    @include md {
      transition: filter 0.08s ease-out;
      padding: 0px;
      border: none;
      background: transparent;
      display: block;
      height: auto;
      align-items: initial;
      border-radius: 0;
      box-sizing: initial;
    }

    &:hover {
      filter: brightness(1.15);
    }
  }

  &__action-separator {
    margin: $gap-4 0px;
  }
}
