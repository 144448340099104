.progress-race-overall-page {
  &__a-closer-look {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $small-gap;
    justify-content: center;

    @media (max-width: 1100px) {
      grid-template-columns: 1fr;
    }
  }

  &__latest-updates-container {
    display: flex;
    flex-wrap: wrap;
    gap: $small-gap;
    @media (max-width: 1100px) {
      flex-direction: column;
    }
  }

  &__progress-updates-container {
    flex: 1;
    box-sizing: border-box;
  }

  &__time-machine-container {
    flex: 1;
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
    padding: $smaller-gap $tiny-gap;
    border: 1px solid $light-gray;
  }
}
