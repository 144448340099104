.tiny-article-preview {
  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  &__image {
    width: 82px;
    height: 46px;
    object-fit: cover;
    display: block;
    border: 1px solid $level-2-border;
    border-radius: 4px;
  }

  &__texts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;
    margin-left: $gap-8;
    padding-top: $gap-2;

    &-title {
      font-weight: 700;
      font-size: $smaller-font-size;
      color: $white-800;
      line-height: 15px;
      max-width: 250px;
      margin-bottom: -$gap-1;
      text-align: left;
    }

    &-date {
      font-weight: 400;
      font-size: $smaller-font-size;
      color: $white-700;
    }
  }
}
