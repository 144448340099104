@use "../TalentTreeNode/TalentTreeNode.scss" as talent;

.talent-tree-node-passive {
  background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='23' cy='23' r='22' fill='black' stroke='url(%23paint0_linear_92_115617)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_92_115617' x1='22' y1='0' x2='22' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FFFA00'/%3E%3Cstop offset='1' stop-color='%23FFC800'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  cursor: pointer; /* when no spellid is set, we convert to a span. keep the cursor, though */

  > img {
    border-radius: 50%;
  }

  &--common {
    background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='23' cy='23' r='22' fill='black' stroke='url(%23az3213)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='az3213' x1='22' y1='0' x2='22' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23#{talent.bake_common(#B2B2B2)}'/%3E%3Cstop offset='1' stop-color='%23#{talent.bake_common(#B2B2B2)}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }

  &--uncommon {
    background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='23' cy='23' r='22' fill='black' stroke='url(%23az3213)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='az3213' x1='22' y1='0' x2='22' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23#{talent.bake_uncommon(#3EC82C)}'/%3E%3Cstop offset='1' stop-color='%23#{talent.bake_uncommon(#3EC82C)}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }

  &--rare {
    background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='23' cy='23' r='22' fill='black' stroke='url(%23az3213)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='az3213' x1='22' y1='0' x2='22' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23#{talent.bake_rare(#0061DC)}'/%3E%3Cstop offset='1' stop-color='%23#{talent.bake_rare(#1C7FFF)}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }

  &--epic {
    background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='23' cy='23' r='22' fill='black' stroke='url(%23az3213)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='az3213' x1='22' y1='0' x2='22' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23#{talent.bake_epic(#9127D9)}'/%3E%3Cstop offset='1' stop-color='%23#{talent.bake_epic(#9B44D7)}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }

  &--legendary {
    background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='23' cy='23' r='22' fill='black' stroke='url(%23az3213)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='az3213' x1='22' y1='0' x2='22' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23#{talent.bake_legendary(#FF8000)}'/%3E%3Cstop offset='1' stop-color='%23#{talent.bake_legendary(#FF8000)}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }

  &--artifact {
    background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='23' cy='23' r='22' fill='black' stroke='url(%23az3213)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='az3213' x1='22' y1='0' x2='22' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23#{talent.bake_artifact(#E268A8)}'/%3E%3Cstop offset='1' stop-color='%23#{talent.bake_artifact(#E268A8)}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }
}
