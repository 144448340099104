.dark-gray-striped-table {
  font-size: $body-font-size;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border-bottom: 1px solid $gray;

  th {
    text-align: center;
    color: $off-white;
    background-color: $gray;
    font-family: $title-font-family;
    white-space: nowrap;
    font-weight: normal;
    padding: $small-gap * 0.5 $content-padding * 0.5;

    &:first-child {
      border-top-left-radius: $border-radius;
    }

    &:last-child {
      border-top-right-radius: $border-radius;
    }
  }

  tbody {
    tr:nth-child(2n) {
      background-color: $dark-gray;
    }
    tr:nth-child(2n+1) {
      background-color: $black;
    }
    tr {
      height: 3rem;
    }
  }

  td {
    white-space: nowrap;
    padding: $smaller-gap * 0.5 $content-padding * 0.5;
  }
}
