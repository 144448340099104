.any-clip {
  display: flex;
  justify-content: center;

  &--fixed {
    position: fixed;
    bottom: $small-gap;
    right: $small-gap;
    background-color: $black;
    z-index: 1;

    .ac-player-wrapper {
      background-color: $black !important;
    }
  }
}