@use "sass:math";

.builds-best-consumables-section__group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px 14px;
}

.builds-best-consumables-section__columns {
  display: grid;
  grid-template-columns: 1fr;
  gap: 4px 14px;

  @include lg {
    grid-template-columns: repeat(2, 1fr);
  }
}

.builds-best-consumables-section__column {
  & > .builds-best-consumables-section__header {
    display: none;
  }

  &:first-child  > .builds-best-consumables-section__header {
    display: flex;
  }

  @include lg {
    &  > .builds-best-consumables-section__header {
    display: flex;
    }
  }
}

.builds-best-consumables-section__header {
  margin: $smaller-gap 0 $tiny-gap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: $body-font-size;
  font-weight: 700;
  color: $white-800;
}

.builds-best-consumables-section__header-column {
  box-sizing: border-box;
  flex: 1 1 auto;

  // the even repeating dom elements must be aligned right
  &:nth-child(even) {
    text-align: right;
  }

  @include lg {
    flex: 1 1 calc(50% - $tiny-gap);
    max-width: calc(50% - $tiny-gap);
  }
}

.builds-best-consumables-section__item {
  box-sizing: border-box;
  margin: $tiny-gap 0;
  width: 100%;

  @include lg {
    margin: 0;
  }

  &--left {
    grid-column: 'left';
  }

  &--right {
    grid-column: 'right';
  }
}

.best-consumable-item {
  border: 1px solid $slightly-dark-gray;
  border-radius: 0.25rem;
  background: $level-0-bg;
  display: grid;
  grid-template-columns: calc(100% - 100px) 100px;
  grid-template-areas: "item popularity";
  align-items: center;

  &--right {
    @include lg {
      grid-template-areas: "popularity item";
      grid-template-columns: 100px calc(100% - 100px);
      text-align: right;
    }
  }

  &__name {
    grid-area: item;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: $subtitle-font-size;
    font-weight: 600;
  }

  &__popularity {
    grid-area: popularity;
    text-align: right;
    font-size: $body-font-size;
    font-weight: 700;
    padding: 0 $smaller-gap;
  }

  &--right & {
    &__popularity {
      @include sm {
        text-align: left;
      }
    }
  }
}
