.textarea {
  @include body-text(true);
  display: flex;
  flex-direction: column;

  > * {
    @include vertical-margin-between($tiny-gap);
  }

  &--full-height {
    height: 100%;
  }

  &__input {
    width: 100%;
    min-height: 25rem;
    background-color: $white !important;

    &--full-height {
      height: 100%;
    }

    &--not-resizeable {
      resize: none;
    }
  }

  &__characters-remaining {
    text-align: right;
    margin-right: $small-gap;
  }
}
