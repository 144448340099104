.guild-report-visibility-tile{
  &__content {
    display: grid;
    grid-gap: $large-gap;
    padding-top: $small-gap;

    @include lg {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__column {
    display: flex;
    flex: 1;
    flex-direction: column;
    > * {
      @include vertical-margin-between($large-gap);
    }

    h3 {
      display: inline;
    }

    .guild-report-visibility-tile__toggle {
      float: right;
    }
  }
}