.a52596fdd-nitro {
  width: 100%;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  justify-content: center;

  // quirk: widths and heights have to be set because it can't determine its own size
  &--cornerVideo {
    box-sizing: border-box;
    border-radius: $border-radius;
    aspect-ratio: 16 / 9;
    width: 100%;
    height: 100%;
    #cornerVideo {
      width: min(60vw, 400px);
      height: 100%;
    }

    // quirk: animated height transition simulates a slide-up effect
    .AV66868d7d01e9d63094010a1a {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}
