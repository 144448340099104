.zone-progress-filter {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: $small-gap;

  @include body-text;

  @include md {
    grid-template-columns: 1fr 1fr;

    &__text-field {
      width: calc(100% - 1.22rem);
    }
  }

  &__field {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    column-gap: $small-gap;
    row-gap: $small-gap;

    @include sm {
      grid-template-columns: 0.6fr 1.7fr 1.7fr;
    }

    @include md {
      grid-template-columns: 0.6fr 0.7fr 0.7fr;
      grid-template-areas: 'label field field';

      &--full-width {
        grid-area: field;
      }
    }

  }

  &__field-label {
    @include sm {
      text-align: right;
    }
  }

  &__summary {
    @include sm {
      margin-left: $large-gap;
    }
  }

  &__filter-name {
    font-weight: bold;
    margin: $small-gap 0;

    @include body-text;

    @include sm {
      margin-left: $large-gap;
    }
  }


  &__any-progress-link {
    margin: $small-gap 0;
    text-align: right;
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
