.editable-input-text {
  &__display-value {
    @include body-text;
  }

  &__characters-remaining {
    @include body-text;
    text-align: right;
    margin-right: $small-gap;
  }
}
