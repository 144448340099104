.search-select {
  display: flex;
  position: relative;

  .menu-select {
    flex: 1;
  }

  .menu-select__control, .menu-select__control:hover {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border: 1px solid $gray !important;
    border-right: 0 !important;
  }

  &__button {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border: 0 !important;
  }
}
