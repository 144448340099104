.header-language-picker {
  font-size: $smaller-font-size;
  color: $white-700;
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-right: -$gap-12;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.85);

  &__wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: $gap-4;
    transition: all 0.14s ease-out;
    user-select: none;
    height: 100%;
    padding: $gap-4 $gap-12;
    background: transparent;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid transparent;
    color: $white-800;

    &:hover {
      background: $active-300;
      border: 1px solid $white-600;
    }

    &--opened {
      background: $active-300;
      border: 1px solid $white-600;
      color: $white-800;
    }
  }

  &__language-title {
    margin: 0 $gap-4;
    display: none;

    @include md {
      display: initial;
    }
  }

  &__caret {
    font-size: 24px !important;
    margin-left: $gap-4;

    @include md {
      margin-left: 0px;
    }
  }

  &__dropdown {
    position: absolute;
    top: calc(100% + #{$gap-4});
    right: 0;
    background: $level-2-bg;
    border: $level-2-border 1px solid;
    border-radius: $medium-border-radius;
    list-style: none;
    padding: $gap-4 $gap-4;
    margin: 0;
    opacity: 0;
    pointer-events: none;
    transform: translateY(15px);
    transition: opacity 0.08s ease-out, transform ease-out 0.14s;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.95);

    li {
      width: 100%;
      display: block;
      box-sizing: border-box;
      background: transparent;
      transition: background 0.14s ease-out;
      border-radius: 9px;

      &:hover {
        background: $level-1-bg;

        &__item {
          color: $link-blue !important;
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }

    &--opened {
      opacity: 1;
      pointer-events: all;
      transform: translateY(0px);
    }

    &-item {
      display: block;
      font-size: $body-font-size;
      letter-spacing: 0.025em;
      color: $white-800 !important;
      transition: color 0.24s ease-out;
      white-space: nowrap;
      padding: $gap-4;
      padding-left: $gap-16;
      padding-right: $gap-32;
    }
  }
}
