.header-link-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 700;
  font-size: $small-font-size;
  color: $white-700 !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  transition: color 0.14s ease-out;
  padding: $gap-4;
  margin: 0 - $gap-4;
  white-space: nowrap;
  user-select: none;

  &:hover {
    color: $link-blue !important;
  }

  &__icon {
    font-size: 12px;
    margin-right: $gap-4;
  }

  & + * {
    margin-left: 6px;
  }

  &--mobile {
    display: inline-flex;
    padding: $gap-4 $gap-12;
    border: 1px solid $level-2-border;
    background: $level-2-bg;
    border-radius: 20px;
    font-size: $smaller-font-size;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    transition: background 0.08s ease-out;

    &:hover {
      background: $active-400;
    }

    .header-link-item {
      &__icon {
        font-size: 15px;
        margin-right: $gap-8;
      }
    }
  }

  &--mobile-column {
    flex-direction: column;
    align-items: center;
    font-size: $smaller-font-size;
    padding: $gap-4;
    text-align: center;
    line-height: $smaller-font-size;
    color: $white-700 !important;
    flex-grow: 1;

    .header-link-item {
      &__icon {
        color: $white-700 !important;
        font-size: 20px;
        margin: 0 0 $gap-4;
      }
    }
  }
}
