.character-profile-customize-tile {
  &--no-banner-selected {
    @include md {
      margin-top: 51px; // Prevent content shifting when selecting a banner
    }
  }

  &__banner-grid {
    display: grid;
    grid-gap: $content-padding;
    max-height: calc(100vh - 330px);
    min-height: 400px;
    overflow-y: auto;
    padding: $small-gap;

    @include md {
      grid-template-columns: 1fr 1fr;
    }
  }

  .react-tile__content {
    overflow-y: hidden;
  }
}
