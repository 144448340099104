.content-and-any-clip {
  &--with-ad {
    display: grid;
    grid-template-columns: auto 400px;
    grid-gap: $small-gap;
    align-items: center;

    @media(max-width: 900px) {
      grid-template-columns: auto;

      .any-clip {
        display: none;
      }
    }
  }
}
