.stream {
  border: 1px solid $light-gray;

  &__fallback-message {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, lighten($black, 15%), $black);
    font-family: $title-font-family;
    font-size: $small-title-font-size;
    text-align: center;
  }

  &--no-border {
    border: 0;
  }
}
