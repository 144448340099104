.progress-race-summary-tile {
  position: relative;

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;

    @include md {
      height: 500px;
    }
  }

  &__content {
    display: grid;
    grid-gap: $small-gap;
  }

  &__stream-and-progress-line {
    display: grid;
    grid-gap: $smaller-gap;
    align-items: center;

    @include md {
      grid-template-columns: 1fr 1fr;

      &--single-column {
        grid-template-columns: 1fr;
      }
    }
  }

  &__time-machine-container {
    background-size: cover;
    background-position: center;
    padding: $smaller-gap $tiny-gap;
    border: 1px solid $light-gray;
  }

  &__progress-line-and-tiles {
    display: flex;
    flex-direction: column;
    grid-gap: $tiny-gap;
    height: 100%;

    &--no-stream {
      grid-column: span 2;
    }
  }

  &__progress-line {
    flex: 1;
    display: grid;
  }

  &__progress-tiles {
    display: none;

    @include lg {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: $tiny-gap;

      &--no-stream {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }

  &__text {
    color: $link-blue;
    font-size: $body-font-size;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include md {
      font-size: $subtitle-font-size;
    }
  }
}
