.navigation-stacked {
  ul {
    list-style: none;
    margin-bottom: 10px;
    margin-block-start: 0;
    margin-block-end: 1rem;
    padding: 0;
    border: 1px solid $light-gray;
    border-bottom: 0;

    li {
      border-bottom: 1px solid $light-gray;
      background: $dark-gray-gradient;

      a {
        display: block;
        color: $lighter-gray;
        padding: .5rem;
      }

      &:hover, &.active {
        background: $dark-blue;
        a {
          color: $link-blue;
        }
      }
    }
  }



  &--danger {
    ul{
      li {
        a {
          color: $red;
        }

        &:hover {
          background: darken($red, 40%);

          a {
            color: lighten($red, 20%);
          }
        }
      }
    }
  }
}