@use "../../../sass/variables";

.builds-metadata-summary {
  &__content {
    display: flex;
    font-size: 15px;
    color: variables.$white-700;

    flex-direction: column;
    gap: variables.$tiny-gap;

    @include sm {
      flex-direction: row;
      gap: variables.$content-padding;
    }
  }

  &__data {
    font-weight: bold;
    color: variables.$white-800;

    &--warning {
      color: variables.$highlight;
    }
  }
}
