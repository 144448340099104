@use "../../../sass/breakpoints";
@use "../../../sass/variables";

.breadcrumbs {
  display: flex;
  list-style: none;
  flex-flow: wrap;
  margin-block-start: unset;
  margin-block-end: unset;
  padding-inline-start: unset;

  &__link,
  &__label {
    @include body-text;
    padding-right: $tiny-gap;
  }

  &__link {
    font-weight: bold;
  }

  &__chevron {
    width: 0.75rem;
    height: 0.75rem;
    margin-right: $tiny-gap;
  }
}
