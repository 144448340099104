// FIXME: Remove all legacy font size styles when converting user profile to React

.user-subscription-status-tile {
  .subscription-tier {
    &__feature-list, &__price, &__is-current-badge {
      font-size: 13px !important;
      line-height: 1.4 !important;
    }

    &__header {
      font-size: $subsubtitle-font-size;
    }
  };

  .subscription-tiers-call-to-action {
    &__subscribe-text {
      font-size: 13px !important;
      line-height: 1.4 !important;
    }
  }

  h2 {
    margin: 0 auto;
    font-weight: bold !important;
    font-size: $subtitle-font-size !important;
  }

  &__centered-element {
    text-align: center;
    margin: 0 auto;
  }

  &__footer-help {
    max-width: 800px;
    text-align: center;
    margin: 0 auto;
    font-size: 13px !important;

    * div {
      line-height: 125% !important;
      font-size: 13px;
    }
  }

  &__subscription-error {
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: center;

    & * {
      margin: 0;
    }
  }

  &__texts {
    text-align: center;
    font-size: 13px;
    margin-top: 0px !important;
  }
}
