.icon {
  --size: 20;
  --label-margin-left: calc(var(--size) * 1.15px + 2px);

  position: relative;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  &__image {
    position: absolute;
    width: calc(var(--size) * 1px);
    height: calc(var(--size) * 1px);

    &--rounded {
      border-radius: 100% !important;
    }

    &--sprite {
      object-fit: cover;
    }

    margin-bottom: calc(var(--size) * 0.5px);
    &--with-label {
      margin-bottom: 0;
    }
  }

  --border-color: #333;
  &--style-v2 {
    --border-color: #4b4b4b;
  }

  &--bordered & {
    &__image {
      border: 1px solid var(--border-color);
      border-radius: 1px;
    }
  }

  &__label {
    margin-left: var(--label-margin-left);

    text-overflow: ellipsis;
    overflow: hidden;

    &--large {
      font-size: pxToRem(18);
      line-height: pxToRem(35);
      letter-spacing: -1px;

      @include sm {
        font-size: pxToRem(19);
        line-height: pxToRem(40);
      }
    }
  }

  &--bordered & {
    &__label {
      margin-left: calc(var(--label-margin-left) + 2px);
    }
  }

  &--rtl {
    @include lg() {
      flex-direction: row-reverse;
    }
  }

  &--rtl & {
    &__label {
      @include lg() {
        margin-left: unset;
        margin-right: calc(var(--label-margin-left) + 2px);
      }
    }
  }

  &--childless {
    vertical-align: middle;

    width: calc(var(--size) * 1px);
    height: calc(var(--size) * 1px);
  }

  &--childless & {
    &__image {
      position: initial;
      margin-bottom: unset;
    }
  }

  &--force-height & {
    &__image {
      margin-bottom: 0;
    }

    &__label {
      height: calc(var(--size) * 1px);
    }
  }

  &--bordered,
  &--force-height & {
    &__label {
      height: calc(var(--size) * 1px + 2px);
    }
  }

  &__label {
    &--no-image {
      margin-left: 0 !important;
    }
  }
}
