@import "../variables";

@mixin new-badge($top: 50%, $left: 100%) {
  position: absolute;
  top: $top;
  left: $left;
  font-size: $smaller-font-size !important;
  font-weight: 900;
  color: white;
  padding: $gap-2 $gap-8;
  background: linear-gradient(97.85deg, #790EBB 0%, #AC51E3 100%);
  box-shadow: 0px 3px 19px #7E15BF;
  border-radius: $gap-4;
  transform: translate(-14px, calc(-50% - 7px));
  text-transform: uppercase;
  text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.35);
  z-index: 1;
}
