.guild-progress-race-detailed-composition-embed-instructions-tile {
  &__content {
    display: grid;
    grid-gap: $small-gap;

    @include md {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__options {
    display: grid;
    grid-gap: $small-gap;
    grid-template-columns: 1fr;
  }

  &__preview-container {
    grid-column: 1 / span 2;
  }

  &__preview-wrapper {
    display: grid;
  }

  &__preview {
    min-height: 500px;

    * {
      user-select: none;
    }
  }
}
