.warning-paragraph {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 0.25em 1fr;
  gap: $content-padding;

  &__inset {
    border-radius: 0.5rem;
  }

  &--orange & {
    &__inset {
      background: #DC7C46;
      box-shadow: 0 0 10px 0 rgba(220, 124, 70, 0.61);
    }
  }

  &--red & {
    &__inset {
      background: #e91e1e;
      box-shadow: 0 0 10px 0 rgba(217, 37, 37, 0.61);
    }
  }
}
