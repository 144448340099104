.builds-partnered-guide-section {
  &__banner {
    position: relative;

    width: 100%;
    height: 90px;
    border: 1px solid $level-0-border;
    background: $level-0-bg;

    font-size: 1.25rem;
    font-weight: 700;
    text-align: center;
    text-wrap: balance;
    @include text-shadow;

    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 1px;

    display: flex;
    align-items: center;
    justify-content: center;

    @include sm {
      font-size: 1.75rem;
    }
  }

  &__banner-bg-1, &__banner-bg-2 {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center;
    background-size: cover;
  }

  &__banner-bg-1 {
    mask-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    right: 70%;
    background-position-x: -60px;
    background-position-y: 36%;

    @include sm {
      background-position-x: -100px;
    }
  }

  &__banner-bg-2 {
    transform: scaleX(-1);
    mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 1));
    filter: blur(4px);
    left: 10%;
  }

  &__banner-content {
    z-index: 1;
  }
}
