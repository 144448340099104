.guild-settings__rank-cutoffs{
  display: grid;
  grid-gap: $small-gap;

  @include sm {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__column {
    display: flex;
    flex-direction: column;
  }
}