.date-picker {
  &--link {
    position: relative;
    margin: 0 7px;
    display: table;

    &::before {
      position: absolute;
      pointer-events: none;
      width: 100%;
      height: 1px;
      content: '';
      background: $link-blue;
      left: 0;
      bottom: 50%;
      transform: translateY(calc(#{$body-font-size * 0.5} + 1.5px));
      transition: background 0.14s ease-out;
    }

    &:hover::before {
      background: $text-white;
    }

    input {
      -moz-appearance: initial;
      position: relative;
      background-color: transparent !important;
      color: $link-blue !important;
      font-weight: 700 !important;
      font-size: $body-font-size !important;
      padding: 0;
      border-radius: 0 !important;
      border: none !important;
      outline: none;
      display: table;
      width: 100%;
      transition: color 0.14s ease-out;

      &:hover {
        color: $text-white;
      }

      &::-webkit-datetime-edit-fields-wrapper {
        box-sizing: border-box;
      }

      &::-webkit-calendar-picker-indicator {
        -webkit-appearance: none;
        opacity: 1;
        color: rgba(0, 0, 0, 0);
        background: transparent;
        background: url("data:image/svg+xml,%3Csvg fill='none' height='8' viewBox='0 0 13 8' width='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m13 .484375h-12.5l6.25 7.031245z' fill='%2346c5ff'/%3E%3C/svg%3E") no-repeat;
        background-size: contain;
        height: 5px;
        width: 10px;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);

        &:hover {
          background: url("data:image/svg+xml,%3Csvg fill='none' height='8' viewBox='0 0 13 8' width='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m13 .484375h-12.5l6.25 7.031245z' fill='%23e1f2f5'/%3E%3C/svg%3E") no-repeat;
        }
      }

      &::-webkit-datetime-edit-day-field,
      &::-webkit-datetime-edit-month-field,
      &::-webkit-datetime-edit-year-field {
        transition: color 0.14s ease-out;

        &:hover {
          color: $text-white;
        }
      }

      &:disabled {
        background-color: unset !important;
      }
    }

    &.date-picker--chrome.date-picker--android input {
      appearance: none;
      margin: 0 !important;
      display: flex;

      &::-webkit-date-and-time-value {
        margin-right: 0.5rem;
      }

      &::after {
        content: '';
        height: 0;
        width: 0;
        border-left: pxToRem(7) solid transparent;
        border-right: pxToRem(7) solid transparent;
        border-top: pxToRem(7) solid $link-blue;
      }
    }

    &:has(& .date-picker.date-picker--link::-webkit-calendar-picker-indicator) {
      &::before {
        width: calc(100% - 28px);
      }
    }

    & input::-webkit-calendar-picker-indicator {
      margin-left: -20px;
    }
  }

  &.date-picker--chrome.date-picker--android {
    &::before {
      width: 100%;
      transform: translateY(calc(#{$body-font-size * 0.5} + 3.5px));
    }

    input {
      margin-bottom: -15px;
      padding-right: 0 !important;
    }
  }

  &.date-picker--chrome.date-picker--with-min-max {
    input {
      padding: 0 9.5px 0 0;
    }
  }
}
