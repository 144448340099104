.race-in-review-embed-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-size: 2em;
    font-family: $title-font-family;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__subtitle {
    font-size: 1.5em;
    font-family: $title-font-family;
    text-transform: uppercase;
  }

  &__strip-left, &__strip-middle {
    height: 1.5em;
    width: 2em;
  }
  &__strip-middle {
    flex-grow: 1;
  }

  &__race-in-review {
    height: 4em;
  }
}
