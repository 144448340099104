.article-preview {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @include lg {
    display: grid;
    grid-template-columns: 412px 1fr;
    grid-gap: 34px;
  }

  &--small {
    display: flex;
    flex-direction: column;
    grid-gap: 0;
    width: 100%;
  }

  &--prominent {
    grid-template-columns: 1fr 1fr;
  }


  &:hover {
    cursor: pointer;
    filter: brightness(1.15);

    .article-preview__category-name {
      filter: brightness(calc(1 / 1.15));
    }
  }

  &__status {
    background: $red;
    background: linear-gradient(90deg, $red 0%, transparentize($red, 0.1) 75%, transparentize($red, 0.2) 80%, transparentize($red, 1) 100%);
    box-sizing: border-box;
    font-weight: bold;
    left: 0;
    padding: $tiny-gap $content-padding $tiny-gap;
    position: absolute;
    text-transform: uppercase;
    top: 14px;
    font-size: $subsubtitle-font-size;
    color: $text-white;
  }

  &__details {
    margin-top: 8px;
    box-sizing: border-box;
    width: 100%;

    @include md {
      padding-top: 10px;
    }
  }

  &__image {
    aspect-ratio: 2/1;
    border: 2px solid $slightly-dark-gray;
    border-radius: 8px;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.8);
    box-sizing: border-box;
    max-width: 560px;
    overflow: clip;
    position: relative;
    width: 100%;

    .optimized-image {
      height: 100%;
    }

    img {
      aspect-ratio: 2/1;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    &--small {
      @include lg {
        img {
          aspect-ratio: 3/2;
        }
      }
    }
  }

  &__pre-title {
    margin-top: 2px;
    font-size: $subsubtitle-font-size;
    line-height: 1em;
    letter-spacing: -0.01em;
    color: $faded-white-1;
  }

  &__category-name {
    color: $link-blue;
    font-weight: bold;

    &:hover {
      filter: brightness(1.1) !important;
    }
  }

  &__title {
    margin-top: 6px;
    font-size: $small-title-font-size;
    line-height: 1.125em;
    letter-spacing: 0.005em;
    word-break: normal;
    overflow-wrap: anywhere;
    color: $faded-white-1;

    @include md {
      font-size: 32px;
      line-height: 36px;
    }

    @include lg {
      font-size: 40px;
      line-height: 45px;
    }
  }

  &__description {
    margin-top: 6px;
    @include body-text;
    color: $lighter-gray;
  }
}
