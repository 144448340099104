.zone-speed-clear-rank-filter-slider {
  grid-column-start: 1;
  grid-column-end: 3;

  &__summary {
    @include sm {
      margin-left: $xlarge-gap;
      margin-top: $small-gap;
    }
  }

  &__encounter-label {
    display: block;
    text-align: center;
    width: 100%;
    margin: $small-gap;
  }
}
