.schedule-boxes {
  display: flex;

  &__box {
    background-color: $off-white !important;
    border-radius: 2px;
    border: 1px solid $gray !important;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $black;
    font-weight: bold;
    user-select: none;

    &:not(:first-child) {
      margin-left: $smaller-gap;
    }

    &--available {
      background-color: $blue !important;
      color: $off-white;
    }
  }
}
