a {
  &.unstyled-link {
    color: inherit !important;
    text-decoration: inherit !important;
    font-weight: inherit !important;
    outline: none;

    &:link, &:visited, &:hover, &:active {
      color: inherit !important;
      text-decoration: inherit !important;
      font-weight: inherit !important;
      outline: none;
    }
  }
}
