.builds-metric-tiles {
  display: flex;
  gap: $content-padding;

  &__tile {
    background: $level-1-bg;
    border-radius: $border-radius;
    border: 1px solid $level-1-border;
    overflow: hidden;
    padding: 0.5rem 1.5rem;
    position: relative;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.80);
  }

  &__tile-background-icon {
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0.25rem;
    left: -20px;
    opacity: 0.15;
    position: absolute;
    right: 0;
    top: 0.25rem;
  }

  &__tile-lines {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: $tiny-gap;
    justify-content: space-around;
    text-align: center;
    white-space: nowrap;

    &--bold {
      font-weight: bold;
    }

    &--large {
      font-weight: bold;
      font-size: 1.75rem;
    }
  }
}
