.site-title {
  font-family: $title-font-family;
  text-transform: uppercase;
  font-weight: bold;
  white-space: nowrap;

  &__text-one, &__text-two {
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
  }
}
