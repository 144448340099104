@use "../../../sass/variables";

.builds-no-data-placeholder {
  border: 1px solid variables.$level-0-border;
  border-radius: 0.25rem;
  background: variables.$level-0-bg;
  padding: $large-gap;

  display: flex;
  flex-direction: column;
  align-items: center;
}
