.wipefest-cta {
  &__wrapper {
    position: relative;
    padding: $gap-16 $gap-32;
    border: 1px solid $light-gray;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: $gap-24;

    @include xl {
      padding: $gap-32 72px;
    }
  }

  &__background {
    background-size: cover;
    background-position: center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-width: 940px;
    z-index: 1;
    mask-size: cover;
    -webkit-mask-size: cover;
    mask-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='url(%23linearGradient3232)'/%3E%3Cdefs%3E%3ClinearGradient id='linearGradient3232' x1='0' y1='5' x2='10' y2='5' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-opacity='0.31'/%3E%3Cstop offset='1' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='url(%23linearGradient3232)'/%3E%3Cdefs%3E%3ClinearGradient id='linearGradient3232' x1='0' y1='5' x2='10' y2='5' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-opacity='0.31'/%3E%3Cstop offset='1' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    opacity: 0.4;

    @include md {
      opacity: 1.0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $gap-16;
    max-width: 730px;
    position: relative;
    z-index: 3;
    text-shadow: 0px 2px 2px black;

    @include lg {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &__coming-soon {
    font-weight: 900;
    font-size: $page-title-font-size;
    color: $white-800;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $gap-12;
    font-weight: 900;
    color: $white-800;
    font-size: $small-title-font-size;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    & > img {
      width: 38px;
      height: auto !important;
      display: block;
    }
  }

  &__subtitle {
    font-weight: 700;
    color: $white-800;
    font-size: $subtitle-font-size;
  }

  &__text {
    font-weight: 400;
    color: $white-800;
    font-size: $subsubtitle-font-size;
  }

  &__button {
    margin-top: 16px;
  }

  &__ad-image {
    width: 100%;
    height: auto;
    max-width: 380px;
    display: block;
    position: relative;
    z-index: 2;
    margin: 0 auto;

    @include lg {
      position: absolute;
      right: 72px;
      height: 140%;
      width: auto;
      top: -12%;
    }
  }

  &__report-private {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: $gap-8;
    gap: $gap-12;
    padding-left: 0;

    @include xl {
      padding-left: 145px;
    }
  }


  &__report-private-asset {
    display: none;

    @include xl {
      display: block;
      position: absolute;
      top: -$gap-24;
      left: -$gap-16;
      height: calc(100% + #{$gap-16} + #{$gap-24});
      width: 165px;
      object-position: left;
      object-fit: contain;
      left: -$gap-16;
      height: calc(100% + #{$gap-32} + #{$gap-24});
      width: 165px;
    }
  }

  &__report-private-title {
    font-weight: 700;
    color: $highlight;
    font-size: $subtitle-font-size;
  }

  &__report-private-text {
    font-weight: 400;
    color: $highlight;
    font-size: $body-font-size;
  }
}
