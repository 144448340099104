.enhanced-markdown-collapse {
  &__header {
    display: flex;
    align-items: center;
  }

  &__header-text {
    cursor: pointer;
    user-select: none;
  }

  &__button {
    background-color: unset;
    border: none;
    font-size: 1.5em;
    color: unset;
    font-weight: bold;
    margin-right: 0.4em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
    padding: 0;
    cursor: pointer;
    margin-bottom: 0.2em;

    &--down {
      transform: rotate(90deg) translate(0.09em, -0.125em);
    }
  }
}
