.detailed-composition {
  width: 100%;
  overflow-x: auto;

  table {
    width: calc(100% - 2px);
    border-spacing: 0;
    border-collapse: collapse;

    --talent-tree-node-width: 1.5rem;
  }

  th {
    text-align: center;
    color: $off-white;
    background-color: $gray;
    font-family: $title-font-family;
    white-space: nowrap;
    font-weight: normal;
    padding: $small-gap * 0.5 $content-padding * 0.5;
  }

  tbody {
    tr:nth-child(4n),
    tr:nth-child(4n + 3) {
      background-color: $dark-gray !important;

      .talent-tree-inline__button {
        background: linear-gradient(
          var(--gradient-rotation),
          rgb(20 20 20 / 85%) 0%,
          rgb(20 20 20 / 75%) 60%,
          rgba(20, 20, 20, 0) 100%
        );
      }
    }

    tr:nth-child(4n + 1),
    tr:nth-child(4n + 2) {
      background-color: $black !important;
    }
  }

  &__set-gear-threshold-header-amount {
    font-family: $body-font-family;
  }

  &__name,
  &__spec {
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  &__name {
    max-width: 16ch;
  }

  &__spec {
    max-width: 5rem;
    font-size: smaller;
    color: $text-dark-gray;
    text-align: center;
  }

  &__talent-tree {
    padding-top: unset !important;
    padding-bottom: unset !important;

    width: 8rem;
    max-width: 8rem;

    @include lg() {
      width: 14rem;
      max-width: 14rem;
    }

    @include xl() {
      width: 20rem;
      max-width: 20rem;
    }

    @media (min-width: 1680px) {
      width: 30rem;
      max-width: 30rem;
    }

    &--wide {
      @include lg() {
        width: 20rem;
        max-width: 20rem;
      }

      @include xl() {
        width: 30rem;
        max-width: 30rem;
      }

      @media (min-width: 1680px) {
        width: 36rem;
        max-width: 36rem;
      }
    }
  }

  &__set-gear-threshold {
    text-align: center;
  }

  &__set-gear-threshold-tick {
    color: $default-green;
    border-color: $default-green !important;
    font-weight: bold !important;
    border-width: 2px !important;
    font-size: pxToRem(14) !important;
  }

  .horizontal-content {
    justify-content: center;
  }

  .icon {
    margin-top: 7px;
  }

  &__custom-powers-header,
  &__custom-powers,
  &__secondary-custom-powers-header,
  &__secondary-custom-powers {
    @media (max-width: 1400px) {
      display: none;
    }
  }

  &__spec-text,
  &__set-gear-threshold-header,
  &__set-gear-threshold {
    @media (max-width: 1100px) {
      display: none;
    }
  }

  &__talents-header,
  &__talents {
    @media (max-width: 900px) {
      display: none;
    }
  }

  &__gear-overview {
    @media (min-width: 750px) {
      display: none;
    }
  }

  &__set-gear-header,
  &__set-gear,
  &__gear-header,
  &__gear,
  &__effect-gear-header,
  &__effect-gear,
  &__spec,
  &__fill-cell,
  &__talent-tree-header span,
  &__talent-tree > div {
    @media (max-width: 750px) {
      display: none;
    }
  }

  &__spacer-row {
    border-top: 1px solid $gray;
    background-color: transparent !important;
    height: $small-gap !important;
  }

  &__talent-tree-full {
    background-color: black;
    
    .talent-tree {
      width: 100%;
    }
  }

  &--embedded {
    .detailed-composition__custom-powers-header,
    .detailed-composition__custom-powers,
    .detailed-composition__secondary-custom-powers-header,
    .detailed-composition__secondary-custom-powers,
    .detailed-composition__spec-text,
    .detailed-composition__set-gear-threshold-header,
    .detailed-composition__set-gear-threshold,
    .detailed-composition__talents-header,
    .detailed-composition__talents,
    .detailed-composition__set-gear-header,
    .detailed-composition__set-gear,
    .detailed-composition__gear-header,
    .detailed-composition__gear,
    .detailed-composition__effect-gear-header,
    .detailed-composition__effect-gear {
      display: table-cell !important;
    }

    .detailed-composition__spec-text {
      display: inline !important;
    }

    .detailed-composition__gear-overview {
      display: none !important;
    }
  }
}
