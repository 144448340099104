.talent-tree-percentage_badge {
  font-weight: 700;

  font-size: $small-font-size;

  @include lg() {
    font-size: $smaller-font-size;
  }

  .talent-tree--compact & {
    font-size: $small-font-size;
  }

  letter-spacing: -0.02em;
  color: $white-800;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border: 1px solid $light-gray;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.8);
  border-radius: 100px;
  position: absolute;
  bottom: -25%;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 0.5px 6px 0px;
  background: $dark-gray;
  z-index: 2;
  line-height: 135%;

  &--uncommon {
    color: $green;
  }

  &--rare,
  &--epic,
  &--legendary,
  &--artifact {
    background: linear-gradient(180deg, #286cc3 0%, #1f78eb 100%);
    color: $white-800;
  }

  &--epic {
    background: linear-gradient(180deg, #9127d9 0%, #9b44d7 100%) !important;
  }

  &--legendary {
    background: rgb(211 113 14) !important;
  }

  &--artifact {
    background: #c1508b !important;
  }

  &--large-text {
    font-size: $subsubtitle-font-size !important;
  }

  &__value {
    &--1-decimal {
      display: none;

      @include md {
        display: inline;
      }
    }

    &--0-decimals {
      display: inline;

      @include md {
        display: none;
      }
    }
  }
}
