.enhanced-markdown-divider {
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, rgba($light-blue, 0), rgba($light-blue, 255) 50%, rgba($light-blue, 0));
  border-radius: 100%;
  margin-block: $large-gap;

  &--purple {
    background: linear-gradient(to right, rgba($epic, 0), rgba($epic, 255) 50%, rgba($epic, 0));
  }
}
