.youtube-embed {
  position: relative;
  padding-bottom: 56.25%; // maintains aspect ratio
  overflow: hidden;

  iframe {
    overflow: hidden;
    border: 0;
    align-self: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
