.welcome-tile {
  &__articles-container {
    display: grid;
    grid-column-gap: $smaller-gap;
    grid-row-gap: $smaller-gap;

    &--three-columns {
      grid-template-columns: 1fr 1fr 0.9fr;
    }

    &--two-columns {
      grid-template-columns: 1fr 1fr;
    }

    &--single-column {
      grid-template-columns: 1fr;
    }
  }

  &__welcome-message-container {
    @include body-text;
    margin: 0 $small-gap $small-gap $small-gap;
  }

  &__title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__social-icons-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $small-gap;
    margin-left: $small-gap;

    > a {
      display: flex;
    }
  }
}
