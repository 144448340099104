.guild-activities-and-language-tiles {
  display: grid;
  grid-gap: $small-gap;

  @include lg {
    grid-template-columns: 1fr 1fr;

    &--single {
      grid-template-columns: 1fr;
    }
  }

  .react-tile {
    height: 100%;
  }
}
