.react-table {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gap-12;
  }

  &__overflow-wrapper {
    overflow: auto;
    width: 100%;

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px $white-500;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: $white-600;
      border-radius: 10px;
    }
  }

  &__cell {
    &--no-wrap {
      white-space: nowrap;
      overflow-x: clip;
    }
  }

  &__row {
    &--selectable {
      cursor: pointer;
    }
  }


  &--report {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    background-color: #000;
    height: 100%;

    th,
    td {
      padding: $tiny-gap $smaller-gap $tiny-gap;
    }

    th {
      border: 1px solid $light-gray;
      background-color: $slightly-dark-gray;
      font-weight: normal;
      color: #eee;
      line-height: 22px;
    }

    td {
      border: 1px solid $gray;
    }

    tr:nth-child(2n + 1) {
      background-color: $dark-gray;
    }

    tr:hover {
      td {
        background-color: $dark-blue;
      }
    }
  }

  &--v2 {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    td,
    th {
      letter-spacing: 0;

      &:first-child {
        padding-left: $gap-12;
      }

      &:last-child {
        padding-right: $gap-12;
      }
    }

    th {
      font-size: pxToRem(18);
      font-family: inherit;
      font-weight: 400;
      color: $white-800;
      letter-spacing: -0.5px;
      background-color: transparent;
      padding: $gap-6 $gap-12;
      border-bottom: 1px solid $gray;

      @include sm {
        font-size: pxToRem(20);
      }
    }

    td {
      font-size: pxToRem(16);
      vertical-align: middle;
      padding: $gap-4;
      font-weight: 500;

      @include sm {
        font-size: pxToRem(18);
      }
    }

    tr:not(:last-child) {
      border-bottom: 1px solid $gray;
    }

    tbody tr:nth-child(2n + 1) {
      background: $black;
    }

    tbody tr:nth-child(2n) {
      background: #0d0d0d;
    }

    &--bordered {
      border-radius: 5px;
      border: 2px solid #252525;
      border-collapse: separate;
      overflow: hidden;

      thead {
        background-color: #181818;

        th:first-of-type {
          img.icon__image {
            border-radius: 5px;
          }
        }

        th {
          height: 2.5rem;

          .icon__label {
            font-size: pxToRem(19);

            @include sm {
              font-size: pxToRem(22);
            }
          }
        }
      }

      tbody {
        tr:not(:first-of-type) {
          border-top: 1px solid #545454;
        }

        td {
          font-size: pxToRem(15);
          padding: pxToRem(10);

          @include sm {
            font-size: pxToRem(16);
          }
        }
      }
    }
  }
}
