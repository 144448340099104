.guild-settings-page {
  &__content {
    display: grid;
    grid-gap: $large-gap;
    padding-top: $small-gap;
    scroll-behavior: smooth;

    @include md {
      grid-template-columns: 200px 1fr;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;

    > div {
      margin-bottom: $large-gap;
    }

    a.guild-settings-page__scroll-target {
      margin-top: -61px;
      margin-bottom: 61px;
    }

    .react-tile__content {
      position: relative;
      @include body-text;
    }


    .nav-stacked-container {
      position: -webkit-sticky;
      position: sticky;
      top: 62px;


    }
  }

  &__tiles {
    margin-bottom: 50vh;
  }
}

.fake-link {
  color: $link-blue;
}

