.bottom-sticky-notice {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1000;

  &__content {
    background-color: #af2e2e;
    padding: 6px 24px 4px 24px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
  }
}
