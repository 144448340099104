@use "sass:math";
@forward "./tools/new-badge";

@mixin no-vertical-margin-around {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin vertical-margin-between($amount: $content-padding) {
  margin-top: $amount * 0.5;
  margin-bottom: $amount * 0.5;

  @include no-vertical-margin-around;
}

@mixin no-horizontal-margin-around {
  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

@mixin horizontal-margin-between($amount: $content-padding) {
  margin-left: $amount * 0.5;
  margin-right: $amount * 0.5;

  @include no-horizontal-margin-around;
}

@mixin fill-view-width {
  max-width: 1800px;
  width: 99vw;

  @include md {
    width: 90vw;
  }
}

@mixin body-text($important: false) {
  @if ($important) {
    font-size: $body-font-size !important;
    line-height: $body-line-height !important;
  } @else {
    font-size: $body-font-size;
    line-height: $body-line-height;
  }
}

@mixin smaller-text($important: false) {
  @if ($important) {
    font-size: $smaller-font-size !important;
    line-height: $smaller-line-height !important;
  } @else {
    font-size: $smaller-font-size;
    line-height: $smaller-line-height;
  }
}

@mixin text-shadow() {
  text-shadow: rgba(0, 0, 0, 0) 0px 0px 1px, rgba(0, 0, 0, 0.8) 0px 1px 2px;
}

@mixin text-shadow-header() {
  text-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.75);
}

@mixin text-shadow-light() {
  text-shadow: rgba(100, 100, 100, 0) 0px 0px 1px, rgba(100, 100, 100, 0.8) 0px 1px 2px;
}

@mixin highlight-glow() {
  box-shadow: 0 0 15px $highlight, 0 0 15px $highlight;
}

@function pxToRem($pixels) {
  @return math.div(1rem * $pixels, 16);
}

/// Stroke font-character
/// @param  {Integer} $stroke - Stroke width
/// @param  {Color}   $color  - Stroke color
/// @return {List}            - text-shadow list
@function stroke($stroke, $color) {
  $shadow: ();
  $from: $stroke*-1;
  @for $i from $from through $stroke {
    @for $j from $from through $stroke {
      $shadow: append($shadow, $i*1px $j*1px 0 $color, comma);
    }
  }
  @return $shadow;
}

/// Stroke font-character
/// @param  {Integer} $stroke - Stroke width
/// @param  {Color}   $color  - Stroke color
/// @return {Style}           - text-shadow
@mixin stroke($stroke, $color) {
  text-shadow: stroke($stroke, $color);
}

@mixin restyled-scroll-bar() {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: $cms-background-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $light-gray;
    border-radius: 3px;
  }
}
