.guild-auto-join-without-an-account-tile {
  &__steps {
    padding-right: $large-gap;
    @include body-text;
  }

  &__or-login {
    background: $gray;
    border-radius: $border-radius;
    padding: $large-gap;
    @include body-text;
  }

  &__join-success {
    background: $gray;
    overflow: hidden;
    border-radius: $border-radius;
    @include body-text;
  }
}
