.editable-description {
  &__any-clip {
    display: none;
    float: right;
    padding-left: $small-gap;
    padding-bottom: $small-gap;

    @include md {
      display: block;
    }
  }
}