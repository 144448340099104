.progress-line-tile {
  border: 1px solid $light-gray;
  padding: $smaller-gap;
  background-position: center;
  background-size: cover;
  min-height: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
