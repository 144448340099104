.progress-race-leaderboard {
  &--v2 {
    // styling for the frontpage leaderboard

    .progress-race-leaderboard {
      &__table {
        border-collapse: collapse;
        border-spacing: 0;
        border: 1px solid $level-2-border;

        tr {
          border-bottom: 1px solid $level-2-border;
        }

        td {
          font-family: $body-font-family;
          font-size: $body-font-size;
          font-weight: 600;
          line-height: 1.8rem;
          padding: $tiny-gap;
          @include sm {
            padding: $tiny-gap $smaller-gap;
          }
        }

        img {
          width: 20px;
          height: 20px;
        }
      }
      &__encounters, &__encounters-header {
        display: none;
        @include sm {
          display: table-cell;
        }
      }
    }

    thead tr {
      line-height: 2rem;
      color: $lighter-gray;
      background-color: $darker-gray;
    }

    tbody tr td:nth-child(n+3) {
      font-size: $smaller-body-font-size;
      color: $off-white;
    }
    tbody tr:nth-child(even) {
      --progress-race-row-bg-color: #{$dark-gray};
      background-color: var(--progress-race-row-bg-color);
    }
    tbody tr:nth-child(odd) {
      --progress-race-row-bg-color: #{$darker-gray};
      background-color: var(--progress-race-row-bg-color);
    }
  }
  &__table {
    font-size: $subtitle-font-size;
    width: 100%;
    border-spacing: 0;

    th {
      font-family: $title-font-family;
      font-size: $body-font-size;
      text-align: center;
    }

    td {
      padding: $tiny-gap $smaller-gap;
    }
  }

  &__row {
    &--is-highlighted {
      background-color: transparentize($light-purple, 0.8);
    }
    &--guild-icon-background {
      @include sm {
        background-size: 70px 70px;
        background-position: -10px;
        background-repeat: no-repeat;
        background-image: linear-gradient(to right, transparent 0%, transparent 50%, var(--progress-race-row-bg-color) 100%), var(--guild-background-url);
        padding-left: 60px !important;
      }
    }
  }

  &__rank {
    text-align: center;
  }

  &__name {
    font-weight: 700;
    font-family: $title-font-family;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__progress, &__pulls, &__best-percent {
    text-align: center;
    font-family: $title-font-family;
  }

  &__pulls, &__best-percent {
    min-width: 3.5rem;
    white-space: nowrap;
  }

  &__gear {
    text-align: center;
    font-size: $body-font-size;
    min-width: 4rem;
  }

  &__encounters, &__name {
    &--shrink {
      width: 1px;
    }

    &--grow {
      width: 100%;
      max-width: 0;
      overflow: hidden;
    }
  }

  &__encounter-icon-container {
    position: relative;
  }

  &__encounter-icon {
    border-radius: 100%;
    width: 2.25rem;
    height: 2.25rem;
    vertical-align: middle;

    &--grayscale {
      filter: saturate(0);
    }
  }

  &__encounter-icon-label {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    font-size: pxToRem(14);
    text-align: center;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: $border-radius $border-radius 100% 100%;
  }
}
