.progress-race-guild-content {
  &__guild-nav-and-position {
    display: none;
  }

  &__sidebar-and-stream {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-gap: 6px;
  }

  &__stream-sidebar {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-gap: 6px;

    &--with-navigation {
      grid-template-rows: auto auto 1fr;
    }
  }

  &__stream-sidebar-nav ul, &__guild-nav-and-position ul {
    display: flex;
    flex-direction: column;
  }

  &__back-to-race-summary {
    display: flex !important;
    justify-content: space-between;
    align-content: center;
  }

  &__back-to-race-summary .zmdi {
    font-size: 17px;
  }

  &__guild-position {
    border: 1px solid $light-gray;
  }

  &__stream-sidebar-stats {
    border: 1px solid $light-gray;
    display: flex;
    flex-direction: column;
  }

  &__encounter-name {
    color: $encounter-name-color;
  }

  &__encounter-timestamp {
    font-weight: normal;
    color: $lighter-gray;
    font-size: $body-font-size;
  }

  &__show-detailed-composition {
    display: none;

    @include md {
      display: block;
    }
  }
}

@media(max-width: 1300px) {
  .progress-race-guild-content__guild-nav-and-position {
    display: grid;
    grid-gap: 6px;
    margin-bottom: 6px;

    &--with-navigation {
      grid-template-columns: 200px 1fr;
    }
  }

  .progress-race-guild-content__sidebar-and-stream {
    grid-template-columns: 100%;
  }

  .progress-race-guild-content__stream-sidebar {
    display: none;
  }
}

@media(max-width: 900px) {
  .progress-race-guild-content__guild-nav-and-position {
    &--with-navigation {
      grid-template-columns: 1fr 1fr;
    }
  }

  .progress-race-guild-content__encounter-timestamp {
    display: block;
  }
}
