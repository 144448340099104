// --current-size is defined via TalentTreeNode.scss
.talent-tree-node-multiple-points {
    position: absolute;
    border-radius: 50%;
    background: linear-gradient(90deg, #e2be00 0%, #b80058 100%);
    width: calc(0.5 * var(--current-size));
    height: calc(0.5 * var(--current-size));
    text-align: center;
    font-size: var(--multiple-points-font-size, calc(calc(0.75rem / $talent-tree-node-default-size) * var(--current-size)));
    color: white;
    font-weight: bold;
    border-style: solid;
    border-color: #242424;
    border-width: calc(calc(0.0625rem / $talent-tree-node-default-size) * var(--current-size));
    box-shadow: 0px 1px 4px rgb(0 0 0 / 45%);
    left: 62.5%;
    top: 62.5%;
    line-height: calc(1.3 * var(--multiple-points-font-size));
    font-family: Avenir,Arial,sans-serif !important;
  }
