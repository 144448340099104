.editable-schedule-table {
  &__actions-cell {
    width: 100px;
  }

  &__actions-container {
    display: grid;
    grid-gap: $small-gap;
    grid-template-columns: 1fr 1fr;
  }

  &__select-day-cell {
    min-width: 80px;

    @include md {
      padding: 0 $smaller-gap !important;
    }
  }

  &__add-time-range-controls {
    display: grid;
    grid-gap: $smaller-gap;
    min-width: 100%;

    @include md {
      min-width: 400px;
      grid-template-columns: 1fr auto auto;
    }
  }

  &__label-and-control {
    display: flex;
    flex-direction: column;
    > * {
      @include vertical-margin-between($tiny-gap);
    }

    label {
      @include body-text;
    }
  }

  &__validation-error {
    text-align: center;
    font-style: italic;
    @include body-text;
  }

  &__validation-tick {
    font-size: $title-font-size !important;
  }

  &__buttons {
    display: flex;
    justify-content: center;

    button {
      margin: 0;
      @include horizontal-margin-between($small-gap);
    }
  }
}
