.header-menu-sections-panel {
  &__section {
    &-wrapper {
      background: $level-0-bg;
      border-bottom: 1px solid $level-0-border;
      padding-bottom: $gap-8;

      @include md {
        background: transparent;
        border-bottom: 0px;
        padding-bottom: 0px;
      }
    }

    &-items {
      list-style: none;
      padding-inline-start: 0;
      margin: 0;
      padding: 0 $gap-8;

      @include md {
        padding: 0;
      }

      &-toggle-more {
        padding-left: 22px;
        padding-top: $gap-8;

        @include md {
          padding: $gap-8 $gap-16;
          background: $level-0-bg;
        }

        &-button {
          border: none;
          width: 100%;
          display: flex;
          cursor: pointer;
          justify-content: space-between;
          align-items: center;
          background: $level-1-bg;
          border-radius: 28px;
          padding: $gap-4 $gap-16;
          @include md {
            transition: filter 0.08s ease-out;
          }

          @include md {
            padding: initial;
            border-radius: unset;
            background: unset;
          }

          &:hover {
            filter: brightness(1.3);
          }

          &-title {
            font-weight: 700;
            font-size: $smaller-font-size;
            line-height: $smaller-font-size;
            color: $white-800;
            text-shadow: 0px 4px 4px #000000;
          }

          &-indicator {
            padding-bottom: 0;
            padding-top: $gap-4;

            &.expand-indicator--expanded {
              padding-top: initial;
              padding-bottom: $gap-4;
            }
          }
        }
      }
    }
  }
}
