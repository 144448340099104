.header-section-header {
  background: transparent;
  border: 0px solid $level-0-border;
  border-top-width: 1px;
  position: relative;
  overflow: hidden;
  padding: $gap-4 $gap-8 $gap-8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @include md {
    transition: background 0.14s ease-out;
    &__background--reports,
    &__dropdown-wrapper {
      transition: filter 0.14s ease-out;
    }
    padding: $gap-16;
    background: $level-0-bg;
    border-bottom-width: 1px;
  }

  &--clickable {
    cursor: pointer;
  }

  &--no-guild {
    .header-section-header__content-wrp {
      @include md {
        align-items: initial;
      }
    }
  }

  &:not(&--character):hover {
    background: $level-0-bg-active;
  }
  &:not(&--character):hover & {
    &__background,
    &__dropdown-wrapper {
      filter: brightness(1.3);
    }
  }

  &--characters {
    filter: none !important;
    cursor: default;
  }

  &--empty-character,
  &--no-guild {
    padding: $gap-12 $gap-8 $gap-16;
    @include md {
      padding: $gap-12 $gap-16 $gap-16;
    }
  }

  &__background {
    position: absolute;
    opacity: 0.6;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-width: 230px;
    z-index: 1;
    background-position: 50% 50%;
    background-size: cover;
    mask-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 100 100' preserveAspectRatio='none' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='200%25' height='200%25' x='0%25' y='-100%25' fill='url(%23rad)'/%3E%3Cdefs%3E%3CradialGradient id='rad'%3E%3Cstop offset='80%25'/%3E%3Cstop offset='100%25' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E");
    mask-size: 100% 100%;
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 100 100' preserveAspectRatio='none' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='200%25' height='200%25' x='0%25' y='-100%25' fill='url(%23rad)'/%3E%3Cdefs%3E%3CradialGradient id='rad'%3E%3Cstop offset='80%25'/%3E%3Cstop offset='100%25' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E");
    -webkit-mask-size: 100% 100%;

    &--characters {
      mask-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 100 100' preserveAspectRatio='none' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' x='0%25' y='0%25' fill='url(%23rad)' /%3E%3Cdefs%3E%3ClinearGradient id='rad'%3E%3Cstop offset='0%25' style='stop-opacity:0' /%3E%3Cstop offset='10%25' style='stop-opacity:1' /%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
      -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 100 100' preserveAspectRatio='none' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' x='0%25' y='0%25' fill='url(%23rad)' /%3E%3Cdefs%3E%3ClinearGradient id='rad'%3E%3Cstop offset='0%25' style='stop-opacity:0' /%3E%3Cstop offset='10%25' style='stop-opacity:1' /%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
    }

    &--reports {
      max-width: 140px;
    }

    &--characters#{&}--ff,
    &--characters#{&}--warcraft,
    &--characters#{&}--swtor {
      max-width: 140px;
    }

    &--characters#{&}--eso {
      max-width: 170px;
    }

    &--characters#{&}--ff {
      background-size: cover;
      background-position: 50% 73%;
    }

    &--characters#{&}--warcraft {
      background-position: 50% 20%;
    }
  }

  &__user-select-catch {
    display: none;
    position: absolute;
    z-index: 3;
    user-select: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include md {
      display: block;
    }
  }

  &__top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 3;
    width: 100%;
  }

  &__content-wrp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 41px;

    @include md {
      justify-content: initial;
      min-height: auto;
    }
  }

  &__content-title {
    margin-bottom: 0px;
    font-size: $subtitle-font-size;
    letter-spacing: 0.015em;
    font-weight: 700;
    color: $white-800 !important;
    text-shadow: 0px 4px 4px #000000;
    pointer-events: none;
    position: relative;
    z-index: 4;

    a {
      pointer-events: none;
      color: $white-800;
    }

    @include md {
      letter-spacing: initial;
      margin-bottom: $gap-4;

      a {
        pointer-events: all;
        transition: color 0.08s ease-out;

        &:hover {
          color: $link-blue;
        }
      }
    }
  }

  &__extra-text {
    font-weight: 400;
    font-size: $smaller-body-font-size;
    line-height: 19px;
    color: $white-700;
    text-shadow: 0px 2px 4px #000000;
    max-width: 350px;

    @include md {
      margin-bottom: $gap-8;
    }
  }

  &__content-actions {
    display: none;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    @include md {
      display: flex;
      order: 2;
    }
  }

  &__dropdown-wrapper {
    margin-left: $gap-16;
    padding: $gap-8;
    margin-right: -$gap-8;
  }

  &__mobile-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $level-1-bg;
    border: 1px solid $level-1-border;
    border-radius: 4px;
    width: 100%;
    padding: $gap-4;
    position: relative;
    z-index: 2;
    margin-top: $gap-4;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.8);
    box-sizing: border-box;

    @include md {
      display: none;
    }
  }
}
