@use 'resources/sass/variables';
@use 'sass:math';

$talent-height: 2.375rem;
$row-height: 3rem;

.builds-alternative-talent-tree-builds-section {
  color: variables.$white-800;

  &__alternative-build {
    border: 1px solid variables.$level-1-border;
    border-radius: variables.$border-radius;
    padding: variables.$small-gap;
    background: linear-gradient(91.38deg, var(--alternative-build-color) 0%, #141414 50%, #121212 100%);

    &--epic {
      --alternative-build-color: rgb(43, 15, 48);
    }

    &--rare {
      --alternative-build-color: rgb(17, 15, 48);
    }

    &--uncommon {
      --alternative-build-color: rgb(15, 48, 18);
    }
  }

  &__alternative-build-row {
    display: grid;
    grid-template-columns: 100%;
    gap: variables.$small-gap;

    @include md() {
      grid-template-columns: max-content;

      /* this trickery handles 2- and 3-column layouts automatically for optional hero talent column */
      grid-auto-columns: 2fr;
      & > * {
        grid-row: 1;
      }
    }

    &--content-hero {
      display: grid;
      grid-template-columns: min-content;
      gap: 1em;
    }

  }

  &__alternative-build-column {
    display: grid;
    grid-template-rows: 2.2em $row-height $row-height;
    gap: variables.$smaller-gap;
    align-content: center;
    align-items: center;
    padding: variables.$subtitle-v-padding variables.$content-padding;
    overflow-x: auto;

    /* this is a specificity hack to remove the class color from the actor icon label.
       the enhanced markdown component assumes you want a class color, and it is not practical
       to operate outside of enhanced markdown in this context. moving the text outside of the icon label
       breaks the icon (which assumes there will be a label). time is short, this is a hack but it is a
       working hack. */
    .icon .icon__label > *, .icon .icon__label > .do-not-change-color-on-hover:hover {
      color: variables.$white-800 !important;
    }

    &--labels {
      text-align: end;
      padding: 0.5rem 0;
    }

    & > .column-label {
      color: variables.$white-800 !important;

      @include lg() {
        justify-self: center;
      }

      &--hero {
        display: flex;
        flex-direction: row;
        justify-items: center;
      }
    }

    &--inset {
      background-color: variables.$level-0-bg-no-gradient;
      border: 1px solid variables.$level-2-border;
      border-radius: variables.$border-radius;
    }

    &:first-child {
      display: none;

      @include sm() {
        display: grid;
      }
    }
  }

  &__changed-talent-row {
    --talent-tree-node-width: #{$talent-height};
    --multiple-points-font-size: 1rem;
    --cell-dimension: #{$row-height};

    &--empty {
      font-style: italic;
      color: variables.$white-600;
    }
  }

  &__large-link-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__large-link-caption {
    margin-bottom: -7px;
  }

  &__large-link-text {
    display: block;
    font-weight: 800;
    font-size: 1.25 * variables.$button-font-size;
  }

  &__talent-tree {
    border: 1px solid variables.$level-1-border;
    background: variables.$level-1-bg;
    width: fit-content;
    align-self: center;

    @include lg() {
      border-radius: variables.$border-radius;
    }
  }

  &__tiles {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: variables.$small-gap;

    &--tiny-gap {
      gap: variables.$tiny-gap;
    }

    @include lg() {
      display: flex;
    }
  }
}
