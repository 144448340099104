.progress-race-encounter-page {
  &__featured-streams {
    @include md {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: $small-gap;
    }
  }

  &__stream-composition {
    padding: $smaller-gap;
    border-top: 1px solid $light-gray;
  }

  &__featured-streams-description {
    display: flex;
    justify-content: center;
  }

  &__featured-stream-subtext, &__encounter-timestamp {
    font-weight: normal;
    color: $lighter-gray;
    font-size: $body-font-size;
  }

  &__featured-stream-subtext {
    display: none;

    @include lg {
      display: unset;
    }
  }
}
