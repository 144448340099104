.site-select {
  width: 100%;
  border-bottom: 1px solid $level-0-border;
  line-height: 100%;

  &__options {
    list-style: none;
    padding: $tiny-gap;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: $tiny-gap;
  }

  &__option {
    display: flex;
    align-items: center;
    font-size: pxToRem(14);
    text-transform: uppercase;
    font-weight: 700;
    border: 1px solid transparent;
    border-radius: $border-radius;
    padding-top: 2px;
    padding-bottom: 3px;

    &:hover {
      background: $level-2-bg;
      border: 1px solid $level-2-bg;

      a {
        color: $white-800 !important;
      }
    }

    img {
      margin-bottom: -3px;
      width: 16px;
      height: 16px;
    }

    a {
      color: $white-700 !important;
      padding: $tiny-gap $smaller-gap;
    }

    &--active {
      background: $level-2-bg;
      border: 1px solid $level-2-bg;

      a {
        color: $white-800 !important;
      }
    }
  }
}
