.recruitment-entry-point-tile {
  p {
    @include text-shadow;
  }

  &__announcement {
    align-self: center;
    text-align: center;
  }

  &__sub-tiles {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $small-gap;

    &--two-column {
      @include lg {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
  }

  &__content-and-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__content {
    &--with-image {
      @include md {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: $large-gap;
        align-items: center;
      }
    }

    &--small-gap {
      grid-gap: $small-gap;
    }
  }

  &__buttons {
    margin-top: $small-gap;
    align-items: center;
  }

  &__featured-guilds-label {
    display: none;

    @include md {
      display: inline;
    }
  }

  &__featured-guild-buttons {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    height: 50px;
    overflow: hidden;
  }

  &__featured-guild-button {
    font-weight: bold;
    padding-right: $content-padding;
    white-space: nowrap;
    line-height: 1.4;
  }

  &__image-container, &__featured-guilds {
    display: none;

    @include md {
      display: block;
    }
  }

  &__image {
    max-height: 155px;
    border-radius: $border-radius;
  }
}
