.page-title-and-image {
  display: grid;
  align-items: flex-end;

  &--with-image {
    @include md {
      grid-template-columns: 2fr 1fr;
    }
  }

  &__image-container {
    display: none;

    @include md {
      display: block;
      position: relative;
      height: 150px;
    }
  }

  &__image {
    z-index: -1;
    position: absolute;
  }
}
