.builds-large-link-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

  &__caption {
    margin-bottom: -7px;
  }

  &__text {
    display: flex;
    align-items: center;
    gap: $tiny-gap;
    font-weight: 800;
    font-size: 1.25 * $button-font-size;
  }

  &__background-icon {
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0.25rem;
    left: -20px;
    opacity: 0.15;
    position: absolute;
    right: 0;
    top: 0.25rem;
  }
}
