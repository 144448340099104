.paginate {
  text-align: center;
  position: relative;

  &__disabled-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: not-allowed;
    z-index: 10;
  }

  &__container {
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;

    > li {
      display: none;
      @include horizontal-margin-between;
      @include body-text;

      @include sm {
        display: inline-block;
      }
    }
  }

  &__page:not(.paginate__active) {
    @include link-looking-button;
  }

  &__page-link {
    &--disabled {
      filter: grayscale(100%);
    }
  }

  &__active {
    text-decoration: underline;
    text-underline-offset: 4px;
  }

  &__disabled {
    .button {
      cursor: not-allowed;
      filter: grayscale(100%);
    }
  }

  &__previous, &__next {
    display: inline-block !important;
  }

  &__previous {
    margin-right: auto !important;
  }

  &__next {
    margin-left: auto !important;
  }

  &__total-pages {
    margin-top: $content-padding;
    display: block;

    @include sm {
      margin-top: 0;
      display: none;
    }
  }
}
