.subscription-tiers-call-to-action {
  align-items: center;

  &__text {
    @include body-text;
  }

  &__wechat-tile {
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 480px;
    @include md {
      flex-direction: row;
    }
  }

  &__wechat-localized-name {
    @include body-text;
    padding: $content-padding 0 0 0;
    @include md {
      padding-left: $content-padding;
    }
  }
}
