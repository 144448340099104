// Small tablets and large smartphones (landscape view)
$screen-sm-min: 540px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 1024px;

// Large tablets and desktops
$screen-xl-min: 1400px;


// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// CSS Container Queries
// Baseline 2023
// https://caniuse.com/css-container-queries
//
@mixin container-xs {
  @container (min-width: 20rem) {
    @content;
  }
}
@mixin container-sm {
  @container (min-width: 24rem) {
    @content;
  }
}
@mixin container-md {
  @container (min-width: 28rem) {
    @content;
  }
}
@mixin container-lg {
  @container (min-width: 32rem) {
    @content;
  }
}
@mixin container-xl {
  @container (min-width: 36rem) {
    @content;
  }
}
@mixin container-2xl {
  @container (min-width: 42rem) {
    @content;
  }
}
@mixin container-3xl {
  @container (min-width: 48rem) {
    @content;
  }
}
@mixin container-4xl {
  @container (min-width: 56rem) {
    @content;
  }
}
@mixin container-5xl {
  @container (min-width: 64rem) {
    @content;
  }
}
@mixin container-6xl {
  @container (min-width: 72rem) {
    @content;
  }
}
