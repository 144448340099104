.latest-progress-updates-tile {
  &--with-ad {
    display: grid;
    grid-template-columns: auto 400px;
    grid-gap: $small-gap;
    align-items: center;

    @media(max-width: 900px) {
      grid-template-columns: auto;

      .any-clip {
        display: none;
      }
    }
  }

  &__latest-updates-content {
    .react-tile__content {
      padding: $tiny-gap;
      background-color: $darker-blue;

      .mCSB_inside > .mCSB_container {
        margin-right: 22px;
      }
    }
  }
}
