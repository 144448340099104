.spec-selector {
  margin-bottom: $small-gap;
  @include body-text;

  &__controls {
    display: grid;
    align-items: center;
    grid-gap: $tiny-gap;

    @include md {
      grid-template-columns: auto 1fr auto;
      grid-gap: $small-gap;
    }
  }
}