.horizontal-rule {
  width: 100%;

  &--color-light-gray {
    color: $light-gray;
    border-color: $light-gray;
    background-color: $light-gray;
  }

  &--color-lighter-gray {
    color: $lighter-gray;
    border-color: $lighter-gray;
    background-color: $lighter-gray;
  }
}
