.bar {
  &__big-bar {
    width: 100%;
    position: relative;
  }
  &__small-bar {
    position: absolute;
    bottom: 0;
    height: 1px;
  }
}
