.builds-best-in-slot-gear-section {
  &__heading-and-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $smaller-gap $content-padding;
    flex-wrap: wrap;

    h2 {
      margin-bottom: 0 !important;
    }
  }

  &__header {
    margin: 0.5rem 0 0.25rem;
    font-size: 1.0625rem;
    font-weight: 700;
    color: $white-800;
    gap: 4px 14px;

    @include lg() {
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 7px));
    }

    &-item {
      display: none;
      width: 100%;
      justify-content: space-between;

      @include sm() {
        display: flex;
      }

      &:last-of-type {
        display: none;
        flex-direction: row-reverse;

        @include lg() {
          display: flex;
        }
      }
    }
  }

  &__gear {
    display: flex;
    flex-direction: column;
    gap: 4px 14px;

    @include lg() {
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 7px));
    }

    &--highlighted {
      display: grid;
      gap: 4px;
    }

    &-item {
      padding: 4px 4px;
      border: 1px solid $slightly-dark-gray;
      border-radius: 0.25rem;
      background: $level-0-bg;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-icon {
        width: 100%;

        @include sm() {
          width: 75%;
        }

        .lazyload-wrapper {
          display: inline !important;
        }
      }

      @include lg() {
        @for $i from 1 through 12 {
          &:nth-of-type(#{$i}) {
            grid-row: if($i == 6 or $i == 12, 6, $i % 6);
            grid-column: if($i > 6, 2, 1);
            flex-direction: if($i > 6, row-reverse, initial);

            padding-left: if($i > 6, 10px, 4px);
            padding-right: if($i > 6, 4px, 10px);

            .builds-best-in-slot-gear-section__gear-item-meta {
              text-align: if($i > 6, left, right);
            }
          }
        }
      }

      &-meta {
        line-height: 100%;
        font-weight: 700;
        gap: 6px;
        padding: 0.5rem 0;
        display: none;
        text-align: right;
        
        @include sm() {
          display: flex;
          flex-direction: column;
        }

        &-popularity {
          font-size: 17px;
        }

        &-parses {
          color: $white-600;
          font-weight: 500;
          white-space: nowrap;
        }
      }
    }
  }

  &__highlighted-gear {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 1rem;

    &-title-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    &-title {
      &--mobile {
        display: initial;
        @include lg() {
          display: none;
        }
      }

      &:last-of-type {
        display: none;

        @include lg() {
          display: initial;
        }
      }
    }

    @include lg() {
      .builds-best-in-slot-gear-section__gear-item {
        &[data-column='1'] {
          grid-column: 1;
        }

        &[data-row='1'] {
          grid-row: 1;
        }

        &[data-row='2'] {
          grid-row: 2;
        }

        &[data-column='2'] {
          grid-column: 2;
          flex-direction: row-reverse;
          padding-left: 10px;
          padding-right: 4px;

          .builds-best-in-slot-gear-section__gear-item-meta-popularity {
            text-align: left;
          }
        }
      }
    }
  }
}
