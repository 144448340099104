.guild-child-guilds-tile {
  &__table_container {
    @include lg {
      width: 66%;
      margin: 0 auto;
    }
  }

  &__table-commands-cell {
    > * {
      margin: 0 $smaller-gap;
    }
  }

  &__add-teams-container {
    display:flex;

    > div:first-child {
      flex: 1;
      margin-right: $smaller-gap;
    }
  }
}
