.builds-class-and-spec-box {
  display: flex;
  flex-direction: column;
  width: 232px;
  height: auto;

  overflow: hidden;
  border-radius: 5px;

  border: 1px solid $level-2-border;
  box-shadow: 0 0 25px -15px;

  &__class-title {
    font-size: 21px;
    font-weight: 700;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    padding: 7px 18px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid $level-2-border;

    &__link-container {
      display: flex;
      color: $white-900;
    }
  }

  &__class-specs {
    padding: 16px 18px;
    text-align: center;
    height: 100%;

    &__link-container {
      display: flex;
      color: $white-800;
    }

    &__spec {
      display: flex;
      flex-direction: row;
      justify-content: center;

      font-size: 17px;
      font-weight: 700;

      @include vertical-margin-between(8px);
    }
  }

  &__class-icon {
    margin-right: 10px;
  }

  &__spec-icon {
    margin-right: 8px;
  }
}
