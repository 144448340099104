.latest-progress-updates {
  &__lines {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  &__line-timestamp, &__line {
    padding: 7px 10px;
    border: 1px solid rgb(51, 51, 51);
    border-top: 0;
  }

  &__line-timestamp {
    display: flex;
    align-items: center;
    border-right: none !important;
    padding-right: 0;
  }

  &__line {
    border-left: none !important;
  }

  &__pinned-line {
    grid-column: 1 / span 2;
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    justify-content: space-between;
    grid-gap: $small-gap;
    border-left: none !important;
    border-right: none !important;
    padding: $smaller-gap $small-gap;
  }

  &__line-timestamp:nth-of-type(4n+1), &__line:nth-of-type(4n+2) {
    background-color: $black;
  }

  &__line-timestamp:nth-of-type(4n+3), &__line:nth-of-type(4n) {
    background-color: #101010;
  }

  &__line-timestamp:first-child, &__line:nth-child(2) {
    border: 1px solid rgb(51, 51, 51);
  }

  &__line-timestamp {
    color: rgb(82%,98%,60%);
  }

  &__line-image {
    width: 20px;
    height: 20px;
    margin-bottom: -5px;

    &--custom-guild {
      border-radius: 100%;
    }

    &--encounter-icon {
      border-radius: 100%;
      border: 1px solid #282d5f;
      box-sizing: border-box;
    }
  }

  &__line-encounter-name {
    color: $encounter-name-color;
  }

  &__line-rank {
    font-weight: bold;
  }

  &__view-more {
    grid-column: 1 / span 2;
    display: flex;
    justify-content: center;
    padding: $tiny-gap;
  }

  .markdown__content {
    font-size: 13px !important;
    line-height: 22px !important;
  }
}

@media(max-width: 699px) {
  .latest-progress-updates__lines {
    grid-template-columns: 100%;
  }

  .latest-progress-updates__line-timestamp, .latest-progress-updates__line {
    border-left: 1px solid rgb(51, 51, 51) !important;
    border-right: 1px solid rgb(51, 51, 51) !important;
  }

  .latest-progress-updates__line-timestamp {
    border-bottom: none;
  }

  .latest-progress-updates__line {
    border-top: none;
    padding-top: 0;
  }

  .latest-progress-updates__line-timestamp:first-child {
    border-bottom: none;
  }

  .latest-progress-updates__line:nth-child(2) {
    border-top: none;
  }
}
