@mixin zebra($style) {
  &:nth-child(#{$style}) {
    background: rgba(255, 255, 255, 0.02);
    border-top: 1px solid $level-0-border;
    border-bottom: 1px solid $level-0-border;
  }
}

.article-preview-list {
  display: grid;
  grid-template-columns: 1fr;

  &__article-preview {
    padding: $content-padding 0;

    &--zebra-even {
      @include zebra(even);
    }

    &--zebra-odd {
      @include zebra(odd);
    }
  }

  &--vertical {
    .article-preview-list__article-preview {
      &:first-child {
        padding-top: 0;
      }
    }
  }

  &--horizontal {
    @include lg {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 2rem;

      .article-preview-list__article-preview {
        padding: 0;
        background-color: unset !important;
        border: none !important;
      }
    }
  }
}
