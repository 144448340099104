.builds-quick-stats-section {
  &-title-row {
    display: grid;
    grid-template-columns: 0 1fr 0;
    margin-bottom: 0.5rem;

    @include md() {
      grid-template-columns: 1fr 1fr 1fr;
      margin-bottom: 0;
    }
  }

  &-title {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 21px;
    background: $level-0-bg-active;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.8);
    border: 1px solid $level-1-border;
    text-align: center;
    padding: 0.5rem 1rem;

    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;

    @include md() {
      position: relative;
      width: fit-content;
      margin: auto auto -0.75rem;
      border-radius: 0.5rem;
    }
  }

  &-row {
    display: flex;
    flex-direction: column;

    &:not(:first-of-type) {
      row-gap: $smaller-gap;

      @include md() {
        row-gap: $smaller-gap;
      }
    }

    &:first-of-type {
      margin-bottom: $smaller-gap;

      @include md() {
        row-gap: $tiny-gap;
        margin-bottom: unset;
      }
    }

    @include md() {
      display: grid;
      grid-template-columns: repeat(var(--items), 1fr);
      column-gap: $smaller-gap;
    }

    &__box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      border: 1px solid $level-1-border;

      &:first-of-type {
        @include md() {
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
        }
      }

      &:last-of-type {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        @include md() {
          border-top-right-radius: 5px;
          border-bottom-left-radius: unset;
        }
      }

      height: 105px;
      text-align: center;
      text-transform: uppercase;

      padding-top: $smaller-gap;
      padding-bottom: $smaller-gap;

      @include md() {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }

      color: $white-800;
      font-weight: 700;

      background: $level-0-bg-active;

      &-top {
        font-size: 21px;
        width: 100%;
      }

      &-center {
        align-self: center;
        width: 100%;
        margin-top: 2px;
        margin-bottom: -2px;

        &--no-bottom {
          margin-top: 18px;
        }
      }

      &-bottom {
        font-size: 17px;
        width: 100%;

        @include md() {
          align-self: flex-end;
          min-height: 1.5rem;
        }
      }
    }
  }

  &__value {
    font-size: 48px;
    color: $white-800;

    &--with-enumeration {
      position: relative;
      right: -0.75rem;
    }
  }

  &__enumeration {
    text-transform: initial;
    font-size: 21px;

    position: relative;
    left: -0.75rem;
  }

  &__metric-link {
    display: contents;
    text-decoration: inherit !important;
    color: inherit !important;
  }
}
