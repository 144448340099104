// no parent element, otherwise styling breaks in e.g. ScheduleBoxes
.tooltip--trigger {
  appearance: none;
  background: none;
  padding: 0;
  border: none;
}

button.tooltip--trigger {
  cursor: pointer;
}

.tooltip--body {
  margin-top: $smaller-gap;
  color: $white-800;
  background: black;
  border: 1px solid $level-1-border;
  border-radius: $border-radius;
  display: inline-block;
  padding: 8px 21px;
  max-width: 1024px;

  font-size: 1.0625rem;
  line-height: 1.6875rem;
  z-index: 2;
  pointer-events: none;
}

.tooltip--arrow {
  position: absolute;
  background: black;
  border: 1px solid $level-1-border;
  width: 1rem;
  height: 1rem;

  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 100 100' preserveAspectRatio='none' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0 L100 100 L0 100 z' fill='%23ffffff'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 100 100' preserveAspectRatio='none' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0 L100 100 L0 100 z' fill='%23ffffff'/%3E%3C/svg%3E");
  mask-size: 100% 100%;
  -webkit-mask-size: 100% 100%;

  &[data-side='top'] {
    transform: rotate(135deg);
  }

  &[data-side='bottom'] {
    transform: rotate(315deg);
  }

  &[data-side='left'] {
    transform: rotate(45deg);
  }

  &[data-side='right'] {
    transform: rotate(225deg);
  }
}
