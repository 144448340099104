.header-title {
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  border-bottom: 1px solid $light-gray;

  &--floating {
    position: absolute;
    z-index: 2;
    border-bottom: none;
    padding: $small-gap;
  }

  &--no-border {
    border-bottom: none;
    background: none;
  }

  &--darkened {
    position: relative;
  }

  &--footer {
    .header-title__text {
       font-size: 32px;
    }

    .header-title__logo-image {
      height: 36px;
      width: auto;
    }

    .header-title__content {
      justify-content: flex-start;
      align-items: center;
      background: transparent;
    }

    .header-title__content {
      zoom: 1.0 !important;

      @media (max-width: 980px) {
        justify-content: center;
      }
    }
  }

  &__darkening-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    z-index: 1;
  }

  &__content {
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.75);
    zoom: 0.4;

    @include sm {
      zoom: 0.5;
    }

    @include md {
      zoom: 0.8;
    }

    @include lg {
      zoom: unset;
    }

    &--floating {
      background-color: unset;
      zoom: unset;
    }

    &--darkened {
      position: relative;
      z-index: 2;
    }
  }



  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;

    &-image {
      width: auto;
      height: 4.25rem;

      &--rounded {
        border-radius: 100%;
      }

      &--slim {
        width: auto;
        height: 2.75rem;
      }

      &--footer-image-only, &--floating {
        height: 48px;
      }

      &--floating {
        width: auto;
        @include md {
          height: 31px !important;
        }
      }

      &--footer-image-only {
        width: auto;
        @include md {
          height: 66px !important;
        }
      }
    }
  }

  &__text {
    position: relative;
    text-align: center;
    white-space: nowrap;
    filter: drop-shadow(2px 2px 5px $black); /* Cannot use text-shadow because of text-fill-color: transparent */
    text-transform: uppercase;
    font-family: $title-font-family;
    font-size: 4rem;

    &--slim {
      font-size: 2.5rem;
      font-weight: bold;
    }

    &--floating {
      font-size: 2rem;
      margin-bottom: 2px;
      display: none;

      @include md() {
        display: block;
      }
    }
  }

  &__text-one {
    background: linear-gradient(180deg, rgb(45,170,200) 0%, rgb(45,170,200) 50%, rgb(0,140,195) 50%, rgb(0,140,195) 100%);
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background-clip: text !important;
    text-fill-color: transparent;
  }

  &__text-two {
    background: linear-gradient(180deg, #e6e6e6 0%, #e6e6e6 50%, #bebebe 50%, #bebebe 100%);
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background-clip: text !important;
    text-fill-color: transparent;

    &--with-padding {
      margin-left: $smaller-gap;
    }

    @media (max-width: #{$screen-md-min}) {
      &--responsive {
        display: none;
      }
    }
  }

  &__text-top-one {
    color: rgb(45,170,200)
  }

  &__text-top-two {
    color: rgb(230,230,230);
  }

  &__text-bottom-one {
    color: rgb(0,140,195)
  }

  &__text-bottom-two {
    color: rgb(190,190,190)
  }
}

body.warcraft {
  .header-title {
    &__content {
      &--faction-0 {
        background-color: rgba(10, 10, 74, 0.75);
      }

      &--faction-1 {
        background-color: rgba(93, 4, 4, 0.88);
      }
    }
  }
}
