.header-top-bar {
  width: 100%;
  height: $header-top-height;
  box-sizing: border-box;
  background: $level-0-bg;
  border-bottom: 1px solid $level-0-border;
  padding: 0 $gap-16;
  display: flex;
  justify-content: stretch;
  align-items: center;
  position: relative;
  z-index: 3;

  &__background {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    z-index: 1;
    background-position: 50% 50%;
    background-size: cover;
    mask-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 100 100' preserveAspectRatio='none' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='300%25' x='0%25' y='-150%25' fill='url(%23rad)'/%3E%3Cdefs%3E%3CradialGradient id='rad'%3E%3Cstop offset='80%25'/%3E%3Cstop offset='100%25' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E");
    mask-size: 100% 100%;
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 100 100' preserveAspectRatio='none' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='300%25' x='0%25' y='-150%25' fill='url(%23rad)'/%3E%3Cdefs%3E%3CradialGradient id='rad'%3E%3Cstop offset='80%25'/%3E%3Cstop offset='100%25' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E");
    -webkit-mask-size: 100% 100%;
    opacity: 0.4;
  }

  &__inner-wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__game-logo {
    margin-right: $gap-16;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__report-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    height: 100%;
  }

  &__button {
    &-link {
      background: $level-2-bg;
      border: 1px solid $level-2-border;
      padding: $gap-4 $gap-8 $gap-2;
      color: $white-700 !important;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: $gap-16;
      height: 31px;
      box-sizing: border-box;
      border-radius: 3px;
      transition: background 0.14s ease-out;
      position: relative;

      &__large-padding {
        padding-left: $gap-32;
      }

      &-responsive {
        display: none;
      }

      &:hover {
        background: $active-300;
      }
    }

    &-icon {
      height: 16px;
      width: auto;
      margin-right: $gap-8;
    }

    &-artwork {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      height: 100%;
    }
  }

  &__links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 1px;

    &--item {
      font-size: $body-font-size;
      color: $white-800 !important;
      margin-right: $gap-16;
      transition: filter 0.14s ease-out;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.85);
      letter-spacing: 0.02em;

      &:hover {
        filter: brightness(1.15);
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  &__spacer {
    flex-grow: 1;
  }

  &--compact {
    @media (max-width: 1540px) {
      .header-top-bar {
        &__links {
          display: none;
        }
      }
    }
  }

  &__game-logo,
  &__game-toggle,
  &__button-link,
  &__links,
  .header-language-picker {
    flex-shrink: 0;
  }

  .header-search-select {
    flex-shrink: 1;
  }

  @media (max-width: 1200px) {
    &__button-link {
      &-responsive {
        display: table;
      }

      &-full {
        display: none;
      }
    }
  }

  @media (max-width: 1200px) {
    &__links {
      display: none;
    }
  }
}
