@import "@resources/sass/variables";

.a52596fdd-with-close-button {
  width: 100%;
  overflow: hidden;

  &__controls {
    margin: 0 3px;
    pointer-events: none;
    height: 20px;
    display: flex;
    justify-content: flex-end;
    button {
      background-color: $black;
      padding: 1px 4px;
      border-radius: 5px 5px 0 0;
      pointer-events: auto;
    }
    &--with-corner-video {
      @media (max-width: 1350px) {
        justify-content: flex-start;
      }
    }
  }
}
