.react-select {
    &__wrapper {

    }

    &__control {
        background-color: $input-background !important;
    }

    &__value-container {
      background-color: $input-background !important;
    }

    &--default {
        font-size: $smaller-font-size !important;
    }
}