@use '../TalentTreeNode/TalentTreeNode.scss' as talent;
// --current-size is defined via TalentTreeNode.scss

@mixin mask() {
  mask-image: url("data:image/svg+xml,%3Csvg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M0 7L7 0H31L38 7V31L31 38H7L0 31V7Z' fill='%23000000'/%3E%3C/svg%3E");
  mask-size: 100% 100%;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M0 7L7 0H31L38 7V31L31 38H7L0 31V7Z' fill='%23000000'/%3E%3C/svg%3E");
  -webkit-mask-size: 100% 100%;
}

.talent-tree-node-choice {
  position: relative;
  &--selected {
    img {
      @include mask();
      height: calc(100% - calc(0.1 * var(--current-size))) !important;
      object-fit: contain;
    }
  }

  &__tooltip-trigger {
    &--inactive {
      height: calc(100% - calc(0.1 * var(--current-size)));

      @include mask();

      img {
        width: 50%;
        height: 100%;
        object-fit: cover;
      }
    }

    &--active {
      display: flex;
      height: 100%;
      align-items: center;
    }
  }
}

.talent-tree-node-choice__overlay {
  background-image: url("data:image/svg+xml,%3Csvg width='66' height='46' viewBox='0 0 66 46' fill='none' fill-opacity='0' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 35V10L20 1H45L55 10L55 35L45 45H20L11 35Z' fill='black' stroke='url(%23paint0_linear_90_115557)' stroke-width='2'/%3E%3Cpath d='M14 15V14L13 14L3 22L2 23L2 24L13 32L14 33V31V15Z' fill='%23FFE901' fill-opacity='1' stroke='black' stroke-width='1.5'/%3E%3Cpath d='M52 31V32L53 32L63 24L64 23L63 22L53 14L52 13V15V31Z' fill='%23FFE901' fill-opacity='1' stroke='black' stroke-width='1.5'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_90_115557' x1='33' y1='0' x2='33' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FFFF00'/%3E%3Cstop offset='1' stop-color='%23FFC300'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% 100% !important;
  height: 100%;

  position: absolute;
  left: calc((100% - var(--current-size) * 66 / 46) / 2);
  width: calc(var(--current-size) * 66 / 46);
  pointer-events: none;

  &--common {
    background-image: url("data:image/svg+xml,%3Csvg width='66' height='46' viewBox='0 0 66 46' fill='none' fill-opacity='0' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 35V10L20 1H45L55 10L55 35L45 45H20L11 35Z' fill='black' stroke='url(%23paint0_linear_90_115557)' stroke-width='2'/%3E%3Cpath d='M14 15V14L13 14L3 22L2 23L2 24L13 32L14 33V31V15Z' fill='%23B2B2B2' fill-opacity='1' stroke='black' stroke-width='1.5'/%3E%3Cpath d='M52 31V32L53 32L63 24L64 23L63 22L53 14L52 13V15V31Z' fill='%23B2B2B2' fill-opacity='1' stroke='black' stroke-width='1.5'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_90_115557' x1='33' y1='0' x2='33' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23#{talent.bake_common(#B2B2B2)}'/%3E%3Cstop offset='1' stop-color='%23#{talent.bake_common(#B2B2B2)}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  }
  &--uncommon {
    background-image: url("data:image/svg+xml,%3Csvg width='66' height='46' viewBox='0 0 66 46' fill='none' fill-opacity='0' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 35V10L20 1H45L55 10L55 35L45 45H20L11 35Z' fill='black' stroke='url(%23paint0_linear_90_115557)' stroke-width='2'/%3E%3Cpath d='M14 15V14L13 14L3 22L2 23L2 24L13 32L14 33V31V15Z' fill='%233EC82C' fill-opacity='1' stroke='black' stroke-width='1.5'/%3E%3Cpath d='M52 31V32L53 32L63 24L64 23L63 22L53 14L52 13V15V31Z' fill='%233EC82C' fill-opacity='1' stroke='black' stroke-width='1.5'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_90_115557' x1='33' y1='0' x2='33' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23#{talent.bake_uncommon(#3EC82C)}'/%3E%3Cstop offset='1' stop-color='%23#{talent.bake_uncommon(#3EC82C)}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  }
  &--rare {
    background-image: url("data:image/svg+xml,%3Csvg width='66' height='46' viewBox='0 0 66 46' fill='none' fill-opacity='0' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 35V10L20 1H45L55 10L55 35L45 45H20L11 35Z' fill='black' stroke='url(%23paint0_linear_90_115557)' stroke-width='2'/%3E%3Cpath d='M14 15V14L13 14L3 22L2 23L2 24L13 32L14 33V31V15Z' fill='%230061DC' fill-opacity='1' stroke='black' stroke-width='1.5'/%3E%3Cpath d='M52 31V32L53 32L63 24L64 23L63 22L53 14L52 13V15V31Z' fill='%230061DC' fill-opacity='1' stroke='black' stroke-width='1.5'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_90_115557' x1='33' y1='0' x2='33' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23#{talent.bake_rare(#0061DC)}'/%3E%3Cstop offset='1' stop-color='%23#{talent.bake_rare(#1C7FFF)}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  }
  &--epic {
    background-image: url("data:image/svg+xml,%3Csvg width='66' height='46' viewBox='0 0 66 46' fill='none' fill-opacity='0' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 35V10L20 1H45L55 10L55 35L45 45H20L11 35Z' fill='black' stroke='url(%23paint0_linear_90_115557)' stroke-width='2'/%3E%3Cpath d='M14 15V14L13 14L3 22L2 23L2 24L13 32L14 33V31V15Z' fill='%239127D9' fill-opacity='1' stroke='black' stroke-width='1.5'/%3E%3Cpath d='M52 31V32L53 32L63 24L64 23L63 22L53 14L52 13V15V31Z' fill='%239127D9' fill-opacity='1' stroke='black' stroke-width='1.5'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_90_115557' x1='33' y1='0' x2='33' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23#{talent.bake_epic(#9127D9)}'/%3E%3Cstop offset='1' stop-color='%23#{talent.bake_epic(#9B44D7)}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  }
  &--legendary {
    background-image: url("data:image/svg+xml,%3Csvg width='66' height='46' viewBox='0 0 66 46' fill='none' fill-opacity='0' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 35V10L20 1H45L55 10L55 35L45 45H20L11 35Z' fill='black' stroke='url(%23paint0_linear_90_115557)' stroke-width='2'/%3E%3Cpath d='M14 15V14L13 14L3 22L2 23L2 24L13 32L14 33V31V15Z' fill='%23FF8000' fill-opacity='1' stroke='black' stroke-width='1.5'/%3E%3Cpath d='M52 31V32L53 32L63 24L64 23L63 22L53 14L52 13V15V31Z' fill='%23FF8000' fill-opacity='1' stroke='black' stroke-width='1.5'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_90_115557' x1='33' y1='0' x2='33' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23#{talent.bake_legendary(#FF8000)}'/%3E%3Cstop offset='1' stop-color='%23#{talent.bake_legendary(#FF8000)}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  }
  &--artifact {
    background-image: url("data:image/svg+xml,%3Csvg width='66' height='46' viewBox='0 0 66 46' fill='none' fill-opacity='0' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 35V10L20 1H45L55 10L55 35L45 45H20L11 35Z' fill='black' stroke='url(%23paint0_linear_90_115557)' stroke-width='2'/%3E%3Cpath d='M14 15V14L13 14L3 22L2 23L2 24L13 32L14 33V31V15Z' fill='%23E268A8' fill-opacity='1' stroke='black' stroke-width='1.5'/%3E%3Cpath d='M52 31V32L53 32L63 24L64 23L63 22L53 14L52 13V15V31Z' fill='%23E268A8' fill-opacity='1' stroke='black' stroke-width='1.5'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_90_115557' x1='33' y1='0' x2='33' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23#{talent.bake_artifact(#E268A8)}'/%3E%3Cstop offset='1' stop-color='%23#{talent.bake_artifact(#E268A8)}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  }
}

.talent-tree-node-choice__tooltip {
  --current-size: var(--talent-tree-node-width, #{$talent-tree-node-default-size});
  background-color: transparent !important;
  border: none !important;
  margin-top: 0 !important;
  padding: 0 !important;
  z-index: 999;

  .tooltip--arrow {
    display: none;
  }

  &--body {
    display: flex;

    a:not(:first-of-type) {
      margin-left: calc(var(--current-size) / 20);
    }
  }

  &--body-heatmap {
    gap: 14px;
  }
}
