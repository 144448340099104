.dark-striped-table {
  width: 100%;
  @include body-text;
  border: 1px solid $light-gray;

  thead {
    background-color: $black;
  }

  tbody {
    tr:nth-child(2n + 1) {
      background-color: $darker-blue;
    }

    tr:nth-child(2n) {
      background-color: $off-black;
    }

    tr:hover {
      background-color: $gray;
    }
  }

  th, td {
    padding: $smaller-gap;
    text-align: center;
  }
}