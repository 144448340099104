.enhanced-markdown-code-title {
  background-color: $closer-background;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  padding: $smaller-gap $small-gap;
  border-bottom: 1px solid $light-gray;
  font-size: pxToRem(16) !important;
  color: $faded-white-2;
  font-weight: bold;

  & + pre, & + div > pre {
    margin-top: 0 !important;
  }
}
