.stream-and-markdown-tile {
  &__content {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-gap: $content-padding;

    @include lg() {
      grid-template-columns: 3fr 4fr;
    }
  }
}
