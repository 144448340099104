.view-report-on-wipefest-tile {
  border: 1px solid $wipefest-blue;

  &__content {
    position: relative;
    text-align: center;
  }

  &__wipefest-logo {
    width: 30px;
    height: 30px;
    display: none;

    @include md {
      display: block;
    }
  }
}
