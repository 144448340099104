.header-mobile-bar {
  background: $level-0-bg;
  border-bottom: 1px solid $light-gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 $gap-8;
  height: $header-mobile-height;

  button:not(.header-mobile-bar__menu) {
    background: transparent;
    border: 0;
  }

  &__right,
  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }

  &__right {
    justify-content: flex-end;
  }

  &__menu {
    padding: $gap-8;
    background: $level-1-bg;
    border: 1px solid $level-1-border;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    margin-right: $gap-16;
    transition: background 0.24s ease-out;

    &-bar {
      width: 20px;
      height: 3px;
      background: white;

      &:nth-child(2) {
        margin-top: $gap-4;
        margin-bottom: $gap-4;
      }
    }

    &--opened {
      background: $active-400;
    }
  }

  &__search {
    font-size: 28px;
    display: flex;
    align-items: center;
    padding: 0;
    padding-top: 3px;
    color: white;
  }

  &__game-logo {
    display: block;
    padding: $gap-8;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-items: center;
  }

  &__characters {
    padding: 0px $gap-4;
    margin-left: $gap-12;
    margin-right: -$gap-4;
    height: 100%;
    display: flex;
    align-items: center;
    justify-items: center;

    &-image {
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      border: 3px solid $level-2-border;
      border-radius: 20px;
      transition: border-color 0.24s ease-out;
    }

    &--opened & {
      &-image {
        border-color: $level-border-active;
      }
    }
  }

  &__search-wrapper {
    position: fixed;
    top: calc(#{$header-mobile-height} + 1px);
    left: 0;
    width: 100%;
    height: calc(100vh - #{$header-mobile-height} - 1px);
    background: rgba(0, 0, 0, 0.9);
    z-index: 11000;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.24s ease-out;
    padding: $gap-4;
    box-sizing: border-box;

    &--visible {
      opacity: 1;
      pointer-events: all;
    }

    .header-search-select {
      max-width: none;
      width: 100%;

      .menu-select__control {
        height: 42px;
      }

      .menu-select__control .menu-select__input input {
        font-size: $button-font-size !important;
        line-height: $button-font-size !important;
        color: $white-800 !important;
      }

      .react-button {
        height: 100%;
        font-size: 25px;
      }
    }
  }

  &__not-logged {
    &-wrapper {
      position: relative;

      .zmdi {
        font-size: 32px;
        color: $white-800;
        margin-left: $gap-16;
      }
    }

    &-dropdown {
      position: absolute;
      right: 0;
      top: calc(100% + 4px);
      background: $level-2-bg;
      border: 1px solid $level-2-border;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.75);
      border-radius: $border-radius;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.14s ease-out;
      transform: translateY(15px);
      transition: opacity 0.08s ease-out, transform ease-out 0.14s;
      padding: $gap-4 0;
      margin: 0;

      &--opened {
        opacity: 1;
        pointer-events: all;
        transform: translateY(0px);
      }

      li {
        width: 100%;
        display: block;
        box-sizing: border-box;
        background: transparent;
        transition: background 0.14s ease-out;
        border-radius: $medium-border-radius;

        &:hover {
          background: $level-1-bg;

          &-item {
            color: $link-blue;
          }
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }

      &-item {
        display: block;
        font-size: $body-font-size;
        letter-spacing: 0.025em;
        color: $white-800 !important;
        transition: color 0.24s ease-out;
        white-space: nowrap;
        padding: $gap-8 $gap-32 $gap-8 $gap-16;
        margin: 0;

        .zmdi {
          font-size: $body-font-size;
          color: $white-800;
          margin-left: 0;
          margin-right: $gap-8;
          width: $gap-16;
        }
      }
    }
  }
}
