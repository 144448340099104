.guild-progress-tile {
  --guild-progress-tile-base-size: 32px;
  display: block;
  /* Ensure tile stays at 850 x 450 ratio: https://www.w3schools.com/howto/howto_css_aspect_ratio.asp */
  position: relative;
  width: 100%;
  padding-top: calc(100% * 450 / 850);
  background-size: contain;
  font-weight: bold;
  letter-spacing: 1px;
  user-select: none;
  color: inherit !important;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-image: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 110 58' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='url(%23radial_0392)'/%3E%3Cdefs%3E%3CradialGradient id='radial_0392' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(55 17) rotate(90) scale(41 55)'%3E%3Cstop stop-color='%23060606' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23060606' stop-opacity='0.98'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E");
    transform: scaleY(1.01);
    opacity: 0.8;
  }

  &--transparent {
    // TODO: When race is over, switch remove that part
    font-family: $title-font-family;
    --guild-progress-tile-base-size: 40px;
    text-shadow: 2px 2px 0 #000, -2px 2px 0 #000, 2px -2px 0 #000, -2px -2px 0 #000, 0 2px 0 #000, 0 -2px 0 #000, -2px 0 0 #000, 2px 0 0 #000, 3px 3px 0 #000, -3px 3px 0 #000, 3px -3px 0 #000, -3px -3px 0 #000, 0 3px 0 #000, 0 -3px 0 #000, -3px 0 0 #000, 3px 0 0 #000, 2px 3px 0 #000, -2px 3px 0 #000, 2px -3px 0 #000, -2px -3px 0 #000, 3px 2px 0 #000, -3px 2px 0 #000, 3px -2px 0 #000, -3px -2px 0 #000;
    &::before {
      display: none;
    }
  }

  &--transparent & {
    // TODO: When race is over, switch remove that part
    &__container {
      padding: 12px 24px;
    }
    &__progress-title,
    &__guild-name-border {
      display: none;
    }
  }

  &--not-transparent {
    overflow: hidden;
  }

  &--not-transparent & {
    // TODO: Refactor these into actual classes after Vault 10.0 Retail RWF
    &__progress {
      text-align: left;
      word-spacing: calc(var(--guild-progress-tile-base-size) * -0.125);
      white-space: nowrap;
      font-size: calc(var(--guild-progress-tile-base-size));
      letter-spacing: -0.02em;
      color: white;
      font-weight: 700;
      line-height: 80%;

      &-title {
        font-size: calc(var(--guild-progress-tile-base-size) * 0.5);
        color: $white-700;
        font-weight: 900;
        text-shadow: 0px 2px 4px #000000;
        letter-spacing: 0;
        margin-bottom: 4px;
        text-transform: uppercase;
      }
    }

    &__top-left {
      max-width: calc(100% - calc(var(--guild-progress-tile-base-size) * 2.1) - 40px);
    }

    &__guild-name {
      line-height: 101%;
    }

    &__boss-name {
      font-weight: 900;
      line-height: 101%;
      text-align: right;
      letter-spacing: 0.005em;
      color: white;
      text-shadow: 0px 2px 4px #000000;
      order: 3;
      text-transform: uppercase;
      margin-top: calc(var(--guild-progress-tile-base-size) * 0.08);
    }

    &__bottom-right {
      gap: calc(var(--guild-progress-tile-base-size) * 0.18);
      max-width: 70%;
      justify-self: flex-end;
    }

    &__best-percent,
    &__pulls {
      color: $white-700;
      font-size: calc(var(--guild-progress-tile-base-size) * 0.7);
      letter-spacing: 0.04em;
      text-shadow: 0px 2px 4px #000000;
      text-transform: uppercase;
      font-weight: 900;
      line-height: 100%;
    }

    &__no-public-stats {
      font-size: calc(var(--guild-progress-tile-base-size) * 0.53);
      line-height: 121.02%;
      letter-spacing: 0.04em;
      letter-spacing: 0.04em;
      color: $white-700;
      font-weight: 400;
      text-shadow: 0px 2px 2px #000000;
    }

    &__best-percent {
      order: 2;
    }
  }

  & > * {
    position: relative;
    z-index: 2;
  }

  &__container {
    /* Completely fill the parent div */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    padding: calc(var(--guild-progress-tile-base-size) * 0.5);
    border: 1px solid $light-gray;

    &--transparent {
      border: 0;
    }
  }

  &__top {
    height: 0;
  }

  &__bottom {
    flex: 1;
  }

  &__top,
  &__bottom {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 8px;
  }

  &__top-left {
    display: flex;
    align-items: center;
  }

  &__guild-icon {
    margin-right: calc(var(--guild-progress-tile-base-size) * 0.5);
  }

  &__guild-icon > img {
    width: calc(var(--guild-progress-tile-base-size) * 1.75);
    height: calc(var(--guild-progress-tile-base-size) * 1.75);
    display: block;
  }

  &__guild-icon--custom > img {
    border-radius: 100%;
  }

  &__guild-name {
    font-size: var(--guild-progress-tile-base-size);
    margin-bottom: calc(var(--guild-progress-tile-base-size) * 0.45);
    letter-spacing: 0.08em;
    font-weight: 900;
    color: white !important;
    position: relative;
    text-shadow: 0 2px 2px black;
    text-transform: uppercase;

    &-border {
      position: absolute;
      top: calc(100% + 6px);
      width: 100%;
      height: 6px;
      // HOW WILL THIS BEHAVE ON FF14 ??
      filter: drop-shadow(0px 2.34725px 4.69451px #000000);
      border-radius: 3px;
      display: none;

      &.faction-0 {
        background: linear-gradient(90deg, $alliance 0%, $alliance calc(100% - 56px), transparent 100%);
      }

      &.faction-1 {
        background: linear-gradient(90deg, $horde 0%, $horde calc(100% - 56px), transparent 100%);
      }
    }
  }

  &__top-right {
    position: relative;
  }

  &__rank {
    width: calc(var(--guild-progress-tile-base-size) * 2.1);
    height: calc(var(--guild-progress-tile-base-size) * 2.1);
    position: absolute;
    right: 0;
    top: 0;
    background: $level-0-bg;
    border: 1px solid $light-gray;
    border-radius: 50%;
    box-shadow: 0px 2px 4px #000000;

    & span {
      font-size: calc(var(--guild-progress-tile-base-size) * 1.44);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__site-logo {
    position: absolute;
    top: calc(var(--guild-progress-tile-base-size) * 0.125);
    right: 0;
  }

  &__bottom-left {
    font-size: var(--guild-progress-tile-base-size);
    display: grid;
    align-items: flex-end;
  }

  &__progress-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    max-width: calc(var(--guild-progress-tile-base-size) * 5);
    gap: 8px;
  }

  &__now-live {
    grid-column: span 2;
    text-align: center;
    font-size: calc(max(13px, var(--guild-progress-tile-base-size) * 0.5));
    white-space: nowrap;
    font-weight: bold;
    color: white;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);

    &-button {
      display: flex;
      align-items: center;
      gap: calc(var(--guild-progress-tile-base-size) * 0.18);
      background: $active-400;
      border-radius: 100px;
      padding: calc(var(--guild-progress-tile-base-size) * 0.1) calc(var(--guild-progress-tile-base-size) * 0.31);
    }
  }

  &__twitch-icon > img {
    display: flex;
  }

  &__twitch-icon > img {
    width: calc(var(--guild-progress-tile-base-size) * 0.57);
    height: calc(var(--guild-progress-tile-base-size) * 0.57);
  }

  &__progress {
    text-align: right;
    word-spacing: calc(var(--guild-progress-tile-base-size) * -0.125);
    white-space: nowrap;

  }

  &__difficulty_abbreviation {
    font-size: calc(var(--guild-progress-tile-base-size) * 0.7);
  }

  &__bottom-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
  }

  &__boss-name {
    font-size: var(--guild-progress-tile-base-size);
  }

  &__best-percent,
  &__pulls {
    font-size: calc(var(--guild-progress-tile-base-size) * 0.75);
  }

  &__no-logged-progress-pulls,
  &__no-public-stats {
    font-size: calc(var(--guild-progress-tile-base-size) * 0.5);
  }

  &__composition {
    display: none;

    @include sm {
      display: block;
    }
  }
}

@media (max-width: 1800px) {
  .guild-progress-tile {
    --guild-progress-tile-base-size: 32px;
  }
}

@media (max-width: 1400px) {
  .guild-progress-tile {
    --guild-progress-tile-base-size: 28px;
  }
}

@media (max-width: 1200px) {
  .guild-progress-tile {
    --guild-progress-tile-base-size: 24px;
  }
}

@media (max-width: 1100px) {
  .guild-progress-tile {
    --guild-progress-tile-base-size: 40px;
  }
}

@media (max-width: 900px) {
  .guild-progress-tile {
    --guild-progress-tile-base-size: 32px;
  }
}

@media (max-width: 700px) {
  .guild-progress-tile {
    --guild-progress-tile-base-size: 28px;
  }
}

@media (max-width: 600px) {
  .guild-progress-tile {
    --guild-progress-tile-base-size: 24px;
  }
}

@media (max-width: 500px) {
  .guild-progress-tile {
    --guild-progress-tile-base-size: 20px;
  }

  .guild-progress-tile {
    text-shadow: 1px 1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000, 0 1px 0 #000, 0 -1px 0 #000, -1px 0 0 #000, 1px 0 0 #000, 2px 2px 0 #000, -2px 2px 0 #000, 2px -2px 0 #000, -2px -2px 0 #000, 0 2px 0 #000, 0 -2px 0 #000, -2px 0 0 #000, 2px 0 0 #000, 1px 2px 0 #000, -1px 2px 0 #000, 1px -2px 0 #000, -1px -2px 0 #000, 2px 1px 0 #000, -2px 1px 0 #000, 2px -1px 0 #000, -2px -1px 0 #000;
  }
}

@media (max-width: 400px) {
  .guild-progress-tile {
    --guild-progress-tile-base-size: 16px;
  }
}

body.warcraft {
  .guild-progress-tile__guild-name-border {
    display: block !important;
  }
}
