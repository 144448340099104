@use "sass:math";

.guild-rankings-summary-box {
  &__tabs {
    display: flex;
  }

  &__tab {
    background-color: $black;
    color: $text-gray;
    padding: $smaller-gap;
    border: 1px solid $gray;
    border-bottom: none;
    border-right: none;

    &:last-child {
      border-right: 1px solid $gray;
    }

    &:hover {
      cursor: pointer;
    }

    &--selected {
      color: $white;
      cursor: default !important;
      margin-bottom: -1px;
    }
  }

  &__bar {
    background-color: $black;
    padding: $smaller-gap;
    border: 1px solid $gray;
    border-bottom: none;
  }

  &__ranks {
    background-color: $black;
    border: 1px solid $gray;
    padding: $content-padding;
    display: flex;
    height: auto;

    &--no-bar {
      padding: (math.div(1rem * 22, 16) + $smaller-gap * 2) $content-padding;
    }
  }

  &__main-rank {
    display: grid;
    text-align: center;
  }

  &__other-rank {
    display: grid;
    grid-gap: $tiny-gap;
    text-align: center;
  }

  &__main-rank {
    font-size: $subsubtitle-font-size;
  }

  &__main-rank-value {
    font-size: 2em;
  }

  &__other-ranks {
    display: flex;
    justify-content: space-evenly;
  }

  &__other-rank-value {
    font-size: $subsubtitle-font-size;
  }

  &__other-rank-label {
    font-size: $small-font-size;
  }

  &__ranks-container {
    flex: 1 1 auto;
  }

  @include sm {
    &__ranks-container + &__ranks-container {
      border-left: 1px dashed $gray;
    }
  }


  &__ranks--combined-zone {
    display: flex;
    flex-wrap: wrap;
    row-gap: $small-gap;
    justify-content: space-evenly;
    align-items: stretch;
    align-content: stretch;
    padding: $subtitle-v-padding $subtitle-v-padding $content-padding $subtitle-v-padding;
    min-width: 175px;

    @include sm {
      flex-wrap: nowrap;
    }

    .guild-rankings-summary-box {
      &__title {
        text-align: center;
        font-size: $subtitle-font-size;
        margin-bottom: $subtitle-v-padding;
      }

      &__main-rank {
        font-size: $body-font-size;
        order: 2;
      }

      &__main-rank-value {
        font-size: 2em;
        order: 3;
      }

      &__other-ranks {
        //display: block;
        flex: 1 1 auto;
      }

      &__other-rank {
        margin: 0 $tiny-gap;
      }
    }
  }
}
