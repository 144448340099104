.time-input {
  font-size: $body-font-size !important;
  background-color: $white !important;
  /* to avoid layout shifting, add a border to the valid state and adjust
   * position appropriately */
  margin-top: 0;
  border: 3px solid $black;
  height: 2.25rem;
  padding: 0 5px;
  width: 7em;
  border-radius: 7px !important;

  &--state-invalid {
    border: 3px solid $red;
  }
}
