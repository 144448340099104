.editable-schedule-preferences {
  @include body-text;

  &__display-values {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: $small-gap;
    max-width: 500px;
    align-items: center;
  }

  &__display-value {
    font-weight: bold;
    text-align: center;
  }
}