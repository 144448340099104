.alchemical-society-call-to-action {
  display: flex;
  flex-direction: row;
  margin: $small-gap;
  border-radius: 5px;
  height: 100%;
  align-items: center;
  color: $faded-white-2;

  > div {
    flex: 1 1 0;
  }

  &__logo {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  &__feature-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__feature-info {
    width: min-content;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $large-gap;

    > img {
      box-shadow: 0px 0px 5px 5px $darker-gray;
      aspect-ratio: auto;
    }
  }

  &__container {
    padding-bottom: $large-gap;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text-container {
    > * {
      text-align: center;
      text-wrap: balance;
    }

    margin: $small-gap;
  }
}
