@mixin label() {
  text-align: center;
  font-family: 'ralewayregular', Avenir, sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 20px;

  &--not-reached {
    color: gray;
  }
}

.progress-line {
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;

  &__grid {
    display: grid;
    grid-row-gap: 12px;
  }

  &__label {
    @include label();
  }

  &__cell {
    height: 32px;
    background: linear-gradient(#393740, #2a2a33);
    border-right: 2px solid #111;

    &--not-reached {
      background: black;
      border-right: 2px solid #222;
    }

    &--first {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &--last {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-right: none;
    }
  }

  &__guilds {
    padding: 0 4px;
  }

  &__guild {
    margin-bottom: 2px;
  }

  &__guild-link {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 6px;
  }

  &__list {
    display: none;
    padding: 20px 20px 0;
    justify-content: center;
    grid-template-columns: auto auto;
    grid-column-gap: 80px;
  }

  &__phase-label-container {
    display: flex;
    flex-direction: column;

    &--small {
      font-size: $body-font-size;
    }
  }

  &__phase-name-label {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $lighter-gray;
  }

  &__killed-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    &--small {
      font-size: $body-font-size;
    }
  }
}

@media (max-width: 1200px) {
  .progress-line__guilds {
    display: none;
  }

  .progress-line__list {
    display: grid;
  }
}

@media (max-width: 900px) {
  .progress-line__label {
    font-size: $body-font-size;
  }
}

@media (max-width: 700px) {
  .progress-line__label-out-of-y {
    display: none;
  }
}

@media (max-width: 550px) {
  .progress-line__list {
    grid-template-columns: 1fr auto;
  }
}

.progress-line-tooltip {
  @include label();

  &__phase-label-container {
    display: flex;
    flex-direction: column;

    &--small {
      font-size: $body-font-size;
    }
  }
}
