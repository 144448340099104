.react-tile {
  position: relative;
  border: 1px solid $light-gray;
  background-color: $dark-gray;

  &__header {
    position: relative;
    background-image: $dark-gray-gradient;
    border-bottom: 1px solid $light-gray;
    padding: $subtitle-v-padding $subtitle-h-padding;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--collapsible {
      user-select: none;

      &:hover {
        cursor: pointer;
        background-image: $dark-gray-gradient-lighter;
      }
    }

    &--collapsed {
      border-bottom: none;
    }
  }

  &__header-left {
    display: flex;
    align-items: center;

    > * {
      @include horizontal-margin-between($small-gap);
    }
  }

  &__collapse-icon-button {
    display: flex;
    justify-content: center;
  }

  &__collapse-icon {
    font-size: $title-font-size;
    transition: 0.25s ease-out;
    line-height: 0;
    transform: rotate(180deg);

    &--collapsed {
      transform: rotate(90deg);
    }

    &--with-subtitle {
      zoom: 2.5;
      margin-right: $smaller-gap;
    }
  }

  &__background-image-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__background-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__title {
    font-weight: bold;
    font-size: $subtitle-font-size;
    text-shadow: 1px 1px 1px $black;

    &--with-subtitle {
      font-size: $title-font-size;
      margin-bottom: $tiny-gap;
    }
  }

  &__subtitle {
    font-size: $body-font-size;
    color: $lighter-gray;
    text-shadow: 1px 1px 1px $black;
  }

  &__action {
    &--regular {
      @include body-text;
      @include link-looking-button;
      user-select: none;
      font-weight: bold;
      background-color: transparent;
      color: $white;
      border: none;
      padding: 0;
      display: inline;
      min-width: unset;
    }

    &:hover {
      cursor: pointer;
    }

    &:link, &:hover, &:visited {
      color: $white;
    }

    span {
      margin: 0;
      @include horizontal-margin-between($smaller-gap);
    }

    &--disabled, &--disabled:hover {
      color: $lighter-gray;
      cursor: not-allowed;
    }
  }

  &__content {
    position: relative;
    padding: $small-gap $content-padding $content-padding;
    overflow-x: auto;

    &--narrow {
      padding: $small-gap;
    }

    &--none {
      padding: 0;
    }

    &--relative {
      position: relative;
    }
  }

  &--sub-tile {
    background-color: $black !important;

    .react-tile__header {
      background-image: unset;
      background-color: $black;
      border-bottom: unset;

      &--padding-narrow {
        padding-bottom: 0;
      }
    }

    .react-tile__title {
      font-size: $subsubtitle-font-size;
    }

    .react-tile__content {
      background-color: $black;

      &--narrow {
        padding-top: $tiny-gap;
        padding-bottom: $tiny-gap;
      }
    }
  }

  &--flat {
    border: none;
    border-radius: $border-radius * 2;
    background-color: $closer-background;
    padding: $smaller-gap;

    @include md() {
      padding: $small-gap;
    }

    .react-tile {
      &__header {
        background-image: none;
        border-bottom: none;
      }

      &__title {
        font-weight: normal;
      }
    }
  }

  &--required-glow {
    box-shadow: 0 0 20px $required;
  }

  &--highlight-glow {
    @include highlight-glow;
  }

  &--with-background-image {
    .react-tile__header,
    .react-tile__title,
    .react-tile__content,
    &.react-tile--sub-tile, {
      background: transparent !important;
    }

    .react-tile__header {
      border: none;
    }

    .react-tile__action {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: $border-radius;
      padding: $tiny-gap $small-gap;
    }
  }
}
