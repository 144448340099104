.compact-composition {
  width: 100%;

  &__item-level {
    display: flex;
    align-items: center;
  }

  &__role {
    align-items: center;
  }

  &__role-players {
    align-items: center;
  }

  &__two-rows {
    display: grid;
    grid-gap: $small-gap;
  }

  &__top-row {
    display: grid;
    grid-gap: $large-gap;
    justify-content: center;
  }

  &__bottom-row {
    display: grid;
    justify-content: center;
  }

  &--with-guild-and-coach-streams {
    .compact-composition__top-row {
      grid-gap: $small-gap;
    }
  }

  &__separator {
    width: 2px;
    margin: $tiny-gap 0;
    background-color: $light-gray;
  }

  &__evenly-spaced-players {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    justify-content: center;
    justify-items: center;
  }

  &__actor-icon-for-player {
    position: relative;
  }
}

.compact-composition-tooltip {
  &__player-name {
    font-weight: bold;
  }

  &__player-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @keyframes pulse {
      0% { transform: scale(0.95); opacity: 0.9; }
      50% { transform: scale(1); opacity: 1; }
      100% { transform: scale(0.95); opacity: 0.9; }
    }

    &__streaming-indicator {
      background: #f00;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      border: 1px solid #000;
      position: absolute;
      right: -4px;
      top: -2px;
      animation: pulse 1s infinite ease-in-out;
    }
  }
}
