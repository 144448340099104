.guild-position {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  white-space: nowrap;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 6px;
  background: linear-gradient(135deg, lighten($black, 15%), $black);
}
