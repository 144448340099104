.character-recruitment-criteria-tile {
  &__factions-and-servers, &__activities-and-language {
    display: grid;
    grid-gap: $content-padding;

    @include lg {
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
    }
  }

  &__zone-filters {
    display: grid;
    grid-gap: $content-padding;

    @include lg {
      grid-template-columns: 1fr;
    }
  }
}
