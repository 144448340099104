.page-title {
  font-size: $page-title-font-size;
  font-family: $title-font-family;
  font-weight: bold;
  margin-top: 0.5em !important;
  @include text-shadow;

  &--no-top-margin {
    margin-top: 0 !important;
  }

  &--no-bottom-margin {
    margin-bottom: 0 !important;
  }
}
