.tabbed-select {
  display: flex;
  flex-direction: column;
  padding: $tiny-gap $small-gap 0 0;

  &--v2, &--rounded-square {
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0;

    @include restyled-scroll-bar();
  }

  &--v2 & {
    &__tab-label {
      &--selected {
        text-decoration: underline;
        text-underline-offset: 4px;

        @include sm {
          text-decoration: none;
        }
      }
    }
  }

  button {
    background: transparent;
    border: 0;
  }

  &--bubble {
    border-width: 1px 0 1px 0;
    border-color: $level-0-border;
    border-style: solid;
    background: $level-0-bg;
    padding: $gap-8;
  }

  &--rounded-square {
    border: 1px solid $level-1-border;
    display: inline-flex;
    padding: 0;
    border-radius: $gap-4;
    flex: 1;
  }

  &__tab {
    position: relative;
    font-size: $subsubtitle-font-size;
    padding: $tiny-gap;
    border-bottom: 2px solid transparent !important;
    user-select: none;
    cursor: pointer;
    margin-right: $large-gap;
    margin-bottom: -1px;
    color: $faded-white-1;

    i {
      margin-left: $smaller-gap;
    }

    &:disabled {
      cursor: not-allowed;
      color: $lighter-gray;
    }

    &--selected:not(&--bubble):not(&--rounded-square) {
      &:not(:disabled) {
        color: $faded-white-1;
      }
    }

    &--highlighted {
      border-bottom: 2px solid $highlight !important;
    }

    &--bubble {
      margin: 0;
      font-weight: 900;
      font-size: $smaller-font-size;
      line-height: 20px;
      letter-spacing: 0.135em;
      text-transform: uppercase;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white-600;
      text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.55);
      transition: background 0.12s ease-out, border 0.12s ease-out, color 0.24s ease-out, flex 0.31s ease-out;
      border: 1px solid transparent !important;
      box-sizing: border-box;
      flex-grow: 0.1;
      padding-top: 5px;
      border-radius: 18px;
      background-repeat: no-repeat;

      span {
        font-weight: 600 !important;
      }

      &-selected, &:focus {
        color: $white-800;
        border: 1px solid transparent !important;
        flex-grow: 1;
        background: $active-400 no-repeat !important;
      }
    }

    &--rounded-square {
      display: flex;
      flex-grow: 0;
      box-sizing: border-box;
      background: $level-1-bg no-repeat !important;
      font-size: $body-font-size;
      font-weight: 700;
      color: $white-700 !important;
      text-decoration: none !important;
      text-transform: none;
      letter-spacing: 0.5px;
      line-height: 115%;
      text-shadow: none;
      border-radius: 0;
      border: 0 !important;
      outline: none !important;
      margin: 0;
      padding: $gap-4 $gap-16;
      height: 36px;
      transition: background-color 100ms ease-in-out, color 100ms ease-in-out;
      align-items: center;

      &-selected, &:focus, &:hover {
        color: $white-800 !important;
      }

      &:focus, &:hover {
        background: $level-2-bg no-repeat !important;
      }

      &-selected {
        background: $active-200 no-repeat !important;
        border-left: 1px solid $level-1-border !important;
        border-right: 1px solid $level-1-border !important;

        &:focus, &:hover {
          background: $active-300 no-repeat !important;
        }
      }

      &:focus-visible {
        span {
          outline: 1px solid white;
        }
      }
    }

    &--v2 {
      font-size: $body-font-size;
      font-weight: 700;
      line-height: 159%;
      letter-spacing: -0.02em;
      color: $white-700 !important;
      display: flex;
      gap: $gap-8;
      align-items: center;
      justify-content: center;
      padding: 6px $gap-12;
      flex: 0 0 auto;
      margin-right: 0;
      width: max-content;
      white-space: nowrap;
      transition: background-color 100ms ease-in-out, color 100ms ease-in-out;

      &-selected, &:focus, &:hover {
        color: $white-800 !important;
      }

      &:focus, &:hover {
        @include md {
          background: $level-2-bg;
        }
      }

      &-selected {
        @include md {
          background: $active-200;

          &:focus, &:hover {
            background: $active-300;
          }
        }
      }

      @include md {
        flex: 1 1 auto;
      }

      &-vertical {
        border: 1px solid $level-0-border !important;
        background: $dark-gray-gradient !important;
        color: $text-white !important;
        font-weight: normal;
        width: 100%;
        justify-content: left;
      }

      &-vertical.tabbed-select__tab--v2-selected {
        color: $white-900 !important;
        border-right: 2px solid $white-900 !important;
      }

      &-vertical:not(.tabbed-select__tab--v2-selected) {
        padding-right: calc($small-gap + 1px);
      }

      &-vertical:hover {
        color: $white-900 !important;
      }
    }

    &--default {
      &-selected, &:focus, &:hover {
        color: $white !important;
      }

      &::after {
        @include sm {
          position: absolute;
          left: 0;
          bottom: -2px;
          display: block;
          content: '';
          border-bottom: 2px solid $white !important;
          transform: scaleX(0);
          transition: transform 100ms ease-in-out;
          width: 100%;
        }
      }

      &-selected::after {
        transform: scaleX(1);
      }
    }

    &--default, &--v2 {
      transition: color 100ms ease-in-out;
    }
  }

  &__tab-icon {
    width: 26px;
    height: 26px;
    border-radius: 26px;
    border: 1px solid $white-600;
    filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.35));
  }

  &__tab-label {
    position: relative;
  }

  &__tab-badge-label {
    position: absolute;
    top: -8px;
    right: -16px;
    background-color: $red;
    border-radius: $border-radius;
    padding: 1px 4px;
    font-size: $smaller-font-size;
  }

  &--short {
    flex-direction: row;
    border-bottom: 1px solid $gray;
  }

  @include sm {
    &:not(&--vertical) {
      flex-direction: row;
      border-bottom: 1px solid $gray;
    }
  }

  &__bullet-point {
    display: inline;
    margin-top: -2px;

    @include sm {
      display: none;
    }
  }

  &--short & {
    &__bullet-point {
      display: none;
    }
  }

  &__alchemicalSocietyIcon {
    display: inline-flex;
    width: 24px;
    margin-right: $tiny-gap;

    > img {
      position: absolute;
      bottom: 0;
    }
  }

  &--vertical:last-child {
    border-bottom: 1px solid $gray;
  }
}

.tabbed-select--bubble.tabbed-select--level-1 {
  border-color: $level-1-border;
  background: $level-1-bg;
}

.tabbed-select--bubble.tabbed-select--level-2 {
  border-color: $level-2-border;
  background: $level-2-bg;
}
