.character-profile-recruitment-tile {
  border: 1px solid $light-gray;

  &__title {
    display: flex;
    justify-content: space-between;
    background-color: #201020;
    padding: $tiny-gap $smaller-gap;
    border-bottom: 1px solid $light-gray;
  }

  &__title:only-child {
    border-bottom:none;
  }

  &__title-left {
    font-weight: bold;
  }

  &__last-updated-value, &__lfg-value {
    color: $lighter-gray;
  }

  &__update-link {
    font-style: normal;
  }

  &__content {
    background-color: $black;
    padding: $small-gap;
  }

  &__infos {
    display: grid;
    grid-row-gap: $smaller-gap;
    grid-column-gap: $content-padding;
    align-self: center;

    &--two-columns {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__subtitle-and-info {
    &--full-width {
      grid-column: 1 / span 2;
    }
  }

  &__subtitle {
    font-weight: bold;
    margin-bottom: $tiny-gap;
  }

  &__info {
    margin-left: $content-padding;
  }
}
