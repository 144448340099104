@keyframes header-fade-in {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.header {
  &__background-fader {
    position: fixed;
    z-index: 10500;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:$black;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.12s ease-out;

    &--visible {
      opacity: 0.88;
    }
  }

  &__desktop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--header-desktop-height);
    z-index: 11000;
    display: none;
    background: $level-0-bg;

    @include md {
      display: initial;
    }
  }

  &__mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $header-mobile-height;
    z-index: 11000;
    display: block;
    max-width: 100vw;

    @include md {
      display: none;
      max-width: none;
    }
  }

  &__menu-wrapper {
    position: fixed;
    max-height: calc(100vh - var(--header-desktop-height));
    overflow-y: auto;
    display: block;
    animation: header-fade-in 160ms ease-out;
    border: solid $level-0-border;
    border-width: 0px;
    box-shadow: 0px 0px 12px $black;
    z-index: 10999;
    max-width: 100vw;
    top: $header-mobile-height;
    height: calc(100% - #{$header-mobile-height});
    width: 100%;
    background: $level-0-bg;
    scrollbar-width: thin;
    scrollbar-color: $light-gray $cms-background-color;

    &--user {
      right: 0px;
    }

    &--content {
      background: $level-0-bg;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      height: 1px;
      left: 0;
      width: 100%;
      background: $level-0-border;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background: $cms-background-color;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $light-gray;
      border-radius: 3px;
    }

    @include md {
      min-width: 430px;
      top: var(--header-desktop-height);
      width: auto;
      background: $level-0-bg;
      height: auto;
      border-width: 0px 1px 0px 1px;
    }

    @media (max-width: #{$screen-md-min}) {
      left: 0 !important;
    }
  }
}
