.download-button {
  display: inline-flex;

  &__icon {
    display: inline-flex;
    justify-content: center;
  }

  &__label {
    margin-left: 0.5rem;
  }
}
