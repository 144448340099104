.guild-progress-encounter-pulls-embed {
  display: grid;
  height: 100%;

  &__content {
    position: relative;
    display: flex;
    border: 1px solid $light-gray;
  }
}
