.guild-and-coach-stream-icons {
  display: grid;
  grid-gap: $smaller-gap;
  align-items: center;

  &__stream-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @keyframes pulse {
      0% { transform: scale(0.95); opacity: 0.9; }
      50% { transform: scale(1); opacity: 1; }
      100% { transform: scale(0.95); opacity: 0.9; }
    }

    &__streaming-indicator {
      display: none;
      background: #f00;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      border: 1px solid #000;
      position: absolute;
      right: -2px;
      top: -2px;
      animation: pulse 1s infinite ease-in-out;
    }

    &__twitch-logo {
      width: 20px;
      height: 20px;
      vertical-align: text-bottom;
      margin: 0 0.25rem;
    }

    &__channel_name {
      color: lighten($twitch-purple, 10);
      font-weight: 800;
    }
  }
}

.compact-composition--show-stream-indicators {
  .guild-and-coach-stream-icons__stream-icon__streaming-indicator {
    display: block;
  }
  .guild-and-coach-stream-icons__stream-icon--link {
    cursor: pointer;
  }
}