.panel {
  display: flex;
  padding: $smaller-gap $medium-gap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $small-gap;
  max-width: fit-content;
  border-radius: $medium-border-radius;
  background: rgba(241, 241, 241, 0.08);
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.31);
  align-self: center;

  &--slim {
    gap: $tiny-gap;
    padding: $tiny-gap $small-gap;
  }

  &--info {
    border: 1px solid $info;
  }

  &--warning {
    border: 1px solid $warning;
  }

  &--error {
    border: 1px solid $error;
  }

  &--success {
    border: 1px solid $success;
  }

  &__title, &__content, &__action {
    color: $white-800;
    font-size: $body-font-size;
    text-align: center;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.85);
  }

  &__title {
    font-weight: 700;
    line-height: normal;
  }

  &__content {
    line-height: 125%;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
