.editable-image-picker {
  &__label-and-buttons {
    display: flex;
    flex-direction: column;

    > * {
      @include vertical-margin-between;
    }

    @include md {
      flex-direction: row;
      justify-content: space-between;

      > * {
        @include vertical-margin-between(0);
      }
    }
  }

  &__grid-cell {
    border: 1px solid $light-gray;
    height: 80px;
    background-position: center;
    background-size: cover;

    &:hover {
      filter: brightness(1.2);
    }

    &:disabled {
      filter: grayscale(1);
      cursor: not-allowed;
    }

    &--selected {
      @include highlight-glow;
    }
  }
}
