.thin-banner {
  display: flex;
  justify-content: center;
  background-color: $dark-blue;
  padding: $tiny-gap;
  border-bottom: 1px solid $light-gray;
  text-align: center;

  .unstyled-link {
    width: 100%;
  }
}
