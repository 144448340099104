.composition-players-in-role {
  display: flex;
  flex-direction: column;

  > * {
    @include vertical-margin-between($small-gap);
  }

  &__role {
    font-size: $subsubtitle-font-size;
    display: flex;
    align-items: center;

    > * {
      @include horizontal-margin-between($smaller-gap);
    }
  }

  &__columns {
    display: flex;

    > * {
      @include horizontal-margin-between($content-padding);
    }
  }

  &__players {
    display: flex;
    flex-direction: column;

    > * {
      @include vertical-margin-between($smaller-gap);
    }
  }
}