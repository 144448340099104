@import '../../../../resources/sass/variables';

.builds-stat-priority-section {
  &__container {
    gap: 1rem 1rem;
    flex-wrap: wrap;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.25rem;

    @include sm() {
      justify-content: space-evenly;
    }

    @include md() {
      display: flex;
      gap: 1.5rem 0.5rem;
      justify-content: space-between;
    }

    &__inner {
      display: flex;

      &__chart-wrapper {
        display: flex;
        flex-direction: column;

        &__chart {
          display: flex;
          justify-content: center;
        }
      }
    }

    &__arrow {
      width: 1.5rem;
      height: 1.5rem;

      display: flex;
      align-self: center;
      margin-bottom: -1.5rem;

      @include sm {
        width: initial;
        height: initial;
        margin-bottom: -1.55rem;
      }
    }

    &__stat-box {
      text-align: center;
      box-sizing: border-box;
      font-weight: 800;
      border-radius: 0.25rem;
      position: relative;
      border: 1px solid $level-1-border;
      text-shadow: 0 2px 3px rgba(0, 0, 0, 0.55);
      display: inline-block;
      font-size: $body-font-size;
      text-transform: uppercase;

      @include sm() {
        font-size: 1.5rem;
      }

      @include md() {
        min-width: 9rem;

        &:not(:last-of-type) {
          margin-bottom: unset;
        }
      }

      background: $level-1-bg;

      &--legendary {
        background: linear-gradient(95deg, #f89737 0%, #d86c00 100%);
      }

      &--epic {
        background: linear-gradient(
          94deg,
          rgba(168, 62, 240, 0.9) 0%,
          rgba(123, 25, 190, 0.9) 100%
        );
      }

      &--rare {
        background: linear-gradient(
          93deg,
          rgba(61, 140, 241, 0.6) 0.14%,
          rgba(0, 90, 205, 0.6) 100%
        );
      }

      &__label {
        padding: 0.5rem 0.35rem;

        @include sm {
          padding: 1rem 0.8rem;
        }
      }

      &__value-wrapper {
        display: flex;
        align-self: center;
        margin-top: 2px;
        height: 22px;

        @include sm() {
          height: 28px;
        }
      }

      &__value {
        transform: translateY(-25%);
        background: linear-gradient(90deg, #141414 0%, #1e1e1e 100%);
        padding: 1px 10px;
        font-weight: bold;
        border-radius: 0.5rem;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.8);
        border: 1px solid #828282;
        white-space: nowrap;
        display: flex;
        place-self: center;

        font-size: 0.7rem;

        @include sm() {
          display: initial;
          padding: 1px 10px;
          transform: translateY(-50%);
        }

        @include md() {
          font-size: 1rem;
        }
      }
    }
  }

  .highcharts-series {
    rect {
      fill: $common;
    }

    &.priority-2 rect {
      fill: $epic;
    }

    &.priority-3 rect {
      fill: $rare;
    }
  }
}
