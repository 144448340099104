.builds-trinket-combos-section {
  &__filter {
    display: block;
    text-transform: uppercase;
    color: $white-700;
    font-size: 21px;
    font-weight: 900;
  }

  &__filter-description {
    display: block;
    margin-bottom: 8px;
  }

  &__select {
    display: table;
  }
}
