.schedule-columns {
  @include body-text;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__day {
    margin: $tiny-gap;
    width: 7rem;
  }

  &__day-name {
    height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $black;
    border: 1px solid $gray;
    border-radius: $border-radius;
    font-size: $title-font-size;
    margin-bottom: $smaller-gap;
    color: $text-gray;
  }

  &__time-range {
    display: flex;
    flex-direction: column;
    margin-bottom: $smaller-gap;
  }

  &__time-range-start-container, &__time-range-duration-container {
    text-align: center;
    padding: $tiny-gap;
  }

  &__time-range-start, &__time-range-duration {
    background-color: $black;
    color: $off-white;
    border: 1px solid $gray;
    padding: $tiny-gap $small-gap;
  }
}