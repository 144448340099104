.guild-name-server-region-title {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $tiny-gap;

  &__name, &__server, &__region {
    @include body-text;
    font-weight: bold;
  }

  &__name {
    font-size: $small-title-font-size;
  }
}