.builds-banner {
  display: flex;
  justify-content: space-between;
  background: $level-0-bg-active;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.8);
  border: 1px solid $level-2-border;
  position: relative;
  flex-direction: column;
  margin: 1.25rem 0;
  overflow: hidden;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  &--rankings {
    margin: 10px;
  }

  @include lg {
    flex-direction: row;
  }

  @mixin bg {
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-size: cover;
    background-image: var(--bg);
    opacity: 0.5;
    filter: blur(2px);
    padding: 5px;
    margin: -5px;

    @include lg {
      background-size: contain;
    }

    @include xl {
      opacity: 0.75;
    }
  }

  &__left,
  &__right {
    display: flex;
    flex: 1;

    text-align: center;

    @include lg {
      text-align: initial;
    }

    &-content {
      display: flex;
      flex-direction: column;

      padding: 0.5rem 0;
      position: relative;
      z-index: 1;
      width: 100%;

      @include lg {
        width: unset;
      }

      &-subtitle {
        color: $white-700;
        font-size: 14px;
        font-weight: 400;

        @include sm {
          font-size: $smaller-body-font-size;
        }
      }
    }

    &-link {
      font-size: 15px;
      font-weight: bolder;

      @include sm {
        font-size: $body-font-size;
      }

      .zmdi {
        margin-left: 0.25rem;
      }
    }
  }

  &__left {
    &::before {
      @include bg;
    }

    &--blurred::before {
      @include lg {
        background-size: 20%;
        filter: blur(2px);
      }
    }

    &-content {
      @include lg {
        padding-left: 15%;
      }

      @include xl {
        padding-left: 30%;
      }
    }
  }

  &__right {
    display: none;
    justify-content: flex-end;

    @include md {
      display: flex;
    }

    @include lg {
      text-align: right;
    }

    &::before {
      @include lg {
        @include bg;
        background-position-x: right;
      }
    }

    &-content {
      @include lg {
        padding-right: 15%;
      }

      @include xl {
        padding-right: 30%;
      }
    }
  }

  &__center {
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem 0;
    max-width: 20%;
    z-index: 1;
    align-self: center;

    @include md {
      display: flex;
    }

    img {
      height: 25px;
      width: 25px;
    }
  }

  &__title {
    color: $white-900;
    font-size: 15px;
    font-weight: 900;
    letter-spacing: 0.35px;
    text-transform: uppercase;

    @include sm {
      font-size: $body-font-size;
    }
  }
}
