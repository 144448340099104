.error-box {
  display: flex;
  justify-content: center;
  padding: $content-padding;
  @include body-text;
  background-color: $faded-red;
  border: 1px solid $dark-red;
  border-radius: $border-radius;
  user-select: text;
  word-break: break-word;

  &__copy-button {
    margin-left: 0.5rem;
  }
}
