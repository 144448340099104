.a52596fdd {
  display: flex;
  justify-content: center;
  overflow: hidden;
  pointer-events: auto;
  background: $level-0-bg;

  &--no-background {
    background: transparent;
  }

  &--rounded {
    border-radius: $border-radius;
  }

  // AdPlacementLocation
  // NOTE: Should be in sync with archon/components/ArchonAdPlacement/ArchonAdPlacement.module.scss
  &--sideRailNarrow {
    width: 160px;
    height: 600px;
  }
  &--sideRailWide {
    width: 300px;
    height: 600px;
    justify-content: center;
  }
  &--characterGear {
    width: 336px;
    height: 280px;
  }
  &--cornerVideo, &--richMedia {
    // Required for ad placements to be treated as visible
    width: 1px;
    height: 1px;
  }
  &--stickyFooter {
    // Mobile Sizes
    min-width: 300px;
    min-height: 50px;
    max-height: 100px;
    @media (min-width: 600px) {
      // Tablet+
      height: 90px;
      min-width: 728px;
    }
  }
  &--leaderboardTall {
    width: 970px;
    height: 250px;
  }
}
