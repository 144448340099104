.collapsible-markdown {
  &__text {
    &--only-leading-paragraph {
      @include body-text;
      max-height: 2.8rem;
      overflow-y: hidden;
      mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
      cursor: pointer;
    }

    &--full-description {
      overflow-wrap: anywhere;
    }
  }

  &__control {
    text-align: center;
    margin-bottom: -0.6rem;

    &--full-description {
      margin-top: $smaller-gap;
    }
  }
}
