.a52596fdd-venatus {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  justify-content: center;

  &--desktop {
    display: none;
  }

  @include sm {
    &--mobile {
      display: none;
    }
    &--desktop {
      display: flex;
    }
  }
}
