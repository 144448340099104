.editable-recruitment-spot {
  @include body-text;

  > * {
    overflow: visible;
  }

  &__description {
    margin: 0 0 $large-gap 0;
  }

  &__editable-specs {
    margin-top: $large-gap;
  }

  &__textarea-container {
    min-height: 5rem !important;
  }

  &__quantity {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: $large-gap 0 0 0;
  }

  &__quantity-label {
    margin-right: $smaller-gap;
  }

  &__quantity-input {
    margin-right: $smaller-gap;
  }

  &__any-amount-label {
    user-select: none;
  }

  &__quantity-view-message {
    margin-top: $small-gap;
  }

  &__delete-box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &__delete-confirmation-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $large-gap;
    background-color: $dark-gray;
    border-radius: 10px;
    z-index: 1;
  }

  &__delete-confirmation-box-label {
    margin: $smaller-gap;
  }

  &__delete-confirmation-box-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    margin: $smaller-gap;

    @include md {
      flex-direction: row;

      > button {
        margin: 0;
      }
    }

    > button {
      margin: $tiny-gap;
    }
  }
}
