.builds-tier-list-section {
  // This is a hack but also the easiest way to make this a little more compact
  zoom: 0.95;

  &__label {
    margin-bottom: 0 !important;

    .icon {
      white-space: unset;

      &__image {
        display: none;

        @include md {
          display: initial;
        }
      }

      &__label {
        margin-left: 0;

        @include md {
          margin-left: var(--label-margin-left);
        }

        &--large {
          font-size: inherit;
          letter-spacing: inherit;
        }
      }
    }
  }

  &__tiers {
    display: flex;
    flex-direction: column;
    gap: $tiny-gap;
  }

  &__tier {
    display: flex;
    width: 100%;
    align-items: stretch;
    gap: $tiny-gap;

    &--multiple-entries {
      @include md {
        display: grid;
        grid-template-columns: auto 2fr 1fr 1fr;
      }
    }
  }

  &__tier-heading {
    display: flex;
    width: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius;

    @include sm {
      width: 88px;
    }

    h3 {
      font-size: 48px;
      font-weight: 900;
      align-self: stretch;
      text-align: center;
      line-height: normal;
      margin: 0 0 3px;

      @include sm {
        font-size: 64px;
      }
    }

    &--compact {
      width: 60px;

      h3 {
        font-size: 48px;
      }
    }
  }

  &__specs {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    padding: 14px;
    gap: 18px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: $border-radius;
    border: 1px solid $level-0-border;
    background: $level-0-bg;

    &--hide-below-md {
      display: none;

      @include md {
        display: flex;
      }
    }
  }

  &__spec {
    list-style: none;
    animation-name: brightness-transition-out;
    animation-duration: 100ms;
    animation-timing-function: ease-in-out;
    line-height: 150% !important;

    &:hover {
      animation-name: brightness-transition-in;
      filter: brightness(1.1);
    }
  }

  &__spec-contents {
    display: flex;
    padding: 2px;
    justify-content: center;
    align-items: center;
    gap: 7px;
    border-radius: 27px;
    border-width: 2px;
    border-style: solid;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.80);

    @include sm {
      padding: 2px 14px 2px 2px;
    }

    &--compact {
      padding: 0;
    }
  }

  &__spec-icon {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.80);
  }

  &__spec-value-and-label {
    min-width: 64px;
    display: none;
    padding: 0 20px 0 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: -3px;
    color: $white-800;
    text-transform: uppercase;

    @include sm() {
      display: flex;
    }

    &--compact {
      display: none;
    }
  }

  &__spec-value {
    font-size: 21px;
    font-weight: 900;
    letter-spacing: -0.945px;

    &--no-label {
      margin-bottom: 3px;
    }

    &--no-value {
      text-transform: none;
      letter-spacing: 0;
    }
  }

  &__spec-label {
    color: $white-800;
    font-weight: 700;
    letter-spacing: -0.765px;
  }

  &__metric-description {
    color: $white-700;
  }
}

@keyframes brightness-transition-in {
  from {
    filter: brightness(1.0);
  }
  to {
    filter: brightness(1.1);
  }
}

@keyframes brightness-transition-out {
  from {
    filter: brightness(1.1);
  }
  to {
    filter: brightness(1.0);
  }
}
