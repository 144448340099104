.guild-logo {
  --size: 96;
  width: calc(var(--size) * 1px);
  height: calc(var(--size) * 1px);

  &--custom {
    border-radius: 100%;
  }

  &--non-custom {
    border-radius: 0;
  }
}
