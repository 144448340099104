.ff-progress-race-summary-tile {

  &__container {
    display: grid;
    width: 100%;

    @include md {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: $small-gap;
    }
  }

  &__race-info {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 20px;

    @include md {
      margin-top: 0;
    }
  }

  &__mogtalk-icon {
    height: 80px;
    margin-left: 20px;

    @include xl {
      margin-left: 0;
      margin-top: 10px;
    }
  }

  &__powered-by {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    overflow: hidden;
    margin-top: 2%;

    @include xl {
      flex-direction: column;
    }
  }

  &__race-table {
    position: relative;
  }

  &__race-table-no-entries {
    text-align: center;
    padding: 20px 0;
    border: solid $light-gray;
    border-width: 0 1px 1px 1px;
    cursor: default;
    height: 28px;
  }

  &__race-table-entry {
    border: solid $light-gray;
    border-width: 0 1px 1px 1px;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 1fr 1fr 1fr 1fr 1fr;
    text-align: center;
    padding: 2px 0;
    cursor: default;
    background-color: $black;

    > div {
      display: flex;
      align-items: center;
      justify-items: center;
      justify-content: center;
      align-content: center;
    }

    &--header {
      background-color: $gray;
      border: 1px solid #444;
      font-weight: bold;
    }

    &--even {
      background-color: $dark-gray;
    }
  }

  &__race-table-entry:hover {
    background-color: $gray;
  }

  &__twitch-link {
    height: 15px;
  }
  &__loading-spinner {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 24px);
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__update-timer {
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 5px;
  }

  &__rank {
    font-weight: bold;
  }
}
