.report-component-dashboard {
  width: 100%;
  box-sizing: border-box;

  .react-tile__content {
    min-height: 300px;
    overflow: hidden;
  }

  &__help {
    text-align: center;
  }

  &__title {
    &--is-being-edited {
      font-weight: normal !important;
    }
  }

  &__empty {
    height: 300px;
    @include body-text;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $smaller-gap;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: $small-gap;
    justify-content: space-between;
  }

  &__component-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: $small-gap;
    align-items: center;
  }

  &__cell {
    position: relative;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background: $cms-background-color;
    }
    &::-webkit-scrollbar-thumb {
      border: 3px solid transparent;
      background-clip: padding-box;
      border-radius: 7px;
      background-color: #555555;
    }

    &--is-being-edited {
      user-select: none;
      border: 1px solid $gray;
      overflow-y: hidden;
    }
  }

  &__cell-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: move;
    background: rgba(255, 255, 255, 0);
    border: 1px solid transparent;
    transition: 0.1s;

    &:active {
      cursor: grabbing;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid $white;

      + .report-component-dashboard__cell-buttons {
        display: flex;
      }
    }
  }

  &__cell-buttons {
    display: none;
    position: absolute;
    top: $smaller-gap;
    right: $smaller-gap;
    gap: $smaller-gap;

    &:hover {
      display: flex;
    }
  }

  // this allows the Monaco tooltip to render past the tile boundaries during editing.
  &__cell-edited .react-tile__content {
    overflow: unset;
  }
}

// From 'react-grid-layout/css/styles.css'

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item img {
  pointer-events: none;
  user-select: none;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: $link-blue;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid $white;
  border-bottom: 2px solid $white;
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

// From 'react-resizable/css/styles.css'

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}


// From 'react18-json-view/src/style.css'
.json-view {
	display: block;
	color: #4d4d4d;
	text-align: left;
	--json-property: #009033;
	--json-index: #676dff;
	--json-number: #676dff;
	--json-string: #b2762e;
	--json-boolean: #dc155e;
	--json-null: #dc155e;
}
.json-view .json-view--property {
	color: var(--json-property);
}
.json-view .json-view--index {
	color: var(--json-index);
}
.json-view .json-view--number {
	color: var(--json-number);
}
.json-view .json-view--string {
	color: var(--json-string);
}
.json-view .json-view--boolean {
	color: var(--json-boolean);
}
.json-view .json-view--null {
	color: var(--json-null);
}

.json-view .jv-indent {
	padding-left: 1em;
}
.json-view .jv-chevron {
	display: inline-block;
	vertical-align: -20%;
	cursor: pointer;
	opacity: 0.4;
	width: 1em;
	height: 1em;
}
:is(.json-view .jv-chevron:hover, .json-view .jv-size:hover + .jv-chevron) {
	opacity: 0.8;
}
.json-view .jv-size {
	cursor: pointer;
	opacity: 0.4;
	font-size: 0.875em;
	font-style: italic;
	margin-left: 0.5em;
	vertical-align: -5%;
	line-height: 1;
}

.json-view :is(.json-view--copy, .json-view--edit),
.json-view .json-view--link svg {
	display: none;
	width: 1em;
	height: 1em;
	margin-left: 0.25em;
	cursor: pointer;
}

.json-view .json-view--input {
	width: 120px;
	margin-left: 0.25em;
	border-radius: 4px;
	border: 1px solid currentColor;
	padding: 0px 4px;
	font-size: 87.5%;
	line-height: 1.25;
	background: transparent;
}
.json-view .json-view--deleting {
	outline: 1px solid #da0000;
	background-color: #da000011;
	text-decoration-line: line-through;
}

:is(.json-view:hover, .json-view--pair:hover) > :is(.json-view--copy, .json-view--edit),
:is(.json-view:hover, .json-view--pair:hover) > .json-view--link svg {
	display: inline-block;
}

.json-view .jv-button {
	background: transparent;
	outline: none;
	border: none;
	cursor: pointer;
}
.json-view .cursor-pointer {
	cursor: pointer;
}

.json-view svg {
	vertical-align: -10%;
}
.jv-size-chevron ~ svg {
	vertical-align: -16%;
}

/* Themes */
.json-view_a11y {
	color: #545454;
	--json-property: #aa5d00;
	--json-index: #007299;
	--json-number: #007299;
	--json-string: #008000;
	--json-boolean: #d91e18;
	--json-null: #d91e18;
}
.json-view_github {
	color: #005cc5;
	--json-property: #005cc5;
	--json-index: #005cc5;
	--json-number: #005cc5;
	--json-string: #032f62;
	--json-boolean: #005cc5;
	--json-null: #005cc5;
}
.json-view_vscode {
	color: #005cc5;
	--json-property: #0451a5;
	--json-index: #0000ff;
	--json-number: #0000ff;
	--json-string: #a31515;
	--json-boolean: #0000ff;
	--json-null: #0000ff;
}
.json-view_atom {
	color: #383a42;
	--json-property: #e45649;
	--json-index: #986801;
	--json-number: #986801;
	--json-string: #50a14f;
	--json-boolean: #0184bc;
	--json-null: #0184bc;
}
.json-view_winter-is-coming {
	color: #0431fa;
	--json-property: #3a9685;
	--json-index: #ae408b;
	--json-number: #ae408b;
	--json-string: #8123a9;
	--json-boolean: #0184bc;
	--json-null: #0184bc;
}
