.ability-icon {
  &__tooltip {
    max-width: 450px;

    &--icon {
      height: 3rem;
      margin-right: $smaller-gap;
    }

    &--description {
      &__content {
        white-space: pre-wrap;
      }
    }

    > tbody {
      vertical-align: top;
    }
  }
}
