.zone-speed-clear-rank-filter {
  @include body-text;

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: $large-gap;

    @include sm {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
    "scope difficulty size"
    "slider slider slider";

      &__text-field {
        width: 50%;
      }
    }

    @include md {
      column-gap: $large-gap;
    }
  }

  &__field {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    column-gap: $small-gap;

    @include sm {
      grid-template-columns: 0.6fr 1.4fr;

      &--slider {
        grid-area: slider;
      }
    }
  }

  &__select-field {
    @include md {
      width: 90%;
    }
  }

  &__field-label {
    @include sm {
      text-align: right;
    }
  }

  &__filter-name {
    font-weight: bold;
    margin: $small-gap 0;

    @include body-text;

    @include sm {
      margin-left: $large-gap;
    }
  }

  &__header {
    margin: $small-gap 0;
  }

  &__scope-summary {
    margin: $small-gap 0 0 0;
    @include sm {
      margin-left: $large-gap;
    }
  }
}
