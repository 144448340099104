.footer-links-list {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: pxToRem(80);
    margin-bottom: $gap-12;

    &:first-of-type {
      margin-left: 0px;
    }

    @media (max-width: 980px) {
      & {
        margin: 0 !important;
        width: 50%;

        &:nth-child(1), &:nth-child(2) {
          margin-bottom: $gap-24;
        }
      }

      &:nth-child(1),
      &:nth-child(3) {
        align-items: flex-start;

        & .footer-links-list__links {
          align-items: flex-start;
        }
      }
    }
  }

  &__title {
    font-weight: 900;
    font-size: $smaller-font-size;
    letter-spacing: 0.145em;
    text-transform: uppercase;
    color: $white-700;
    border-bottom: 1px solid $level-2-border;
    padding-bottom: $gap-4;
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: $gap-8;
  }

  &__link {
    font-weight: 400;
    font-size: 15px;
    color: $white-800;
    margin-bottom: $gap-4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    transition: color 0.14s ease-out;

    &:hover &-text,
    &:hover &-icon-glyph {
      color: $link-blue;
    }

    &-text {
      font-weight: 400;
      font-size: 15px;
      color: $white-800;
      white-space: nowrap;
    }

    &-icon {
      width: 17.5px;
      height: 15px;
      display: block;
      object-fit: contain;
      margin-left: $gap-8;
    }

    &-icon-glyph {
      width: 17.5px;
      text-align: center;
      height: 15px;
      margin-left: $gap-8;
      color: $white-800;
    }
  }
}
