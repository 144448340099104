.palette {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: $content-padding;

  &__color {
    display: grid;
    grid-template-rows: 120px auto;
  }

  &__preview {

    border-top-left-radius: $medium-border-radius;
    border-top-right-radius: $medium-border-radius;
    border: 1px solid $gray;
    border-bottom: 0;

    &--blue {
      background-color: $blue;
    }

    &--link-blue {
      background-color: $link-blue;
    }

    &--text-white {
      background-color: $text-white;
    }

    &--default-green {
      background-color: $default-green;
    }

    &--green {
      background-color: $green;
    }

    &--light-purple {
      background-color: $light-purple;
    }

    &--orange {
      background-color: $orange;
    }

    &--highlight {
      background-color: $highlight;
    }

    &--red {
      background-color: $red;
    }

    &--dark-red {
      background-color: $dark-red;
    }

    &--faded-red {
      background-color: $faded-red;
    }

    &--input-background {
      background-color: $input-background;
    }

    &--text-gray {
      background-color: $text-gray;
    }

    &--text-dark-gray {
      background-color: $text-dark-gray;
    }

    &--white {
      background-color: $white;
    }

    &--off-white {
      background-color: $off-white;
    }

    &--lighter-gray {
      background-color: $lighter-gray;
    }

    &--light-gray {
      background-color: $light-gray;
    }

    &--gray {
      background-color: $gray;
    }

    &--slightly-dark-gray {
      background-color: $gray;
    }

    &--dark-gray {
      background-color: $dark-gray;
    }

    &--off-black {
      background-color: $off-black;
    }

    &--black {
      background-color: $black;
    }

    &--darker-blue {
      background-color: $darker-blue;
    }

    &--cms-background-color {
      background-color: $cms-background-color;
    }

    &--closer-background {
      background-color: $closer-background;
    }

    &--faded-white-1 {
      background-color: $faded-white-1;
    }

    &--faded-white-2 {
      background-color: $faded-white-2;
    }

    &--faded-white-3 {
      background-color: $faded-white-3;
    }

    &--twitch-purple {
      background-color: $twitch-purple;
    }

    &--rare {
      background-color: $rare;
    }

    &--epic {
      background-color: $epic;
    }

    &--legendary {
      background-color: $legendary;
    }

    &--encounter {
      background-color: $encounter;
    }

    &--npc {
      background-color: $npc;
    }

    &--pet {
      background-color: $pet;
    }

    &--white-900 {
      background-color: $white-900;
    }

    &--white-800 {
      background-color: $white-800;
    }

    &--white-700 {
      background-color: $white-700;
    }

    &--white-600 {
      background-color: $white-600;
    }

    &--white-500 {
      background-color: $white-500;
    }

    &--active-400 {
      background: $active-400;
    }

    &--active-300 {
      background: $active-300;
    }

    &--active-200 {
      background: $active-200;
    }

    &--level-0-bg {
      background: $level-0-bg;
    }

    &--level-1-bg {
      background: $level-1-bg;
    }

    &--level-2-bg {
      background: $level-2-bg;
    }

    &--level-0-border {
      background: $level-0-border;
    }

    &--level-1-border {
      background: $level-1-border;
    }

    &--level-2-border {
      background: $level-2-border;
    }
  }

  &__details {
    border-bottom-left-radius: $medium-border-radius;
    border-bottom-right-radius: $medium-border-radius;
    padding: $content-padding;
    background-color: $closer-background;
    border: 1px solid $gray;
    border-top: 1px solid $lighter-gray;

    h5 {
      margin-bottom: 0;
    }
  }

  &__color-codes {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
