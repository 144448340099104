.archon-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &--include-margin {
    margin-top: 40px;
    margin-bottom: 48px;
  }

  &-text {
    @include archon-style-text;
  }
}
