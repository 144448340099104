.content-and-ads {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: $smaller-gap;

  &--with-ads {
    // FIXME: Haven't been able to convince Playwire to use the narrow width
    //@include lg {
    //  grid-template-columns: 1fr 160px;
    //}
    @include xl {
      grid-template-columns: 1fr 320px;
    }
  }

  &__content {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  &__ads {
    margin: 0;
    padding: 0;
    display: none;
    @include xl {
      display: block;
    }
  }

  &__ads-sky {
    display: none;
    // FIXME: If the incantation needed to convince playwire to show a narrow
    //  ad is found, this could be set to lg to match the above grid column size.
    @include xl {
      display: block;
    }
  }

  &__ads-rect {
    display: none;
    @include xl {
      display: block;
    }
  }
}
