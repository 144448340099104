.wechat-qr-codes {
  z-index: 1000;
  position: fixed;
  left: 32px;
  bottom: 32px;

  &__content {
    display: flex;
    text-align: center;
    flex-direction: column;
    background-color: $dark-gray;

    @include md {
      flex-direction: row;
    }
  }
}
