@use '../../../sass/variables';
@use 'sass:color';
@use 'sass:string';

@function bake($color, $saturation, $lightness) {
  @return string.slice(#{color.scale($color, $saturation: $saturation, $lightness: $lightness)}, 2);
}

@function bake_common($color) {
  @return bake($color, -40%, -60%);
}

@function bake_uncommon($color) {
  @return bake($color, 0%, -50%);
}
@function bake_rare($color) { @return bake($color, 0%, -36%); }
@function bake_epic($color) { @return bake($color, 0%, -10%); }
@function bake_legendary($color) { @return bake($color, 0%, 0%); }
@function bake_artifact($color) { @return bake($color, 0%, 0%); }

.talent-tree-node {
  --current-size: var(--talent-tree-node-width, #{variables.$talent-tree-node-default-size});
  width: var(--current-size);
  height: var(--current-size);
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100%;
  position: relative;

  > img {
    width: 89%;
    height: 89%;
  }

  &--inactive {
    filter: grayscale(1);
  }

  &--common {
    & img {
      filter: saturate(0.5) brightness(0.5);
    }
    color: variables.$lighter-gray;
  }

  &--uncommon img, &--rare img {
    filter: saturate(0.8) brightness(0.7);
  }

  &--epic img {
    filter: saturate(0.95) brightness(0.95);
  }

}

:root {
  --talent-tree-node-width: #{variables.$talent-tree-node-default-size};
}
