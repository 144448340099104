.article-title {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @include md {
    height: 240px;
  }

  &--slim {
    @include md {
      height: 180px;
    }
  }

  &__fade {
    position: absolute;
    top: 180px;
    right: 0;
    bottom: -20px;
    left: 0;
    background-image: linear-gradient(to bottom,
                                      $level-0-no-gradient-transparent,
                                      $level-0-bg-no-gradient,
                                      100%);

    &--slim {
      @include md {
        top: 140px;
      }
    }
  }

  &__image-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__image {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    filter: brightness(0.55) contrast(1.3) saturate(1.5) blur(6px);
  }

  &__text {
    z-index: 1;
    font-size: 1.75rem;
    font-weight: bold;
    color: $faded-white-1;
    letter-spacing: 1px;
    text-align: center;
    padding: 2rem $small-gap 0;
    @include text-shadow-header;

    @include sm {
      font-size: 2.25rem;
    }

    @include lg {
      font-size: 3rem;
      padding-top: 0.75rem;
    }
  }
}
