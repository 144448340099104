
.header-search-select  {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 16px;
  max-width: 666px;
  flex-grow: 1;
  position: relative;
  @include md {
    transition: filter 0.14s ease-out;
  }

  &:hover {
    filter: brightness(1.15);
  }

  label {
    width: 100%;
  }
}
