.link-select__menu-list {
  overflow-x: hidden;
}

.guild-select {
  &__option {
    &.menu-select__option {
      display: flex !important;
    }

    &--icon {
      display: flex;

      img {
        width: $large-gap;
        height: $large-gap;
      }
    }

    &--label {
      padding-left: $smaller-gap;
    }
  }

  &__control {
    &--icon {
      display: flex;
      padding-left: $small-gap;

      img {
        width: $large-gap;
        height: $large-gap;
      }
    }

    .menu-select__value-container {
      &::before {
        --background: var(--faction-color);
      }
    }

    .menu-select__single-value {
      padding-left: $tiny-gap !important;
      padding-right: $tiny-gap !important;
    }
  }
}

#guild-select-selected-option .guild-select__option--label {
  color: white !important;
}

