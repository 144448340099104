.hover-box {
  &__activator {
    position: relative;
    background-color: $dark-gray;
    border: 1px $light-gray solid;
    padding: $small-gap;
    color: $off-white;
    &:hover {
      background-color: $gray;
      color: $white;

      & .hover-box__content {
        visibility: visible;
      }
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    img {
      width: 100%;
    }
  }

  &__content {
    visibility: hidden;
    position: absolute;
    border: 1px $light-gray solid;
    bottom: -1px; // offset for the parent border

    &--anchor-left {
      right: 64px;
    }

    &--anchor-right {
      left: 64px;
    }
  }
}
