@use "sass:math";

.builds-best-enchants-section__group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 4px 14px;
}

.builds-best-enchants-section__header {
  margin: $smaller-gap 0 $tiny-gap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: $body-font-size;
  font-weight: 700;
  color: $white-800;
}

.builds-best-enchants-section__header-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  flex: 1 1 auto;

  @include lg {
    flex-basis: calc(50% - 7px);
    max-width: calc(50% - 7px);
    margin: 0;
  }

  &--right {
    display: none;

    @include lg {
      display: flex;
    }
  }
}

.builds-best-enchants-section__header-column {
  box-sizing: border-box;
  flex: 1 1 auto;

  // the even repeating dom elements must be aligned right
  &:nth-child(even) {
    text-align: right;
  }

  @include lg {
    flex: 1 1 calc(50% - $tiny-gap);
    max-width: calc(50% - $tiny-gap);
  }
}

.builds-best-enchants-section__item {
  box-sizing: border-box;
  flex: 1 1 100%;
  margin: $tiny-gap 0;

  @include lg {
    flex-basis: calc(50% - 7px);
    max-width: calc(50% - 7px);
    margin: 0;
  }
}
