.social-icon__wrapper {
  margin: 0 $gap-8;
  padding: 2px;
  background: $white-800;
  border-radius: pxToRem(4);
  display: table;

  & img {
    width: 18px;
    height: 18px;
    display: block;
    object-fit: contain;
  }
}
