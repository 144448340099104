.encounter-progress-duration-table {
  &__site-logo {
    display: flex;
    justify-content: center;
    height: 50px;

    & * {
      display: none;
      padding: 4px 0;
    }
  }

  &__encounter-header {
    background-position: center;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__encounter-name, &__guild-name, &__guild-logo, &__duration {
    text-align: center;
  }

  &__encounter-name {
    width: 80px !important;
    margin-top: 1.5rem !important;
    margin-bottom: 0 !important;
    text-align: center !important;
  }

  &__encounter-name {
    font-weight: 900 !important;
    position: absolute;
    width: 100% !important;
    left: 0px;
    bottom: 0px;
    font-family: Arial, sans-serif;
    text-transform: uppercase;
    color: #ececec;
    letter-spacing: 0.14em;
    text-shadow: 0 2px 4px black !important;
  }

  &__guild-cell {
    width: 100px;
  }

  &__guild-name {
    width: 100% !important;
    margin-top: $tiny-gap !important;
  }

  &__guild-name, &__encounter-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @include text-shadow;
  }

  &__duration {
    font-size: $subtitle-font-size;
  }

  &__duration-content {
    display: flex;
    flex-direction: column;
    gap: $tiny-gap;
    padding: $tiny-gap;

    &--highlight {
      border: 2px solid $epic;
      border-radius: 2px;
    }
  }

  &--full-height {
    height: 100%;
  }

  &--embed & {
    &__site-logo {
      background-color: transparent !important;
      position: relative;
      filter: drop-shadow(0px 0px 12px black);
      height: auto;

      & * {
        display: block;
      }
    }

    &__encounter-header {
      background-color: transparent !important;
      padding: 0 !important;
      position: relative;
      filter: drop-shadow(0px 0px 12px black);
    }

    &__table-row {
      background-color: transparent !important;
      box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.8);

      & td {
        background: #0a0a0a !important;
        border-top: 1px solid #2c2c2c;
        border-bottom: 1px solid #2c2c2c;

        &:first-child {
          border-left: 1px solid #2c2c2c;
        }

        &:last-child {
          border-right: 1px solid #2c2c2c;
        }
      }

      &:first-child {
        & td {
          &:first-child {
            border-top-left-radius: 4px;
          }

          &:last-child {
            border-top-right-radius: 4px;
          }
        }
      }


      &:last-child {
        & td {
          &:first-child {
            border-bottom-left-radius: 4px;
          }

          &:last-child {
            border-bottom-right-radius: 4px;
          }
        }
      }
    }

    &__table-row:nth-child(2n) {
      & td {
        background: #141414 !important;
      }
    }

    &__guild-logo .guild-logo {
      width: 40px !important;
      height: auto !important;
    }

    &__guild-cell {
      width: 100px;
      padding: 0.4rem 0.625rem !important;
    }

    &__guild-name {
      margin-bottom: 0 !important;
    }
  }

  &__difference {
    font-size: pxToRem(16);
    color: $white-700;
  }
}
