@mixin background-box {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 7px 12px;
  border-radius: 4px;
}

@mixin no-background-box {
  background-color: initial;
  padding: initial;
  border-radius: initial;
}

.guild-header {
  background-color: $darker-blue;
  border-bottom: 1px solid $light-gray;
  padding: $tiny-gap 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: $tiny-gap;
    align-items: flex-start;
    @include fill-view-width;
    height: max-content;
    padding: 0 10px;

    @include md {
      grid-gap: $small-gap;
      align-items: center;
      padding: initial;
    }

    @include lg {
      grid-template-columns: auto 1fr minmax(360px, auto);
    }
  }

  &__infobox {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: $tiny-gap;
    justify-content: flex-start;

    @include md {
      width: max-content;
      gap: $small-gap;
    }

    .guild-logo {
      display: none;
      @include md {
        display: inherit;
      }
    }
  }

  &__name {
    font-size: $subsubtitle-font-size;
    @include md {
      font-size: $title-font-size;
      line-height: 40px; // FIXME: pixel line-heights to space these elements apart seems a bit hacky
    }
    font-weight: bold;
  }

  &__region-and-server {
    font-size: $smaller-font-size;
    @include md {
      font-size: $subtitle-font-size;
      line-height: 23px;
    }

    a {
      color: $white !important;
    }
  }

  &__type-and-parent-guild {
    font-size: $smaller-font-size;
    color: $lighter-gray;
    line-height: 29px;
  }

  &__parent-guild {
    &::before {
      @include md {
        content: ' - ';
      }
    }

    display: block;
    line-height: initial;
    @include md {
      display: initial;
      line-height: inherit;
    }
  }

  &__child-guilds {
    padding: $tiny-gap $tiny-gap 0 0;
  }

  &__links {
    width: max-content;
    justify-self: flex-end;
  }

  &__related-links {
    display: flex;
    height: min-content;
    width: max-content;
    align-content: center;
    flex-direction: column;
    gap: $smaller-gap;
  }

  &__customize-container {
    display: grid;
    gap: $smaller-gap;
    justify-items: stretch;
    align-items: center;

    .customize-button, .update-guild-button {
      font-size: $small-font-size;
      text-align: right;

      @include md {
        font-size: $body-font-size;
      }
    }
  }

  &--with-banner {
    height: 130px;
    background-position: center;
    background-size: cover;

    .customize-button, .update-guild-button {
      text-align: center;
    }

    .guild-header__infobox, .customize-button, .update-guild-button {
      @include background-box;
    }
  }
}
