.zone-filters {
  display: flex;
  justify-content: center;
  color: $text-gray;

  @include md {
    justify-content: flex-start;
  }

  &__container {
    @include md {
      display: flex;
      align-items: center;
      gap: $content-padding;
    }
  }

  &__filter {
    @include md {
      display: flex;
      align-items: center;
      gap: $smaller-gap;
    }
  }
}
