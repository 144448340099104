.user-notifications {
  @include body-text;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__actions {
    display: flex;
    gap: $large-gap;
  }

  @include sm {
    flex-direction: row;
    gap: $content-padding;
  }
}
