@use "../TalentTreeNode/TalentTreeNode.scss" as talent;
@use 'sass:color';

@mixin full-layout {
  @include lg() {
    .talent-tree--container--default-layout & {
      @content;
    }
  }
}

@mixin compact {
  .talent-tree--compact & {
    @content;
  }
}

.talent-tree {
  display: inline-block;
  position: relative;
  overflow-y: clip;

  /* separate rule to also configure choice node tooltips conveniently */
  &--container,
  &__implementation__choice-tooltip {
    --talent-tree-node-width: 1.78125rem;

    @include lg {
      --talent-tree-node-width: 2.375rem;
    }

    @include compact {
      --talent-tree-node-width: 2rem;
    }
  }

  &__implementation__hero-tooltip {
    --talent-tree-node-width: 5.34rem;

    @include lg {
      --talent-tree-node-width: 7rem;
    }

    @include compact {
      --talent-tree-node-width: 6rem;
    }

    & .talent-tree-node:hover img {
      filter: none;
    }
  }

  /* todo: find better name */
  &--container {
    display: grid;
    grid-template-rows: auto auto auto;

    --cell-dimension: 3rem;
    --multiple-points-font-size: 0.75rem;

    &--collapsed {
      max-height: 15.25rem;
    }

    @include lg() {
      &--collapsed {
        --mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 526 192'%3E%3Cpath fill='url(%23a)' d='M0-.00012207h526v192H0z'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='233.387' x2='233.387' y1='61.9999' y2='192' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='.52168' stop-color='%23fff' stop-opacity='1'/%3E%3Cstop offset='.719824' stop-color='%23fff' stop-opacity='.73'/%3E%3Cstop offset='.996478' stop-color='%23fff' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");

        -webkit-mask-image: var(--mask);
        mask-image: var(--mask);
        mask-position: bottom;
      }

      &.talent-tree--container--default-layout {
        grid-template-columns: minmax(450px, 2fr) auto minmax(450px, 2fr);
        grid-template-rows: unset;
      }

      column-gap: 1rem;
      --cell-dimension: 4rem;
      --multiple-points-font-size: 1rem;
    }
  }

  &__interactions {
    @mixin sharedButtonStyling() {
      border-radius: 2rem;
      text-align: center;
      padding: 0.25rem 1rem;
      border: 1px solid $gray;
      box-shadow: 0px 2px 10px $black;
      font-size: 0.85rem;

      i {
        margin-left: 0.5rem;
      }
    }

    $gradient: linear-gradient(90deg, #141414 0%, #1e1e1e 100%);
    $center-left: calc(
            50% - 7.5rem / 2
    ); /* the 7.5rem comes from the fixed width of the interaction buttons */
    &-export,
    &-show-more {
      position: absolute;
      z-index: 1;
    }

    &-export {
      display: flex;
      flex-direction: column;
      width: 7.5rem;
      top: 1rem;
      right: 1rem;

      &--heat-map {
        width: calc(100% - 240px);
        left: unset !important;
        right: 1em !important;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 4px;

        @include lg {
          align-items: center;
          width: calc(100% - 500px);
          max-width: 740px;
          right: 50% !important;
          transform: translateX(50%);
        }
      }

      @include lg {
        .talent-tree--container--default-layout + & {
          right: unset;
          left: $center-left;

          .talent-tree__interactions-export__external-link {
            display: block;
          }
        }
      }

      &__copy-button {
        @include sharedButtonStyling();
        cursor: pointer;
        border: 1px solid $gray;
        box-shadow: 0px 2px 10px $black;
        color: white;
        background: $gradient;

        &--copied {
          color: $green;
        }
      }

      &__external-link {
        @include sharedButtonStyling();
        color: white !important;
        background: $gradient;
        line-height: normal;
        margin-top: 0.5rem;
        display: none;
      }

      &__percent-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
      }

      &__percent-image {
        height: 34px;
        width: auto;
        display: block;
      }

      &__percent-title,
      &__percent-subtitle {
        background: linear-gradient(180deg, #f0f0f1 26.79%, #b5b5b5 78.57%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-weight: 900;
        font-size: 12px;
        line-height: 100%;
        filter: drop-shadow(0px 2px 9px rgba(0, 0, 0, 0.9));
        text-transform: uppercase;
      }

      &__percent-subtitle {
        font-size: 20px;
      }

      &__percent-disclaimer {
        font-weight: 400;
        font-size: 14px;
        line-height: 115.49%;
        color: $white-800;
        text-align: center;
        letter-spacing: 0.01em;
        display: none;

        @include lg {
          display: unset;
        }
      }

      &__outdated-revision {
        display: flex;
        background-color: $dark-gray;
        border: 1px solid #9c6532;
        border-radius: 5px;
        padding: 0.25rem 0.5rem;

        > div {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }

        &--icon {
          align-items: center;
        }

        &--title-container {
          display: flex;
          justify-content: space-between;
          padding-left: 0.5rem;
        }

        &--title {
          font-weight: 900;
          text-transform: uppercase;
        }

        &--subtitle {
          padding-left: 0.5rem;
        }

        button {
          appearance: none;
          color: white;
          opacity: 0.7;
          background: unset;
          border: none;
          cursor: pointer;
          position: relative;
          left: 0.5rem;
          top: -0.1rem;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    &-show-more {
      bottom: 1rem;
      left: $center-left;

      height: 2rem;
      width: 7.5rem;
      color: white;
      background: $active-300;
      cursor: pointer;
      @include sharedButtonStyling();

      &--expanded {
        bottom: 0.5rem;
      }
    }
  }

  &__description {
    display: flex;
    text-transform: uppercase;
    font-weight: 900;
    z-index: 1;
    text-shadow: 0 2px 2px #000;
    column-gap: 0.5rem;

    margin-top: 1rem;
    margin-left: 0;
    margin-bottom: 1rem;

    @include full-layout {
      margin-left: 1em;
      margin-bottom: unset;
    }

    .talent-tree--container--vertical-layout & {
      margin-bottom: 1rem;
    }

    &--spec {
      @include lg {
        .talent-tree--container--default-layout & {
          flex-direction: row-reverse;
          margin-left: unset;
          margin-right: 1rem;

          .talent-tree__description__label {
            text-align: right;
          }
        }
      }
    }

    &__icon {
      display: flex;
      justify-content: center;

      img {
        border-radius: 99px;
        border: 2px solid #404040;

        --size: 40;
        width: 2.5rem;
        height: 2.5rem;

        @include lg() {
          --size: 48;
          width: 3rem;
          height: 3rem;
          border-width: 2px;
        }
      }
    }

    &__label {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &--kind {
        font-size: 1.25rem;
        letter-spacing: 0.04em;
        line-height: 115%;

        @include lg() {
          font-size: 1.5rem;
        }
      }

      &--generic {
        font-size: 0.75rem;
        letter-spacing: 0.12em;
        line-height: 125%;

        @include lg() {
          font-size: 0.875rem;
        }
      }
    }
  }

  &__artwork {
    height: 10rem;
    background-repeat: no-repeat;
    position: absolute;
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
    background-position: top;
    background-size: cover;
    width: 100%;
    left: 0;
    right: 0;

    &--class {
      // TODO: this also flips the image, but our images are cropped to right-side only so this is awkward
      transform: scaleX(-1); // the mask is flipped, and this reverts it
      --mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 100 100' preserveAspectRatio='none' fill='none'%3E%3Crect width='200%25' height='200%25' x='-0%25' y='-100%25' fill='url(%23rad)'/%3E%3Cdefs%3E%3CradialGradient id='rad'%3E%3Cstop offset='80%25' stop-opacity='0.4'/%3E%3Cstop offset='100%25' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E");

      -webkit-mask-image: var(--mask);
      mask-image: var(--mask);
    }

    &--spec {
      transform: scaleX(-1); // the mask is flipped, and this reverts it
      @include lg {
        .talent-tree--container--default-layout & {
          transform: unset;
        }
      }

      --mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 100 100' preserveAspectRatio='none' fill='none'%3E%3Crect width='200%25' height='200%25' x='0%25' y='-100%25' fill='url(%23rad)'/%3E%3Cdefs%3E%3CradialGradient id='rad'%3E%3Cstop offset='80%25' stop-opacity='0.4'/%3E%3Cstop offset='100%25' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E");

      -webkit-mask-image: var(--mask);
      mask-image: var(--mask);
    }
  }

  &__container {
    position: relative;
    padding: 0 1rem;

    box-sizing: border-box;

    @include lg() {
      padding: unset;
    }

    &--hero {
      min-width: calc(var(--min-cols, 3) * var(--talent-tree-node-width));
      grid-row: 1;

      & .talent-tree__container--height-limiter {
        display: grid;
        grid-template-areas:
                "title title"
                "icon talents";
        grid-template-columns: min-content 1fr;

        @include full-layout {
          display: flex;
          flex-direction: column;
        }
      }

      @include full-layout {
        grid-row: unset;
      }
    }


    &--class, &--spec {
      padding: 0 8%;
      min-width: max(350px, calc(var(--min-cols, 3) * var(--talent-tree-node-width)));
    }

    &--spec {
      margin-top: 0.5rem;

      @include full-layout {
        margin-top: unset;
      }
    }

    /* todo: needs better name */
    &--height-limiter {
      display: flex;
      flex-direction: column;

      .talent-tree__container--hero & {
        flex-direction: row;
      }

      @include full-layout {
        &:first-of-type {
          border-right-width: 0;
        }

        &:last-of-type {
          margin-top: unset;
          border-left-width: 0;
        }
      }

      &[data-expanded='false'][data-kind='class'][data-exportable='true'] {
        max-height: 15.25rem;
      }

      .talent-tree__container--hero & {
        align-items: center;
      }
    }
  }

  &__implementation {
    display: flex;
    justify-content: center;
    max-height: 10.5rem;
    position: relative;
    align-self: center;
    width: 100% !important;
    max-width: 540px;

    &--expanded {
      max-height: unset;
    }

    .talent-tree__container--class &--expanded {
      padding-bottom: 1.5rem;
    }

    .talent-tree__container--spec &--expanded {
      /* on mobile, only the 2nd tree gets the extra padding */
      padding-bottom: 4.5rem;
    }

    @include full-layout {
      max-width: 650px;
      .talent-tree__container--hero & {
        max-width: 325px;
      }
      .talent-tree__container--class &--expanded,
      .talent-tree__container--spec &--expanded {
        padding-bottom: 5rem;
      }
    }

    &__svg {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      opacity: 0.8;
      overflow: visible; /* this fixes half a pixel being cut off from sides */
      height: calc(var(--rows) * var(--cell-dimension));

      line {
        stroke-width: 1;
        stroke: #787878;

        @include full-layout {
          stroke-width: 2;
        }
      }
    }

    &__nodes {
      width: 100%;
      position: relative;
      height: calc(var(--rows) * var(--cell-dimension));

      &--item {
        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        top: calc(var(--row) * var(--cell-dimension));
        left: var(--posX);
        // center these so they line up with the svg lines
        //
        // the trick here is that translate uses %s based on the current element size,
        // while top/left use %s based on the container size.
        transform: translate(-50%, -50%);
      }
    }
  }

  &__hero-container {
    border: 1px solid hsla(0, 0, 100%, 25%);
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 10px;
    padding: 3em;
    align-self: stretch;
    /** this max-width is based on the number of nodes in a row being at most 3 */
    max-width: calc(7 * var(--talent-tree-node-width));

    grid-area: talents;

    &--compact {
      padding: 3em 0;
      align-self: center;
      min-width: calc(2 * var(--talent-tree-node-width));
    }

    &--horizontal {
      padding: 0;
      flex-grow: 1;
      justify-self: start;
      min-width: calc(2 * var(--talent-tree-node-width) * var(--talent-count))
    }
  }

  &__hero-title {
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: 900;
    z-index: 1;
    text-shadow: 0 2px 2px #000;
    text-align: center;
    max-width: 18rem;

    grid-area: title;
    justify-self: start;
    padding-left: 1rem;

    &--horizontal {
      width: 100%;
      white-space: nowrap;
      max-width: unset;
      padding-left: 0.5rem;
    }

    &--no-nodes {
      padding-left: 0;
    }

    @include full-layout {
      justify-self: unset;
      padding-left: unset;
    }
  }

  &__hero-icon {
    display: block;
    grid-area: icon;
    position: relative;

    margin: 0 5%;
    padding: 1em;
    $size: calc(2 * var(--talent-tree-node-width));
    min-width: $size;
    min-height: $size;
    width: $size;
    height: $size;

    @include full-layout {
      $size: calc(3 * var(--talent-tree-node-width));
      min-width: $size;
      min-height: $size;
      width: unset;
      height: unset;
    }

    & .talent-tree-percentage_badge {
      bottom: 5%;
      text-align: center;
    }

    @function hero_background_image($color) {
      $startColor: talent.bake($color, 0%, -20%);
      $endColor: talent.bake(color.adjust($color, $hue: -12), 0%, -20%);
      @return url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='23' cy='23' r='22' fill='black' stroke='url(%23paint0_linear_92_115617)' stroke-width='1'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_92_115617' x1='22' y1='0' x2='22' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23#{$startColor}'/%3E%3Cstop offset='1' stop-color='%23#{$endColor}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    }

    &--wrapper {
      background-repeat: no-repeat;
      background-size: 100%;
      background-image: hero_background_image(#fffa00);
      aspect-ratio: 1 / 1;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
    }

    &--rarity- {
      &common {
        background-image: hero_background_image(#b2b2b2);
      }

      &uncommon {
        background-image: hero_background_image(#3ec82c);
      }

      &rare {
        background-image: hero_background_image(#0061dc);
      }

      &epic {
        background-image: hero_background_image(#9127d9);
      }

      &legendary {
        background-image: hero_background_image(#ff8000);
      }

      &legendary {
        background-image: hero_background_image(#e268a8);
      }
    }


    & img {
      border-radius: 50%;
      width: 92%;
      height: 92%;
    }
  }

  &__hero-spacer {
    /* TODO this should really be on a grid layout */
    height: 1rem;

    @include full-layout {
      height: 7rem;
    }
  }

}
