.guild-progress-encounter-tile {
  display: grid;
  grid-gap: $content-padding;
  overflow: hidden;

  &__row-one {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: $xlarge-gap;
    align-items: center;
  }

  &__row-two {
    display: block;

    @include md {
      display: none;
    }
  }

  &__row-three {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @include md {
      align-items: flex-end;
      flex-direction: row;
    }

    .guild-progress-encounter-tile__chart {
      flex: 1 1 auto;
      overflow: hidden;

      @include md {
        width: 0;
      }

      &--fallback-message {
        display: none;
        box-sizing: border-box;
        height: 250px;
        justify-content: center;
        align-items: center;
        background: linear-gradient(135deg, lighten($black, 15%), $black);
        border: 1px solid $gray;
        font-family: $title-font-family;
        font-size: $small-title-font-size;
        text-align: center;

        @include md {
          display: flex;
        }
      }
    }

    .guild-progress-encounter-tile__video {
      margin-top: $small-gap;

      @include md {
        width: 444px;
        height: 250px;
        margin-top: 0;
        margin-left: $tiny-gap;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    width: 58px;
    height: 58px;

    img {
      width: 100%;
      height: 100%;
    }

    &--not-yet-progressed {
      filter: grayscale(1);
      border: 1px solid $lighter-gray;
    }

    &--in-progress {
      border: 1px solid $orange;
    }

    &--complete {
      border: 1px solid $green;
    }

    &--rounded {
      border-radius: 100%;

      img {
        border-radius: 100%;
      }
    }

    &--no-border {
      border: 0;
    }
  }

  &__stats {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-around;
    align-items: center;

    @include md {
      grid-template-columns: auto auto 1fr;
      grid-gap: 5rem;
      justify-content: unset;
    }
  }

  &__message, &__pull-count {
    text-align: center;

    @include md {
      text-align: left;
    }
  }

  &__composition {
    display: none;

    @include md {
      display: block;
    }
  }

  &__show-detailed-composition {
    display: none;

    @include lg {
      display: block;
    }
  }
}
