.archon-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: #00000099;

  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    background: $dark-gray-gradient;
    border-radius: $border-radius;
    border: 1px solid $level-0-border;
    padding: $large-gap;
    min-width: 200px;
    max-width: 800px;
    margin: 0 $small-gap;
    box-shadow: 0 0 20px 5px #161616;

    > * {
      @include vertical-margin-between($large-gap);
    }
  }
}
