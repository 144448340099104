.navigation .guild-navigation {
  &__related-link {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: $smaller-gap;
    align-items: center;
  }

  &__related-link-icon {
    width: 1.25em;
    height: 1.25em;
  }
}
