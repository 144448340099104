.subscription-tier {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: $small-gap $large-gap;
  margin: $tiny-gap;
  border: 1px solid $light-gray;
  box-shadow: $tiny-gap $tiny-gap $tiny-gap $black;
  position: relative;
  background-color: $dark-gray;

  &--current {
    border-color: $lighter-gray;
  }

  &__is-current-badge {
    position: absolute;
    top: -12px;
    left: 12px;
    background-color: $gray;
    border-radius: $border-radius;
    padding: $tiny-gap $smaller-gap;
    @include body-text;
  }

  &__header {
    z-index: 1;
    text-align: center;
    margin: 0;
    font-size: $subtitle-font-size;

    img {
      width: 32px;
      height: 32px;
      margin-bottom: -8px;
      margin-right: 6px;
    }
  }

  &__feature-list {
    @include body-text;
    padding-inline-start: 1rem;
  }

  &__feature {
    &--is-highlighted {
      background-color: $light-blue;
      border-radius: $border-radius;
      padding: 0 $smaller-gap;

      > span {
        color: $black;
      }
    }
  }

  &__price {
    @include body-text;
    display: flex;
    flex: 1;
    align-items: flex-end;
    align-self: center;
    font-weight: bold;
  }

  @include md {
    max-width: 75%;
  }
}
