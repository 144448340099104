.a52596fdd-fallback {
  flex-grow: 1;
  overflow: hidden;

  container-type: size;
  & > * {
    box-sizing: border-box;
  }
}

.a52596fdd-fallback__layout {
  --flex-direction: column;
  --appeal-font-size: #{$small-title-font-size};
  --remain-free-font-size: #{$subsubtitle-font-size};
  --body-font-size: #{$smaller-body-font-size};
  --logo-width: 8rem;
  --logo-height: 4rem;
  --work-hard-display: block;
  --thank-you-display: block;
  --view-more-display: block;
  --remain-free-display: block;

  border: 1px solid $level-0-border;
  display: flex;
  flex-direction: var(--flex-direction);
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-right: $smaller-gap;
  padding-left: $smaller-gap;
  gap: $small-gap;
  background-position-x: 23%;
  background-size: cover;
  background-color: rgb(0 0 0 / 0.85);
  background-blend-mode: darken;
  color: $text-white !important;
  text-align: center;

  &--vertical {
    padding: $small-gap $tiny-gap;
  }
  &--rectangle {
    padding-top: 1rem;
    --view-more-display: none;
    --thank-you-display: none;
    --work-hard-display: none;
    --remain-free-display: inline;
    --remain-free-margin-left: #{$tiny-gap};
  }
  &--horizontal {
    --view-more-display: none;
    --remain-free-display: inline;
    --remain-free-margin-left: #{$tiny-gap};
    --flex-direction: row;
    --appeal-font-size: #{$title-font-size * 1.75};
    --remain-free-font-size: #{$subsubtitle-font-size * 1.5};

    text-align: left;
    .a52596fdd-fallback__thank-you {
      margin-right: $tiny-gap;
      font-style: italic;
      text-align: right;
    }
    @container (min-height: 200px) {
      --thank-you-display: inline;
      --body-font-size: #{$smaller-body-font-size * 1.5};
    }
    @container (max-height: 200px) {
      --appeal-font-size: #{$title-font-size * 0.8};
      --remain-free-font-size: #{$subsubtitle-font-size * 0.75};
      --body-font-size: #{$smaller-body-font-size};
      --logo-width: 8rem;
      --logo-height: 4rem;
      --thank-you-display: none;
      --view-more-display: none;

      padding-top: 0;
      padding-left: $small-gap;
    }

    @container (max-height: 80px) {
      --appeal-font-size: #{$title-font-size * 0.45};
      --remain-free-font-size: #{$subsubtitle-font-size * 0.5};
      --body-font-size: #{$smaller-body-font-size * 0.5};
      --logo-width: 2rem;
      --logo-height: 2rem;
      --thank-you-display: none;
      .a52596fdd-fallback-content {
        justify-content: center;
      }
    }
  }
}

.a52596fdd-fallback {

  &__site-logo {
    height: var(--logo-height);
    width: var(--logo-width);
    object-fit: contain;
  }

  &__content {
    font-size: var(--body-font-size);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 1;
  }

  &__greeting {
    font-size: var(--appeal-font-size);
    font-weight: 800;
  }

  &__remain-free {
    display: var(--remain-free-display);
    margin-left: var(--remain-free-margin-left, 0);
    font-size: var(--remain-free-font-size);
    margin-top: $tiny-gap;
  }

  &__work-hard {
    display: var(--work-hard-display);
  }

  &__linkish {
    color: $link-blue;
  }

  &__thank-you {
    display: var(--thank-you-display);
  }

  &__view-more {
    display: var(--view-more-display);
  }
}
