.guild-recruitment-criteria-tile {
  &__activities-and-language {
    display: grid;
    grid-gap: $content-padding;

    @include lg {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__zone-filters {
    display: grid;
    grid-gap: $content-padding;

    @include lg {
      grid-template-columns: 1fr;
    }
  }
}
