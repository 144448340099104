@use 'resources/sass/variables';
@use 'resources/sass/tools';

.detailed-composition-grid {
  &__table {
    border-bottom: unset;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 2px;
    background-color: variables.$gray;
    border-radius: variables.$border-radius variables.$border-radius 0 0;
  }
  &__table tbody tr:nth-child(2n+1) {
    background-color: variables.$dark-gray;
  }

  &__character-cell {
    &__name-group {
      margin: 0 auto;
      width: max-content;

      display: grid;
      grid-template-columns: min-content auto;
      grid-gap: variables.$smaller-gap;
      align-items: center;

      font-weight: bold;
      font-size: variables.$subtitle-font-size;
    }

    &__text-data {
      display: grid;
      grid-template-rows: repeat(2, auto);
      justify-items: center;
      margin: variables.$tiny-gap;

      dt {
        font-variant: all-small-caps;
        font-weight: bold;
        grid-row: 1;
      }

      dd {
        grid-row: 2;
        margin: 0;
      }
    }
  }

  &__progress-bar-container, &__progress-bar {
    height: 0.5rem;
    border-radius: calc(0.5rem / 3);
  }

  &__progress-bar-container {
    position: relative;
    background: variables.$black;
    width: 100%;
  }

  &__progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    background-color: var(--color, variables.$rare);
  }

  &__guild-name-container {
    font-size: variables.$page-title-font-size;
    font-weight: bold;
    text-transform: uppercase;

    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 1rem;
    align-items: center;
    justify-content: center;
  }

  &__overall-ilvl {
    font-size: variables.$subtitle-font-size;
    font-family: variables.$body-font-family;
    padding: variables.$tiny-gap;
  }

  &--embed {
    background-color: transparent;
    border-spacing: 0;

    thead, thead tr, th {
      background-color: transparent;
    }

    tbody {
      td {
        border: 1px solid variables.$gray;
      }

      tr:first-child {
        td:first-child {
          border-top-left-radius: variables.$border-radius;
        }

        td:last-child {
          border-top-right-radius: variables.$border-radius;
        }
      }

      tr:last-child {
        td:first-child {
          border-bottom-left-radius: variables.$border-radius;
        }

        td:last-child {
          border-bottom-right-radius: variables.$border-radius;
        }
      }
    }
  }

  &--embed & {
    &__guild-logo, &__overall-ilvl {
      filter: drop-shadow(0px 0px 12px variables.$black);
    }

    &__guild-name {
      @include tools.text-shadow-header;
    }
  }
}
