.recruitment-page {
  &__help {
    text-align: center;
  }

  &__option {
    display: grid;
    min-height: 150px;
    grid-gap: $xlarge-gap;
    align-items: center;
    justify-content: center;

    &--with-image {
      @include md {
        grid-template-columns: 180px 1fr;
      }
    }
  }

  &__option-image-container {
    display: none;

    @include md {
      width: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__option-image {
    max-height: 160px;
    border-radius: $border-radius;
  }

  &__option-text {
    font-size: $title-font-size;
  }

  &__option-coming-soon-container {
    position: relative;
  }

  &__option-coming-soon {
    z-index: 1;
    position: absolute;
    left: -10px;
    bottom: -15px;
    font-size: $title-font-size;
    background-color: $black;
    border-radius: $border-radius;
    border: 1px solid $light-gray;
    padding: $tiny-gap $large-gap;
  }

  &__tile {
    &--disabled {
      cursor: not-allowed;

      .recruitment-page__option-image {
        filter: grayscale(50%);
      }

      .recruitment-page__option-text {
        color: $lighter-gray;
      }

      .react-tile__content {
        overflow-x: unset;
      }
    }
  }

  &__content-and-ad {
    &--with-ad {
      @include lg {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: $small-gap;
        align-items: center;
      }
    }
  }

  &__any-clip {
    display: none;

    @include lg {
      display: block;
    }
  }
}
