.thin-banner-with-markdown {
  .markdown__content {
    @include smaller-text;
  }

  @include md {
    .markdown__content {
      @include body-text;
    }
  }
}
