.retail-sepulcher-welcome-back-tile {
  margin-bottom: 10px;

  &__content {
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    font-family: $title-font-family;
    @include text-shadow-header();
  }

  &__welcome-back {
    font-size: 2rem;
    line-height: 2.25rem;
    font-weight: bold;
    filter: drop-shadow(2px 4px 6px $black);

    @include md {
      font-size: 5.25rem;
      line-height: 5.625rem;
    }
  }

  &__welcome {
    color: #1E785C;
  }

  &__logo {
    width: 4rem;
    height: 4rem;
    filter: drop-shadow(2px 4px 2px $black);

    @include md {
      width: 8.25rem;
      height: 8.25rem;
    }
  }

  &__returning-user {
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: bold;

    @include md {
      font-size: 4.5rem;
      line-height: 4.875rem;
    }
  }

  &__check-out {
    font-size: 1.25rem;
    line-height: 1.5rem;

    @include md {
      font-size: 3rem;
      line-height: 3rem;
    }
  }
}
