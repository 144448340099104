.composition-set-gear {
  &__top-left {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__set-gear-threshold-header-amount {
    font-family: $body-font-family;
  }

  &__role {
    text-align: right;
  }

  &__item-level,
  &__set-gear-count {
    text-align: center;
  }
}
