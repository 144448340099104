@use "../TalentTreeNode/TalentTreeNode.scss" as talent;

.talent-tree-node-active {
  background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 40V6L6 1H40L45 6V40L40 45H6L1 40Z' fill='black' stroke='url(%23paint0_linear_88_115513)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_88_115513' x1='22' y1='0' x2='22' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FFF900'/%3E%3Cstop offset='1' stop-color='%23FFC900'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");

  &--common {
    background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 40V6L6 1H40L45 6V40L40 45H6L1 40Z' fill='black' stroke='url(%23paint0_linear_88_115513)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_88_115513' x1='22' y1='0' x2='22' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23#{talent.bake_common(#B2B2B2)}'/%3E%3Cstop offset='1' stop-color='%23#{talent.bake_common(#B2B2B2)}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }
  &--uncommon {
    background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 40V6L6 1H40L45 6V40L40 45H6L1 40Z' fill='black' stroke='url(%23paint0_linear_88_115513)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_88_115513' x1='22' y1='0' x2='22' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23#{talent.bake_uncommon(#3EC82C)}'/%3E%3Cstop offset='1' stop-color='%23#{talent.bake_uncommon(#3EC82C)}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }
  &--rare {
    background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 40V6L6 1H40L45 6V40L40 45H6L1 40Z' fill='black' stroke='url(%23paint0_linear_88_115513)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_88_115513' x1='22' y1='0' x2='22' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23#{talent.bake_rare(#0061DC)}'/%3E%3Cstop offset='1' stop-color='%23#{talent.bake_rare(#1C7FFF)}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }
  &--epic {
    background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 40V6L6 1H40L45 6V40L40 45H6L1 40Z' fill='black' stroke='url(%23paint0_linear_88_115513)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_88_115513' x1='22' y1='0' x2='22' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23#{talent.bake_epic(#9127D9)}'/%3E%3Cstop offset='1' stop-color='%23#{talent.bake_epic(#9B44D7)}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }
  &--legendary {
    background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 40V6L6 1H40L45 6V40L40 45H6L1 40Z' fill='black' stroke='url(%23paint0_linear_88_115513)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_88_115513' x1='22' y1='0' x2='22' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23#{talent.bake_legendary(#FF8000)}'/%3E%3Cstop offset='1' stop-color='%23#{talent.bake_legendary(#FF8000)}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }
  &--artifact {
    background-image: url("data:image/svg+xml,%3Csvg width='46' height='46' viewBox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 40V6L6 1H40L45 6V40L40 45H6L1 40Z' fill='black' stroke='url(%23paint0_linear_88_115513)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_88_115513' x1='22' y1='0' x2='22' y2='46' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23#{talent.bake_artifact(#E268A8)}'/%3E%3Cstop offset='1' stop-color='%23#{talent.bake_artifact(#E268A8)}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }

  img {
    mask-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 38 38' preserveAspectRatio='none' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 3L3 0H35L38 3V35L35 38H3L0 35V3Z' fill='%23000000'/%3E%3C/svg%3E");
    mask-size: 100% 100%;
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 38 38' preserveAspectRatio='none' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 3L3 0H35L38 3V35L35 38H3L0 35V3Z' fill='%23000000'/%3E%3C/svg%3E");
    -webkit-mask-size: 100% 100%;
  }
}
