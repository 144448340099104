.character-thumbnail {
  --size: 64;
  width: calc(var(--size) * 1px);
  height: calc(var(--size) * 1px);

  &--rounded {
    border-radius: 100%;
  }

  &--bordered {
    border: 1px solid $gray;
  }
}