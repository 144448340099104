.character-recruitment-zone-filters {
  display: grid;
  grid-gap: $xlarge-gap;

  @include body-text;

  @include xl {
    grid-template-columns: 1fr 1fr;
  }

  > div:only-child {
    @include lg{
      grid-column: 1/-1;
    }
  }

  &__zone-filter-container {
    > * {
      @include vertical-margin-between($large-gap)
    }
  }

  &__zone-name {
    font-weight: bold;
    font-size: $subsubtitle-font-size;
  }

  &__zone-filter-name {
    font-weight: bold;
    display: block;
    margin: $small-gap 0;

    @include body-text;

    @include sm {
      margin-left: $large-gap;
    }
  }

  &__buttons-container {
    margin-top: $large-gap;
  }
}
