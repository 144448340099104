.save-changes-or-cancel-buttons {
  &__buttons {
    display: flex;
    justify-content: center;

    button {
      margin: 0;
      @include horizontal-margin-between($small-gap);
    }
  }
}