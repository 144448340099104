.footer {
  max-width: 1480px;
  margin: $gap-48 auto 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  padding: $gap-48 0px $gap-64;
  border-top: 1px solid $level-0-border;
  position: relative;
  width: calc(100% - 20px);

  &--fill-width {
    width: 90vw;
    max-width: 1800px;
  }

  &--narrow {
    width: calc(100% - 20px);
    max-width: 1024px;
    margin: 0 auto;

    .footer-links-list__wrapper {
      margin-left: $gap-40;
    }
  }

  &__background-image {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 25%;
    mask-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 100 100' preserveAspectRatio='none' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='url(%23rad)'/%3E%3Cdefs%3E%3CradialGradient id='rad'%3E%3Cstop offset='74%25'/%3E%3Cstop offset='100%25' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E");
    mask-size: 100% 100%;
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' viewBox='0 0 100 100' preserveAspectRatio='none' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='100%25' height='100%25' fill='url(%23rad)'/%3E%3Cdefs%3E%3CradialGradient id='rad'%3E%3Cstop offset='74%25'/%3E%3Cstop offset='100%25' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E");
    -webkit-mask-size: 100% 100%;
  }

  &__top-part {
    &__wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: $gap-64;
      width: 100%;
      position: relative;
      z-index: 1;
    }
  }

  &__left-part {
    &__links {
      font-weight: 700;
      font-size: $smaller-font-size;
      color: $white-800;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: $gap-8;
      margin-top: 0px;

      a {
        transition: opacity 0.24s ease-out;
        text-transform: none !important;
        margin: 0 $gap-8;
        color: $white-800;
        transition: filter 0.14s ease-out;
        white-space: nowrap;

        &:first-of-type {
          margin-left: 0;
        }

        &:hover {
          filter: brightness(1.15);
        }
      }
    }

    &__social-link {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      & a:first-child {
        margin-left: 0;
      }
    }

    &__article {
      &-wrapper {
        margin-top: $gap-40;
        display: block;
        transition: filter 0.14s ease-out;

        &:hover {
          filter: brightness(1.15);
        }
      }

      &-section-name {
        display: block;
        font-weight: 900;
        font-size: $smaller-font-size;
        color: $link-blue;
        letter-spacing: 0.145em;
        text-align: left;
        margin-bottom: $gap-2;
        text-transform: uppercase;
      }
    }

  }

  &__right-part {
    &__links-wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-end;
    }
  }

  &__bottom-part {
    font-weight: 400;
    height: 13px;
    color: $white-800;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 1;
  }
}

@media (max-width: 1450px) {
  .footer {
    &__right-part {
      &__links-wrapper {
        flex-wrap: wrap;
      }
    }
  }
}



@media (max-width: 980px) {
  .footer {
    &__top-part__wrapper {
      flex-direction: column;
      align-items: center;
    }

    &__left-part {
      &__game-space-name,
      &__links,
      &__social-link  {
        justify-content: center;
      }

      &__article-section-name {
        text-align: center;
        margin-bottom: $gap-8;
      }
    }

    &__right-part {
      &__links-wrapper {
        margin-top: $gap-48;
        width: 100%;
      }
    }
  }
}
