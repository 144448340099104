.report-tags-form__wrapper {
  .report-tags-form__form-element {
    display: block;
    width: 100%;
    margin: 0 auto;
    @include md {
      width: 66%;
    }
    @include lg {
      width: 50%;
    }
  }
}