@import '@resources/sass/variables';

.guild-progress-composition-embed {
  height: 100%;

  &__content {
    position: relative;
    border: 1px solid $light-gray;
    padding: $content-padding;
    background-color: $black;
  }
}
