.guild-progress-composition-embed-instructions-tile {
  &__content {
    display: grid;
    grid-gap: $small-gap;

    @include md {
      grid-template-columns: 50% 50%;
    }
  }

  &__preview {
    height: 480px;
  }
}
