.guild-selector {
  display: grid;
  align-items: center;
  grid-gap: $tiny-gap;
  margin-bottom: $small-gap;
  @include body-text;

  @include md {
    grid-template-columns: auto 1fr auto;
    grid-gap: $small-gap;
  }
}
