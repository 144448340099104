.character-scores-summary {
  &__title, &__zone, &__zone-score-and-bar {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: $small-gap;
    align-items: center;
  }

  &__title {
    background-color: $closer-background;
    border-bottom: 1px solid $dark-gray;
    padding: $smaller-gap;
  }

  &__scores {
    padding: $smaller-gap;
    display: grid;
    grid-gap: $smaller-gap;
  }
}
