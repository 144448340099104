.toggle-select {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @include sm {
    min-height: 38px;
    flex-direction: row;
  }

  &--slim {
    min-height: unset;
  }

  &__option {
    align-items: center;
    border-bottom-color: transparent;
    justify-content: center;
    text-align: center;
    border-radius: 0;
    border-top-color: $slightly-dark-gray;
    border-left-color: $slightly-dark-gray;

    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    &:last-child {
      border-bottom-color: $slightly-dark-gray;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    @include sm {
      border-bottom-color: $slightly-dark-gray;
      border-right-color: transparent;
      &:last-child {
        border-right-color: $slightly-dark-gray;
      }
    }
  }
}
