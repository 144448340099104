.recruitment-spot-specs-multi-selector {
  @include body-text;

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: $small-gap;
  margin: $small-gap 0;

  &--multi-column {
    grid-column-gap: $large-gap;
    grid-template-columns: 1fr 1fr;
  }

  &__role-container {
    display: grid;
    grid-gap: $smaller-gap;
    grid-template-columns: 1fr;

    @include sm {
      grid-template-columns: 1fr 2fr;
    }
  }
}
