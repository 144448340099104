.a52596fdd-google {
  display: block;
  &__leaderboard-atf, &__leaderboard-btf {
    width: 728px !important;
    height: 90px !important;
    &--responsive {
      width: 100%;
    }
  }

  &__side-rail-wide {
    width: 300px !important;
    height: 600px !important;
  }
}
